////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import YouTube from 'react-youtube';

// [리덕스]스토어 연결
import store from "store";

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import MediaSourceModule from 'components/edit/editModule/MediaSourceModule'; // 텍스트 모듈
import ImageButtonModule from 'components/edit/editModule/ImageButtonModule'; // 이미지 버튼을 눌러 여러 옵션중 값을 정합니다.
import ErrorModule from 'components/edit/editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import TimeModule from '../editModule/TimeModuleV2'; // 타이머 모듈


import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬
import VideoControlBar from 'components/common/form/VideoControlBar'// 가이드 벌룬

// 이미지 
import youtubeIcon from 'assets/resource/youtube.png';
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import UtilityPanel from '../editPanel/preferences/UtilityPanel';


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* size: { width : number, height : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function VideoEditForm({target,modify,keyName,title,option}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {mediaType,mediaSource,startTime,autoplay} = keyName ;

    // set
    const uuid = target.uuid
    const context = useContext(EditContext);
    const playerRef = useRef(null);
    const TargetSource = ED.getValue(target,mediaSource)
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [GetVideoAction, setVideoAction] = useState('url');

    const [YoutubePlayer, setYoutubePlayer] = useState(null);

    // 비디오 길이
    const [videoDuration, setVideoDuration] = useState(null);

    const [Cache,setCache] = useState(null);
    const [currentTime, setCurrentTime] = useState(Date.now());
    


    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        setVideoAction(ED.getValue(target,mediaType))
        try {
            const intervalId = setInterval(() => {
              setCurrentTime(Date.now());
            }, 1000);
        
            // 컴포넌트 언마운트 시 인터벌 제거
            return () => clearInterval(intervalId);
          } catch (error) {
            console.error("An error occurred in the useEffect hook:", error);
          }
      }, []);

      // 캐쉬 체커
      useEffect(() => {
        initCacheControl();
      }, [context.channelCache]);


        // 캐쉬 체커
        useEffect(() => {
            getDuration();
        }, [videoDuration]);
            

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////



    
    const modifyCacheControl = () => {

    }

    const initCacheControl = () => {
        const Cache = context.getChannelCache(target)

        // 캐쉬가 변경될때 *새 채널 이동 및 새 페이지 이동 playCache_duration 가 없으면 등록됨
        if(Cache?.playCache_duration==null||Cache?.playCache_duration==undefined){
            getDuration()
        }
        setCache(Cache)
    }


    const getProgress = () => {

        let progressTime = 0
        if(Cache?.playCache_state == 'play'){
          progressTime = Math.floor(Date.now() / 1000) - Cache?.playCache_start + (Cache?.playCache_progress ? Cache?.playCache_progress : 0)
        }else if (Cache?.playCache_state == 'pause') {
          progressTime = (Cache?.playCache_progress ? Cache?.playCache_progress : 0)
        }else if (Cache?.playCache_state == 'stop') {
          progressTime = Cache?.playCache_progress??0
        }
    
         return progressTime;
        }

        const formatTime = (seconds) => {
            const hrs = Math.floor(seconds / 3600);
            const mins = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;
          
            // 시간 부분은 1시간 이상일 때만 포함
            if (hrs > 0) {
              return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else {
              return `${mins}:${secs.toString().padStart(2, '0')}`;
            }
          }


    // const initCache = () =>{
    //     const Cache = context.getChannelCache(target)
    //     if (Cache.hasOwnProperty('videoState')) {
    //         setPauseTime(Cache?.pauseTime)
        
    //     } 
    //     if (Cache.hasOwnProperty('pauseTime')) {setPauseTime(Cache?.pauseTime)} 
    //     if (Cache.hasOwnProperty('atStart')) {setAtStart(Cache?.atStart)}
    //     setReadyTimer(true);
    // }
    

    /**
     * 편집기가 로딩될때 채널 캐쉬를 가지고 옵니다.
     * @param {*} widget 
     */
    // const linkChannelCache = (widget) =>{
    //     const Cache = context.getChannelCache(widget)
    //     const InitData = Utility.deepCopy(LivePlayControl)
    //     if (Cache.hasOwnProperty('playCache_pause')) {InitData['pause'] =Cache?.playCache_pause } 
    //     if (Cache.hasOwnProperty('playCache_state')) {InitData['state'] =Cache?.playCache_state } 
    //     if (Cache.hasOwnProperty('playCache_start')) {InitData['start'] =Cache?.playCache_start } 
    //     if (Cache.hasOwnProperty('playCache_end')) {InitData['end'] =Cache?.playCache_end } 
    //     setLivePlayControl(InitData)
    // }

    const getDuration = () =>{

        if(videoDuration!=null){
            const RequestCache ={}
            RequestCache['playCache_duration'] = videoDuration;
            context.setChannelCache(RequestCache,target);
        }
    }


    const channelCacheState = (state) =>{

        const RequestCache ={}
        const ProgressTime = getProgress() > Cache?.playCache_duration ? Cache?.playCache_duration : getProgress();
        const now = Math.floor(Date.now() / 1000)
        switch (state) {
            case 'play':
                RequestCache['playCache_state'] = state
                RequestCache['playCache_start'] = now
                RequestCache['playCache_progress'] =  Cache?.playCache_progress ? Cache?.playCache_progress : 0; 
                break;
            case 'pause':
                if(Cache?.playCache_start){
                    // 일시정지시 지금까지의 진행도를 저장합니다.
                    const Progress = now - Cache?.playCache_start + (Cache?.playCache_progress ? Cache?.playCache_progress : 0 )
                    if(videoDuration >= Progress){
                        RequestCache['playCache_state'] = state
                        RequestCache['playCache_start'] = null
                        RequestCache['playCache_progress'] =  Progress
                    }else{
                        RequestCache['playCache_state'] = 'stop'
                        RequestCache['playCache_start'] = null
                        RequestCache['playCache_progress'] = 0
                    }
                }else{
                    RequestCache['playCache_state'] = 'stop'
                    RequestCache['playCache_start'] = null
                    RequestCache['playCache_progress'] = 0
                }
                break;
            case 'stop':
                RequestCache['playCache_state'] = state
                RequestCache['playCache_start'] = null
                RequestCache['playCache_progress'] = 0
                break;    
            case 'forward':
                RequestCache['playCache_start'] = now
                RequestCache['playCache_progress'] = Number(ProgressTime > Cache?.playCache_duration?Cache?.playCache_duration : ProgressTime+10)
                break;  
            case 'replay':   
                RequestCache['playCache_start'] = now
                RequestCache['playCache_progress'] = Number(ProgressTime - 10 > 0? ProgressTime-10 : 0)
                break;  
            default:
                break;
        }
        context.setChannelCache(RequestCache,target);
    }
    
    const modifyURL = (source) => {
        const RequestCache ={}
        RequestCache['playCache_state'] = 'stop'
        RequestCache['playCache_start'] = null
        RequestCache['playCache_progress'] = 0
        context.setChannelCache(RequestCache,target);
        setTimeout(() => {
            modify(mediaSource.category,mediaSource.key,source,uuid);
        }, 100);


    }


    const changeProgress = (sec) => {

        const RequestCache ={}
        const now = Math.floor(Date.now() / 1000)
        RequestCache['playCache_start'] = now
        RequestCache['playCache_progress'] = Number(sec)
        context.setChannelCache(RequestCache,target);
    }
    
    /**
     * 동영상의 URL 이 어떤 타입인지 체크합니다.
     */
    const checkVideoUrl = (url) => {
        if (typeof(url) != 'string'){
            return false
        }
        const YoutubeShort = 'https://youtu.be/'
        if (url.toLowerCase().includes(YoutubeShort.toLowerCase())){
            return 'youtube';
        }
        const YoutubeFull = 'https://www.youtube.com/'
        if (url.toLowerCase().includes(YoutubeFull.toLowerCase())){
            return 'youtube';
        }
    }

    const getYoutubeID = (youtubeURL) => {
        const YoutubeShort = 'https://youtu.be/'
        if (youtubeURL.toLowerCase().includes(YoutubeShort.toLowerCase())){
            let videoId = youtubeURL.split('/').pop();
            videoId = videoId.split('?')[0];
            return videoId;
        }
        const YoutubeFull = 'https://www.youtube.com/'
        if (youtubeURL.toLowerCase().includes(YoutubeFull.toLowerCase())){
            let videoId = youtubeURL.split('/watch?v=').pop();
            console.log('videoId',videoId)
            videoId = videoId.split('?')[0];

            return videoId;
        }
    }



    const onReady = (event) => {
        const Duration = event.target.getDuration()

        console.log('Duration',Duration)
        setVideoDuration(Duration);

    };

    const youtubeControl = () => {
        const YoutubeID = getYoutubeID(ED.getValue(target,mediaSource))
        const opts = {
            height: '0',
            width: '0',
            playerVars: {
              autoplay: 0,
              mute: 1,
            },
          };

          const ProgressTime = getProgress() > Cache?.playCache_duration ? Cache?.playCache_duration : getProgress();

        return(
            <Fragment>
            <YouTube className='loadYoutube' videoId={YoutubeID} opts={opts} onReady={onReady} onc ref={playerRef} />
            <div className='MediaSourceImgPreviewField'>
                <picture className='MediaSourceImgPreview'>
                    <source srcSet={`https://img.youtube.com/vi/${getYoutubeID(ED.getValue(target,mediaSource))}/0.jpg`}/>
                    <img src={`https://img.youtube.com/vi/${getYoutubeID(ED.getValue(target,mediaSource))}/0.jpg`}/>
                </picture>

                <div className='videoInfo'>
                    <img height={20} src={youtubeIcon}/>
                </div>
            </div>

            <div className='liveControlField'>
                <div>
                {/* <input type="range" min="0" max="100" value="0"/> */}
                </div>
                <div>
                <ImageButtonModule value={'play'} type ="mediaState"  data={[{val:"replay",icon:"replay_10"},(Cache?.playCache_state!='play'? {val:"play",icon:"play_arrow"}: {val:"pause",icon:"pause"}),{val:"stop",icon:"stop"},{val:"forward",icon:"forward_10"},]} change={(type,value)=>{
                channelCacheState(value)
                }}/>
                </div>
                <div className='progressivePlace'>
                <div className='progressiveTime'>
     
                {/* {formatTime(Progress)} */}
                {formatTime(ProgressTime??0)}
                </div>
                <input type="range" value={ProgressTime??0} min="0" max={Cache?.playCache_duration??0} onChange={(e)=>changeProgress(e.target.value)}/>
                <div className='durationTime'>
                {/* {ProgressTime} */}
                {formatTime(Cache?.playCache_duration??0)}
                </div>
                </div>
            </div>
        </Fragment>
        )

    }

    const videoControl = () =>{

        const VideoType = checkVideoUrl(ED.getValue(target,mediaSource))

        
        console.log('VideoType',ED.getValue(autoplay));

        return (
            <Fragment>
                {Editor.WidgetEditSubhead({text:'동영상 컨트롤',icon:"play_pause"})}
                <article className="EditPart" >
                {VideoType == 'youtube'?
                youtubeControl()
                :null}

                <div>
                
                <GuideBalloon guideText={<p> 현제 페이지 송출시 자동으로 <br/> 동영상이 시작 됩니다. </p>} direction='topleft'>
                    <SwitchModule value={ED.getValue(target,autoplay)} title={autoplay?.title??"자동재생"} type ={autoplay.key} change={(type,value)=>modify(autoplay.category,type,value,uuid)}/>
                </GuideBalloon>

                
                </div>
                </article> 
                {Editor.WidgetEditSubhead({text:'동영상 편집',icon:"movie_edit"})}
                <article className="EditPart" >

                {/* <GuideBalloon guideText={<p> 현제 페이지 송출시 자동으로 <br/> 동영상이 시작 됩니다. </p>} direction='topleft'> */}

                {/* <h3>동영상의 시작 시간</h3> */}
                {startTime?<TimeModule value={ED.getValue(target,startTime)} title={startTime?.title??"시작 지점"} type ={startTime.key} change={(type,value)=>modify(startTime.category,type,value,uuid)}/>: ''}
                {/* </GuideBalloon> */}
                </article> 
            </Fragment>
        );
    }

    const videoAppend = () => {

        return (
            <Fragment>
            {/* {Editor.WidgetEditSubhead({text:'동영상 가저오기',icon:"media_link"})} */}
            <article className="EditPart" >
            <div className='ButtonModule'>
             <h5>동영상 가저오기</h5>
                <div>
                    <ImageButtonModule value={GetVideoAction} type ="mediaType"  data={[{val:"url",icon:"add_link"}]} change={(type,value)=>setVideoAction(value)}/>
                </div>
             </div>
            {GetVideoAction=='youtube'? 
             <div className='ButtonModule'>
                <MediaSourceModule value={ED.getValue(target,mediaSource)} target={'mediaSource'} type={mediaSource?.key} placeholder={'여기에 이미지 URL을 복사해주세요'} change={(type,value)=>modifyURL(value)} />
             </div>
            :null }

            {GetVideoAction=='url'? 
             <div className='ButtonModule'>
                <MediaSourceModule value={ED.getValue(target,mediaSource)} target={'mediaSource'} title={'영상의 주소'} type={mediaSource?.key} placeholder={'여기에 이미지 URL을 복사해주세요'} change={(type,value)=>modifyURL(value)} />
             </div>
            :null }

            {GetVideoAction=='online'? 
             <div className='ButtonModule'>
                {/* <MediaSourceModule value={ImageUrl} target={'mediaSource'} type='mediaSource' title="인터넷에서 이미지"  placeholder={'여기에 이미지 URL을 복사해주세요'} change={(type,value)=>setImageUrl(value)} /> */}
             </div>
            :null }
            </article>
            </Fragment>
        )
    }

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='VideoEditForm'>
            {Editor.editorDetail('동영상 가져오기','media_link',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {videoAppend()}
            </div>
        </section>
        {ED.getValue(target,mediaSource)?
        <section className='VideoEditForm'>
            {Editor.editorDetail('동영상','play_circle',OpenForm,setOpenForm,false)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>
                {videoControl()}
            </div>
        </section>
        :''}
    </Fragment>
    );

}

export default VideoEditForm;


const FormStyle = `

.VideoEditForm .MediaSourceImgPreviewField{
    display:flex;
    height : 150px;
    margin : 5px 0px;
    border-radius : 5px;
    position: relative;
}

.MediaSourceImgPreviewField .videoInfo{
    position: absolute;
    bottom : 10px;
    right : 10px;
}
.VideoEditForm .MediaSourceImgPreview {
    display: flex;
    width: 100%;
    height : 150px;
    object-fit: scale-down;
    align-items: center;
    justify-content: center;
    
}

.VideoEditForm .MediaSourceImgPreview img {
    display: flex;
    width: 100%;
    height : 140.625px;
    object-fit: cover;
    border-radius : 5px;
    overflow : hidden;
}

.VideoEditForm .liveControlField{
    width: 100%;
    display: flex;
    background-color : #353535;
    padding : 10px 0px;
    border-radius : 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.VideoEditForm .loadYoutube{
    display : none;
}

.VideoEditForm .liveControlField .ImageButtonModule{
    display: flex;
    flex-wrap: wrap;

}

.VideoEditForm .liveControlField .optionButton.buttonSelect {
    background: red !important; 
    box-shadow: 3.5px 3.5px 10px #1d1d1d, -3.5px -3.5px 10px #272727;
}

.VideoEditForm .liveControlField .optionButton.buttonSelect .ButtonImage{
    display: flex;
    width: 100%;
    height: 100%;
    filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px 5px 5px) drop-shadow(rgba(0, 0, 0, 0.03) 10px 10px 10px) ;
}

.VideoEditForm .liveControlField .ImageButtonModule .ButtonImage{
    display: flex;
    width: 100%;
    height: 100%;
}

.VideoEditForm .progressivePlace{
    display: flex;
    justify-content: center;
    align-items: center;
    width : 100%;
  }
  .VideoEditForm .progressivePlace  .progressiveTime{
    width : 50px;
    text-align: right;
    padding-right : 5px;
    font-size : 15px;
  }
  .VideoEditForm .progressivePlace  .durationTime{
    width : 50px;
    text-align: left;
    padding-left : 5px;
    font-size : 15px;
  }

 .VideoEditForm .progressivePlace input[type="range"] {
  -webkit-appearance: none; /* 크롬, 사파리, 오페라 */
  -moz-appearance: none; /* 파이어폭스 */
  appearance: none; /* 기본 브라우저 스타일 제거 */
  
  width: 130px; /* 너비 설정 */
  height: 6px; /* 높이 설정 */
  margin 2px 0px;
  background: #bbb; /* 기본 배경색 */
  outline: none; /* 외곽선 제거 */
  opacity: 0.9; /* 불투명도 */
  transition: opacity .15s ease-in-out; /* 전환 효과 */
  border-radius: 10px; /* 둥근 모서리 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 트랙 그림자 */
}

.VideoEditForm .progressivePlace input[type="range"]:hover {
  opacity: 1; /* 호버 시 불투명도 변경 */
}

.VideoEditForm .progressivePlace input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* 크롬, 사파리, 오페라 */
  appearance: none; /* 기본 브라우저 스타일 제거 */
  width: 10px; /* 너비 설정 */
  height: 10px; /* 높이 설정 */
  background: #e0e0e0; /* 슬라이더 색상 */
  cursor: pointer; /* 커서 모양 */
  border-radius: 50%; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* 슬라이더 그림자 */
  transition: background 0.3s ease, transform 0.3s ease; /* 전환 효과 */
}

.VideoEditForm .progressivePlace input[type="range"]::-moz-range-thumb {
  width: 10px; /* 너비 설정 */
  height: 10px; /* 높이 설정 */
  background: #e0e0e0; /* 슬라이더 색상 */
  cursor: pointer; /* 커서 모양 */
  border-radius: 50%; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* 슬라이더 그림자 */
  transition: background 0.3s ease, transform 0.3s ease; /* 전환 효과 */
}

.VideoEditForm .progressivePlace input[type="range"]::-ms-thumb {
  width: 10px; /* 너비 설정 */
  height: 10px; /* 높이 설정 */
  background: #e0e0e0; /* 슬라이더 색상 */
  cursor: pointer; /* 커서 모양 */
  border-radius: 50%; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* 슬라이더 그림자 */
  transition: background 0.3s ease, transform 0.3s ease; /* 전환 효과 */
}

.VideoEditForm .progressivePlace input[type="range"]::-webkit-slider-thumb:hover {
  background: #999; /* 호버 시 색상 변경 */
  transform: scale(1.5); /* 호버 시 확대 */
}

.VideoEditForm .progressivePlace input[type="range"]::-moz-range-thumb:hover {
  background: #999; /* 호버 시 색상 변경 */
  transform: scale(1.5); /* 호버 시 확대 */
}

.VideoEditForm .progressivePlace input[type="range"]::-ms-thumb:hover {
  background: #999; /* 호버 시 색상 변경 */
  transform: scale(1.5); /* 호버 시 확대 */
}


`;