////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import ColorModule from 'components/edit/editModule/ColorModule'; // 색상 모듈
import SwitchModule from 'components/edit/editModule/SwitchModule'; // 색상 모듈
import SelectModule from 'components/edit/editModule/SelectModule';
import ImageButtonModule from 'components/edit/editModule/ImageButtonModule'; // 이미지 버튼
import RangeModule from 'components/edit/editModule/RangeModule'; // 이미지 버튼

// 서비스 연결
import * as WidgetData from 'service/value/WidgetData' ; 
import * as Editor from 'service/edit/EditService'; 
// 이미지 연결
import particles_few from 'assets/sidebar/particles/particles_few.svg';
import particles_many from 'assets/sidebar/particles/particles_many.png';

import particles_small from 'assets/sidebar/particles/particles_small.png';
import particles_medium from 'assets/sidebar/particles/particles_medium.png';
import particles_large from 'assets/sidebar/particles/particles_large.png';

import particles_slow from 'assets/sidebar/particles/particles_slow.svg';
import particles_quick from 'assets/sidebar/particles/particles_quick.svg';




////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ParticlesEdit extends Component {
    
    state = {
        editorToggle : true
    }

    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)
    }
    presetChange = (name,value) =>{
        this.props.callBack('preset',name,value)
    }
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }

    GameChange = (name,value) =>{
        this.props.callBack('value',name,value)

        let defaultCharacters = null
        try {
            defaultCharacters = Object.keys(WidgetData.get("GameCharacters")[value])[0]
        } catch (error) {
            
        }
        this.props.multiCallBack([
            {property:'value',name:"game",value:value},
            {property:'value',name:"characters",value:defaultCharacters},
        ])
    }

    // heroesList=()=>{
    //     return Object.keys(OVERWATCHELIST)
    // }

    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none'
            }

        }

        return (

                <section className='ParticlesEdit'>
                    {/* <legend style={Style.legend} > {legend} </legend> */}
                    {Editor.WidgetEditTitle(this,{title:'파티클설정',toggle:false,icon:'ac_unit'})}

                    <article className="EditPart" > 
                        <div className='ParticlesExampleBox'>
                            {WidgetData.get('Particles')[this.props.data.value.particleStyle]?.images[0]? <img className='ParticlesExample' src={ WidgetData.get('Particles')[this.props.data.value.particleStyle].images[0]}/>:"" }
                            {WidgetData.get('Particles')[this.props.data.value.particleStyle]?.images[1]? <img className='ParticlesExample' src={ WidgetData.get('Particles')[this.props.data.value.particleStyle].images[1]}/>:"" }
                            {WidgetData.get('Particles')[this.props.data.value.particleStyle]?.images[2]? <img className='ParticlesExample' src={ WidgetData.get('Particles')[this.props.data.value.particleStyle].images[2]}/>:"" }
                        </div>


                    <div className={"ButtonModule ImageButtonModule " + this.props.appendClass} > 
         
                        <p>파티클 모양</p>

                        <span className="ButtonList">
                        <SelectModule value={this.props.data.value.particleStyle} list={Object.keys( WidgetData.get('Particles'))}  type ="particleStyle" change={this.valueChange}/>
                
                        </span>

                    </div>

                    </article>

                    <article className="EditPart MediaSourceModule" > 
                    {/* <ImageButtonModule value={this.props.data.style.objectFit} type ="objectFit"  data={[{val:"fill",image:fill},{val:"cover",image:cover},{val:"scale-down",image:scaleDown},]} title="파티클의 숫자" change={this.change}/> */}
                    <RangeModule value={this.props.data.value.particleCount} type="particleCount" valueView="" min="30" max="100" title="파티클의 빈도" startImage={particles_few} endImage={particles_many} change={this.valueChange} />
                    <RangeModule value={this.props.data.value.particleSpeed} type="particleSpeed" valueView="" min={1} max={50} title="파티클의 속도" startImage={particles_slow} endImage={particles_quick} change={this.valueChange} />
                    {/* <ImageButtonModule value={this.props.data.style.objectFit} type ="objectFit"  data={[{val:"fill",image:fill},{val:"cover",image:cover},{val:"scale-down",image:scaleDown},]} title="파티클의 속도" change={this.change}/> */}
                    <ImageButtonModule value={this.props.data.value.particleSize} type ="particleSize"  data={[{val:30,image:particles_small},{val:100,image:particles_medium},{val:200,image:particles_large},]} title="파티클의 크기" change={this.valueChange}/>
                    </article>
                </section>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

const mapStateToProps = (state) => ({
    // exemple : state.exemple,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ParticlesEdit);
  