////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Widget from '../../service/event/widget' ; 
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Import extends Component {
    
    static defaultProps = {
        widgetName: 'Import',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;
    
    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }


    widgetTest = (e) => {
        // e.preventDefault();
        // console.log('체크중')
        console.log(e,'체크중')
    }


    render() {

        const Style = this.props.getStyle(this.props.data)
        const Height =this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        const MinSize = Width<Height?Width:Height;
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        <div style={{...Style.object,...{'borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column'}}} >
                            {MinSize < 200 ?
                                <Fragment>
                                {Editor.icon({icon:'input',size:MinSize/2,lineHeight:MinSize/2})}
                                </Fragment>
                            :
                                <Fragment>
                                {Editor.icon({icon:'input',size:MinSize/5,lineHeight:MinSize/5})}
                                <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                                    URL을 입력해주세요
                                </p>
                            </Fragment>
                            }
                        </div>
                    </div>
                );
            case 'broadcast':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                        <div style={{...Style.object,...{'borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column'}}} >
                            {MinSize < 200 ?
                                <Fragment>
                                {Editor.icon({icon:'input',size:MinSize/2,lineHeight:MinSize/2})}
                                </Fragment>
                            :
                                <Fragment>
                                {Editor.icon({icon:'input',size:MinSize/5,lineHeight:MinSize/5})}
                                <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                                    URL을 입력해주세요
                                </p>
                            </Fragment>
                            }
                        </div>
                    </div>
                );
          
            case 'view':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                         <div style={{...Style.object,...{'borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column'}}} >
                            {MinSize < 200 ?
                                <Fragment>
                                {Editor.icon({icon:'input',size:MinSize/2,lineHeight:MinSize/2})}
                                </Fragment>
                            :
                                <Fragment>
                                {Editor.icon({icon:'input',size:MinSize/5,lineHeight:MinSize/5})}
                                <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                                    URL을 입력해주세요
                                </p>
                            </Fragment>
                            }
                        </div>
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Import);
  