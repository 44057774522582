// 이미지 연결
import EG_editor1 from 'assets/guide/EG_editor1.png';
import EG_editor2 from 'assets/guide/EG_editor2.png';
import EG_editor2_1 from 'assets/guide/EG_editor2-1.png';
import EG_editor3 from 'assets/guide/EG_editor3.png';
import EG_editor4 from 'assets/guide/EG_editor4.png';

import EG_page1 from 'assets/guide/EG_page1.png';
import EG_page2 from 'assets/guide/EG_page2.png';
import EG_page3 from 'assets/guide/EG_page3.png';

import EG_widget1 from 'assets/guide/EG_widget1.png';
import EG_widget2 from 'assets/guide/EG_widget2.png';
import EG_widget2_1 from 'assets/guide/EG_widget2-1.png';
import EG_widget2_2 from 'assets/guide/EG_widget2-2.png';
import EG_widget3 from 'assets/guide/EG_widget3.png';
import EG_widget3_1 from 'assets/guide/EG_widget3-1.png';

import EG_widget4_1 from 'assets/guide/EG_widget4-1.png';

import EG_obs1 from 'assets/guide/EG_obs1.png';
import EG_obs2 from 'assets/guide/EG_obs2.png';
import EG_obs3 from 'assets/guide/EG_obs3.png';
import EG_obs4 from 'assets/guide/EG_obs4.png';
import EG_obs5 from 'assets/guide/EG_obs5.png';
import EG_obs6 from 'assets/guide/EG_obs6.png';
import EG_obs7 from 'assets/guide/EG_obs7.png';

import EG_obs9_1 from 'assets/guide/EG_obs9-1.png';
import EG_obs9_2 from 'assets/guide/EG_obs9-2.png';
import EG_obs9_3 from 'assets/guide/EG_obs9-3.png';
import EG_obs10 from 'assets/guide/EG_obs10.png';

import EG_start1 from 'assets/guide/EG_start1.png';
import EG_start2 from 'assets/guide/EG_start2.png';
import EG_start3 from 'assets/guide/EG_start3.png';
import EG_start4 from 'assets/guide/EG_start4.png';
import EG_start5 from 'assets/guide/EG_start5.png';
import EG_start6 from 'assets/guide/EG_start6.png';
import EG_start7 from 'assets/guide/EG_start7.png';
import EG_start8 from 'assets/guide/EG_start8.png';
import EG_start9 from 'assets/guide/EG_start9.png';
import EG_start10 from 'assets/guide/EG_start10.png';



// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 


export function get (title) {

    switch (title) {
        case 'editorBasic':
            return editorGuide;
        case 'pageBasic':
            return PageGuide;
        case 'widgetBasic':
            return WidgetGuide;   
        case 'linkOBS':
            return LinkOBSGuide;
        case 'startGuide':
            return StartGuide;   
        default:
            return NoneGuide;

    }

}


const NoneGuide=(
    <article id = "GuideBook">
    <div style={{marginTop:'20px'}}/>
    <div className = "infoMessage">
      가이드를 준비중입니다.
    </div>
    </article>
  )
  const editorGuide = (
    <article id = "GuideBook">
    <div style={{marginTop:'20px'}}/>
    <h3>
      편집기 둘러보기
    </h3>
    <div className = "infoImage">
      <img src={EG_editor1}/>
    </div>
    <div className = "infoMessage">
      위젯 편집기는 <b>편집패널,</b> <b>도구패널,</b> <b>추가패널,</b> <b>편집화면</b> 으로 나뉩니다.
    </div>
    <p><b>편집패널</b> 에서는 페이지 편집, 위젯 편집, 위젯의 효과 편집을 할 수 있습니다. </p>
    <p><b>도구패널</b> 에서는 연결된 채널 관리와 페이지 내 위젯의 순서, 이름 변경을 할 수 있습니다. </p>
    <p><b>추가패널</b> 에서는 위젯, 변수등 다양한 요소를 추가 할 수 있습니다.</p>
    <p><b>편집화면</b> 에서는 마우스와 키보드를 통해서 화면을 보며 위젯을 편집 할 수 있습니다. </p>
    <div style={{marginTop:'20px'}}/>
    <h3>
      패널 소개
    </h3>
    
    <div className = "infoImage">
      <img src={EG_editor2}/>
    </div>
    <div className = "infoMessage">
      편집 패널은 상단 메뉴를 통해서 <b> {Editor.icon({icon:'stack',size:15,lineHeight:18})} 화면편집  {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})} 위젯편집  {Editor.icon({icon:'acute',size:15,lineHeight:18})} 효과편집</b>  탭으로 이동 할 수 있습니다. 
    </div>

    <div>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'stack',size:15,lineHeight:18})} </div>프로젝트 내 페이지를 관리하는 탭 입니다. </p>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})} </div>선택한 위젯을 편집하는 탭 입니다. </p>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'acute',size:15,lineHeight:18})}  </div>선택한 위젯에 에니메이션 효과를 부여하는 탭 입니다. </p>
    </div>

    <div className = "infoImage">
      <img src={EG_editor3}/>
    </div>
    <div className = "infoMessage">
      도구 패널은 상단 메뉴를 통해서 <b>채널 편집, 위젯 순서 편집</b> 탭으로 이동 할 수 있습니다. 
    </div>

    <div>
    <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="red" stroke="#fff" stroke-width="2"></circle></svg> </div>채널을 선택하고, 채널을 관리하는 탭 입니다. </p>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'list',size:15,lineHeight:18})} </div>페이지내 위젯 순서를 보고 관리하는 탭 입니다. </p>
    </div>

    <div className = "infoImage">
      <img src={EG_editor4}/>
    </div>
    <div className = "infoMessage">
      추가 패널은 <b>위젯과 변수</b>를 추가 할 수 있는 패널입니다.
    </div>

    <div>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'widgets',size:15,lineHeight:18})} </div>위젯을 추가 할 수 있는 탭 입니다.</p>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'function',size:15,lineHeight:18})} </div>프로젝트 내 변수를 관리하는 탭 입니다.</p>
    </div>

    <div style={{marginTop:'40px'}}/>

  </article>
  )


  const WidgetGuide = (
  <article id = "GuideBook">
    <div style={{marginTop:'20px'}}/>
    <h3>
      위젯 편집하기
    </h3>
    <div className = "infoImage">
      <img src={EG_widget1} style={{width:700,margin:'auto'}} />
    </div>
    <p>
      편집기에서는 위젯을 <b>추가,편집,삭제</b> 하고 위젯의 <b>추가 설정 메뉴</b>를 불러올수 있습니다.
    </p>
    <div style={{marginTop:'20px'}}/>
    <h3>위젯 추가</h3>
    <div className = "infoImage">
    <img src={EG_editor4} style={{width:700,margin:'auto'}} />
    </div>
    <div className = "infoMessage" style={{textAlign:'center'}}>
    새로운 위젯을 추가하려면 편집기의 오른쪽 하단에 있는 <br/> 추가패널에서 {Editor.icon({icon:'widgets',size:15,lineHeight:18})}위젯탭을 선택해서 위젯을 추가 할 수 있습니다.
    </div>
    <div>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'image',size:15,lineHeight:18})} </div>&#91;이미지&#93; URL을 통해서 jpg, png, gif, webp 등의 확장자의 이미지를 화면에 표시하는 위젯입니다.</p>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'subscriptions',size:15,lineHeight:18})} </div>&#91;동영상&#93; URL을 통해서 유튜브 등 인터넷상에 있는 동영상을 보여주는 위젯입니다.</p>
    <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'timer',size:15,lineHeight:18})} </div>&#91;타이머&#93; 특정 시간까지 남은 시간을 표시하는 위젯입니다.</p>
    </div>
    <div style={{marginTop:'20px'}}/>
    <h3>위젯 편집</h3>
    <div className = "infoImage">
    <img src={EG_widget2} />
    </div>
    <div className = "infoMessage" style={{textAlign:'center'}}>
      위젯의 편집은 주로 편집화면과 편집패널의 {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})}위젯편집 탭에서 이루워집니다.
    </div>
    <div>
      <p>- &#91;편집 화면&#93; 편집할 위젯을 선택 할 수 있습니다. 선택 후 메뉴로 위젯을 변형시켜 옵션을 변경 할 수 있습니다.</p>
      <p>- &#91;편집 패널&#93; 위젯의 다양한 세부 옵션과 애니메이션 효과를 편집 할 수 있습니다.</p>
    </div>
    <p>
      편집화면에 있는 <b>위젯을 클릭하면</b> 왼편의 편집패널이 자동으로 해당 <b>위젯을 편집 할 수 있는 위젯편집 탭</b>으로 변경됩니다.
    </p>
    <div style={{marginTop:'20px'}}/>
    <h3>편집화면에서의 위젯 편집</h3>
    <div className = "infoImage">
    <img src={EG_widget2_1} style={{width:300,margin:'auto'}} />
    </div>
    <div className = "infoMessage">
      위젯을 선택하면 해당 위젯이 상단에 위젯 설정 메뉴와 위젯의 위치&크기를 편집할 수 있는 핸들이 나옵니다.
    </div>

    <h3>위젯 핸들을 통한 편집</h3>
    <div>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'open_with',size:15,lineHeight:18})} </div>위젯을 잡고 드레그 하면 위젯을 이동 시킬 수 있습니다. </p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'left_click',size:15,lineHeight:18})} </div>위젯 핸들을 잡고 드레그 하면 위젯의 사이즈를 조절 할 수 있습니다. </p>
    </div>
    <h3>위젯 상단 메뉴를 통한 편집</h3>
    <div>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'content_copy',size:15,lineHeight:18})} </div>위젯을 복사합니다. </p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'visibility_off',size:15,lineHeight:18})} </div>위젯을 송출화면에서 보이지 않도록 합니다. </p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'flip_to_front',size:15,lineHeight:18})} </div>위젯 순서를 맨 앞으로 변경합니다.</p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'flip_to_back',size:15,lineHeight:18})} </div>위젯 순서를 맨 뒤로 변경합니다.</p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'delete',size:15,lineHeight:18})} </div>위젯을 삭제합니다</p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})} </div>위젯 추가 설정 메뉴를 보여줍니다.</p>
    </div>
    <div className = "infoMessage" style={{textAlign:'center'}}>
      위젯에는 순서가 있습니다. 앞에 있는 위젯이 뒤에 있는 위젯을 가리게 됩니다.
    </div>


    <div style={{marginTop:'20px'}}/>
    <h3>추가 설정 메뉴에서의 위젯 편집</h3>
    <div className = "infoImage">
    <img src={EG_widget2_2} style={{width:300,margin:'auto'}} />
    </div>
    <div className = "infoMessage" style={{textAlign:'center'}}>
    <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})}</div>버튼 또는 마우스로 위젯 오른쪽 클릭을 하면 추가 설정 메뉴를 볼 수 있습니다.
    </div>
    <p> 위젯의 추가 설정 메뉴는 상단 메뉴의 옵션들과 더불어 <b>더 많은 옵션들을 제공합니다.</b></p>
    <div>
      <p> &#91;되돌리기&#93; 준비중인 기능입니다. 전에 한 행동을 취소합니다.</p>
      <p> &#91;순서변경&#93; 위젯의 순서를 변경합니다. 상단 메뉴보다 더 많은 옵션을 제공합니다.</p>
      <p> &#91;복사하기&#93; 위젯을 복사합니다. 상단 메뉴보다 더 많은 옵션을 제공합니다.</p>
      <p> &#91;위젯공유&#93; 준비중인 기능입니다.</p>
    </div>
    <div style={{marginTop:'20px'}}/>
    <h3>편집패널에서 위젯편집</h3>
    <div className = "infoImage">
    <img src={EG_widget3} style={{width:300,margin:'auto'}} />
    </div>


    <div className = "infoMessage" style={{textAlign:'center'}}>
      편집패널에서는 선택한 위젯의 상세정보를 수정 할 수 있습니다.
    </div>

    <div>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'deployed_code',size:15,lineHeight:18})} </div>위젯의 모양에 대해 편집합니다.</p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'acute',size:15,lineHeight:18})} </div>위젯의 효과에 대해 편집합니다.</p>
    </div>

    <div style={{marginTop:'20px'}}/>
    <div className = "infoImage">
    <img src={EG_widget3_1} style={{width:400,margin:'auto'}} />
    </div>
    <div className = "infoMessage" style={{textAlign:'center'}}>
      편집패널에서는 여러 편집 요소를 주제로 다양한 폼이 있습니다.<br/>
      하나의 폼에서는 해당 폼의 주제, 위젯을 수정할 수 있는 버튼과 입력창이 있습니다.
    </div>
    <p> 폼을 통해서 값을 변경하면 바로 위젯이 변경됩니다. 편집화면과 송출중인 채널을 통해서 확인 할 수 있습니다. </p>

    <div style={{marginTop:'20px'}}/>
    <h3>도구 패널에서 위젯 편집하기</h3>

    <div className = "infoImage">
    <img src={EG_widget4_1} />
    </div>
    <div className = "infoMessage" style={{textAlign:'center'}}>
      도구 패널에서는 {Editor.icon({icon:'list',size:15,lineHeight:18})} 위젯목록 탭에서 위젯의 순서를 바꾸거나 이름을 변경 할 수 있습니다.
    </div>

    <div>
      <p> - 선택된 위젯은 테두리가 흰색으로 빛납니다. </p>
      <p> - 핸들 {Editor.icon({icon:'drag_indicator',size:15,lineHeight:18})} 부분을 드레그해서 위젯의 순서를 바꿀수 있습니다. </p>
      <p> - 오른쪽 클릭을 통해 위젯의 추가 설정 메뉴를 열 수 있습니다.</p>
    </div>
    <div style={{marginTop:'20px'}}/>
  </article>
  )


  const PageGuide = (
    <article id = "GuideBook">
    <div style={{marginTop:'20px'}}/>
    <h3>
      오버랩 편집하기
    </h3>
    <div className = "infoImage">
      <img src={EG_page1}/>
    </div>
      <p> {Editor.icon({icon:'stack',size:15,lineHeight:18})} <b>화면 편집</b>탭 에서는 프로젝트 내 페이지를 관리하며 특정 페이지를 송출 할 수 있습니다. </p>
      <div style={{marginTop:'20px'}}/>
      <h3> 왼쪽 상단 메뉴 소개</h3>
      <div className = "infoMessage">
        왼쪽 상단 메뉴는 페이지를 추가하거나 페이지의 상태에 대해 알 수 있는 도구들이 있습니다.
      </div>

      <div>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'new_window',size:15,lineHeight:18})} </div>프로젝트에 빈 페이지를 추가합니다. </p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'shop',size:15,lineHeight:18})} </div>페이지를 다운로드 받을수 있는 스토어로 이동합니다. </p>
      <p> <div className='iconBox' style={{background:'orange'}}> 1 </div>편집화면에 선택된 페이지의 번호입니다. </p>
      </div>

      <div style={{marginTop:'20px'}}/>
      <h3> 상태별 페이지 프리뷰 </h3>
      <div className = "infoImage">
        <img src={EG_page2}/>
      </div>
      <div className = "infoMessage">
        페이지 상태에 따라 프리뷰는 다르게 표시됩니다. 페이지 번호와 프리뷰의 배경을 통해 페이지의 상태를 파악 할 수 있습니다.
      </div>
      <div>
      <p> <div className='iconBox' style={{background:'#222'}}> 2 </div>&#91;일반 페이지&#93; 편집중도 아니며 송출중도 아닌 페이지 입니다.</p>
      <p> <div className='iconBox' style={{background:'orange'}}> 3 </div>&#91;편집중인 페이지&#93; 편집화면에서 보여지고 있는 페이지 입니다. </p>
      <p> <div className='iconBox' style={{background:'red'}}> 4 </div>&#91;송출중인 페이지&#93; 선택된 채널로 송출되고 있는 페이지 입니다. </p>
      </div>

      <div style={{marginTop:'20px'}}/>
      <h3> 프리뷰에서 페이지 수정하기 </h3>
      <div className = "infoImage">
        <img src={EG_editor2_1} style={{width:300,margin:'auto'}} />
      </div>
      <div className = "infoMessage">
        페이지 프리뷰에서는 페이지 순서, 제목, 미리보기, 등을 확인하고 수정할 수 있습니다.
      </div>
      <div>
      <p> - 프리뷰를 클릭해서 편집할 페이지를 선택 할 수 있습니다.</p>
      <p> - 프리뷰를 드레그 해서 페이지 순서를 바꿀 수 있습니다.</p>
      <p> - 페이지의 제목 부분을 클릭하고 제목을 변경 할 수 있습니다.</p>
      <p> - 프리뷰를 오른쪽 클릭해서 페이지의 추가 설정 메뉴를 볼 수 있습니다.</p>
      </div>

      <div className = "infoMessage">
        마우스 포인터를 프리뷰에 가깝게 다가가면 페이지 관련 메뉴가 보입니다.
      </div>
      <div>
      <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="red" stroke="#fff" stroke-width="2"></circle></svg> </div> 연결된 채널에 해당 페이지를 송출 합니다.</p>
      <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="grey" stroke="#fff" stroke-width="2"></circle></svg> </div> <small>채널이 연결되지않으면 해당 버튼이 회색으로 바뀌며 페이지를 송출 할 수 없습니다.</small> </p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'content_copy',size:15,lineHeight:18})} </div>페이지를 복사하고 붙여넣습니다. </p>      
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})} </div>페이지의 추가 설정 메뉴를 봅니다. 오른쪽 클릭과 같은 역활을 합니다. </p>

      </div>
      {/* <p>  마우스 포인터를 페이지에 가깝게 다가가면  </p> */}
    <div style={{marginTop:'20px'}}/>
    <h3> 추가 설정 메뉴 </h3>
    <div className = "infoImage">
        <img src={EG_page3} style={{width:300,margin:'auto'}} />
    </div>
    <div className = "infoMessage">
    <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'arrow_drop_down',size:15,lineHeight:18})}</div>버튼 또는 프리뷰에서 <b>마우스 오른쪽 클릭</b>을 해서 추가 설정 메뉴를 불러올 수 있습니다.
    </div>
    <p> 추가 설정메뉴에서 다음과 같은 설정을 할 수 있습니다.</p>
    <div>
      <p style={{display:'flex',alignItems:'center'}}> <div className='iconBox' style={{background:'#222'}}> <svg id="broadcastIcon" width="25px" height="25px"><circle cx="12.5" cy="12.5" r="7" fill="red" stroke="#fff" stroke-width="2"></circle></svg> </div>&#91;송출하기&#93; 연결된 채널에 해당 페이지를 송출 합니다.</p>
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'content_copy',size:15,lineHeight:18})} </div>&#91;복사하기&#93; 페이지를 복사하고 붙여넣습니다. </p>      
      <p> <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'remove_selection',size:15,lineHeight:18})} </div>&#91;삭제하기&#93; 페이지를 삭제합니다. </p>
    </div>
    {/* <p> 더 자세한 가이드는 왼쪽 메뉴에서 확인 할 수 있습니다. </p> */}
    <div style={{marginTop:'20px'}}/>
  </article>
  )



  const LinkOBSGuide = (
    <article id = "GuideBook">
    <div style={{marginTop:'20px'}}/>
    <h3>
        OBS와 오버랩 연결하기
    </h3>
    <div className = "infoImage">
      <img src={EG_obs1}/>
    </div>
      <p> OBS는 비디오 녹화 및 라이브 스트리밍을 위한 인기 있는 무료 오픈소스 소프트웨어입니다.<br/>Twitch, YouTube, Facebook과 같은 플랫폼에서 스트리밍을 진행하는 데 널리 사용됩니다.</p>
      <div className = "infoMessage">
        OBS를 아직 설치하지 않으셨나요? OBS는 <a style={{color:'#3425AF', textDecoration:'underline'}} href={"https://obsproject.com/ko"}>이곳에서</a> 다운로드 받을 수 있습니다.
      </div>
      <div style={{marginTop:'20px'}}/>
      <h3>STEP.1 연결할 채널을 선택합니다.</h3>
      <div className = "infoImage">
        <img src={EG_obs2}/>
      </div>

      <div>
      <p> - 로비 페이지에서 채널 탭으로 이동합니다.</p>
      <p> - 채널 탭에서 연결할 채널을 선택해주세요.</p>
      </div>

      <div className = "infoMessage">
        아직 오버랩 프로젝트와 채널이 없다면 다른 가이드를 참고해 생성해주세요
      </div>

      <div className = "infoImage">
        <img src={EG_obs3}/>
      </div>

      <div>
      <p> 탭 중간에 위치한 <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'link',size:15,lineHeight:18})} </div> 버튼을 눌러 선택한 채널의 주소를 복사합니다. </p>
      </div>
      <div style={{marginTop:'20px'}}/>
      <h3>STEP.2 선택한 채널과 OBS를 연결합니다.</h3>
      <div className = "infoImage">
        <img src={EG_obs5}/>
      </div>
      <div>
      <p>먼저 OBS 소프트웨어를 실행시켜주세요</p>
      </div>
      <div className = "infoImage">
        <img src={EG_obs4}/>
      </div>
      <div>
      <p> - OBS 소프트웨어의 하단 "소스목록" 목록 부분을 우클릭 또는 <div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'add',size:15,lineHeight:18})} </div> 버튼을 누릅니다.</p>
      <p> - 이후 나오는 메뉴창에서 "추가 &gt; 브라우저" 를 선택합니다. </p>
      </div>

      <div className = "infoImage">
        <img src={EG_obs6}/>
      </div>
      <div>
      <p> - 브라우저 추가 창이 나오면 &#91;새로 만들기&#93; 를 선택해주세요</p>
      <p> - 새로만들기 아래의 소스제목 필드에서 소스의 이름을 만들어주세요</p>
      <p> - 이름을 만든 후에 확인 버튼을 눌러주세요</p>
      </div>

      <div className = "infoImage">
        <img src={EG_obs7}/>
      </div>
      <div className = "infoMessage">
       확인 버튼을 눌러 속성창이 나오면 STEP 1 에서 선택한 채널 탭의 정보를 참고해서 아래 필드들의 값을 넣어주세요
      </div>
      <div className = "infoImage">
        <img src={EG_obs9_1}/>
      </div>
      <p> - OBS에서 송출할 채널주소를 입력해주세요 STEP 1 에서 복사한 주소입니다.</p>
      <div>
        <p > &#91;URL&#93; 복사한 채널의 주소를 입력합니다. 채널 정보에서<div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'link',size:15,lineHeight:18})}</div>버튼을 눌러 복사 할 수 있습니다. </p>
      </div>
      <div style={{marginTop:'20px'}}/>
      <div className = "infoImage">
        <img src={EG_obs9_2}/>
      </div>
      <p> - OBS에서 채널이 송출될 해상도를 설정합니다. 채널 탭의 해상도와 일치시켜주세요</p>
      <div>
        <p > &#91;너비&#93; 채널의 가로 해상도를 입력합니다. 채널 정보에서<div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'resize',size:15,lineHeight:18})}</div>필드의 해상도 정보를 참고해주세요</p>
        <p > &#91;높이&#93; 채널의 세로 해상도를 입력합니다. 채널 정보에서<div className='iconBox' style={{background:'#222'}}> {Editor.icon({icon:'resize',size:15,lineHeight:18})}</div>필드의 해상도 정보를 참고해주세요</p>
      </div>
      <div className = "infoImage">
        <img src={EG_obs9_3}/>
      </div>
      <p> - 두개의 체크박스와 FPS 필드값을 넣은 후 확인 버튼을 눌러주세요</p>
      <div>
        <p > {Editor.icon({icon:'check_box',size:15,lineHeight:18})} 사용자가 임의로 설정한 프레임 레이트 사용 </p> 
        <p > {Editor.icon({icon:'check_box',size:15,lineHeight:18})} OBS를 통해 오디오 조절 </p> 
        <div style={{marginTop:'10px'}}/>
        <p > &#91;FPS&#93; 1초당 프레임 숫자 입니다. 60을 권장하나 저사양에서는 30을 해주세요 </p> 

      </div>

        <div style={{marginTop:'20px'}}/>
      <div className = "infoImage">
        <img src={EG_obs10}/>
      </div>
      <div className = "infoMessage">
       이제 OBS와 오버랩이 연결되었습니다. 채널화면과 오버랩의 화면이 일치하는지 확인해주세요
      </div>
       <div style={{marginTop:'40px'}}/>
  </article>
  )


  const StartGuide = (
    
  <article id = "GuideBook">
    <div className = "infoImage">
          <img src={EG_start1}/>
    </div>  
    <h3>
        오버랩에 오신걸 환영합니다.
    </h3>


    <div className = "infoMessage">
      오버랩은 생방송을 쉽게 실시간으로 편집할 수 있는 도구입니다. 
    </div>
    <p > - 오버랩내 위젯들을 통해서 나의 방송 화면을 꾸밀 수 있습니다. </p> 
    <p > - 오버랩내 템플릿을 통해서 다양한 온라인 행사를 손쉽게 준비해보세요</p> 

    <div style={{marginTop:'20px'}}/>
    <h3>STEP.1 프로젝트 생성하기</h3>
    <div className = "infoImage">
          <img src={EG_start2}/>
    </div>  
    <p > 로비페이지 에서 프로젝트 탭을 이동합니다.</p> 
    <div className = "infoImage">
          <img src={EG_start3}/>
    </div>  
    <p > 프로젝트 탭에서 <div className='iconBox' style={{background:'orange',display:'inline', padding:'10px'}}> 새 프로젝트 만들기 </div> 버튼을 눌러 새로운 프로젝트를 생성합니다. </p> 
    <div className = "infoImage">
          <img src={EG_start4}/>
    </div>  
    <div className = "infoMessage">
      오버랩에서는 두가지 방식으로 프로젝트를 제작 할 수 있습니다. 
    </div>
    <div>
      <p> - 템플릿으로 시작하기 : 미리 만들어진 프로젝트에서 일부 정보를 수정해서 사용합니다.</p>
      <p> - 빈 프로젝트로 시작하기 : 처음부터 프로젝트를 만듭니다.</p>
    </div>
    <div style={{marginTop:'20px'}}/>
    <p> 처음 오버랩을 사용하는 사람에게 추천하는 템플릿으로 시작하기를 눌러 다음 화면으로 넘어갑니다. </p>
    <div className = "infoImage">
          <img src={EG_start5}/>
    </div>  
    <p>생성할 템플릿을 선택합니다.</p>
    <div className = "infoMessage">
    템플릿을 누르면 해당 템플릿의 페이지보고 생성 할 수 있는 상세 페이지로 이동합니다.
    </div>
    <div style={{marginTop:'20px'}}/>
    <div className = "infoImage">
          <img src={EG_start6}/>
    </div>  
    <div className = "infoMessage">
    페이지 프리뷰를 클릭하면 해당 페이지를 더 자세하게 볼 수 있습니다.
    </div>
    <p > 템플릿 설명 아래의  <div className='iconBox' style={{background:'orange',display:'inline', padding:'10px'}}> {Editor.icon({icon:'magnify_fullscreen',size:15,lineHeight:18})} 프로젝트 만들기 </div> 버튼을 눌러 템플릿으로 프로젝트를 생성합니다. </p> 
    <div style={{marginTop:'20px'}}/>
    <div className = "infoImage">
          <img src={EG_start7}/>
    </div>  
    <p>프로젝트의 이름을 만듭니다. 이후 다음 버튼을 넘어 다음페이지로 넘어갑니다.</p>
    <div style={{marginTop:'20px'}}/>
    <div className = "infoImage">
          <img src={EG_start8}/>
    </div>  
    <div className = "infoMessage">
      채널은 프로젝트를 송출하가 위해 꼭 필요합니다. 송출된 화면과 스트리밍 도구를 연결해주는 역활을 합니다.
    </div>
    <p> 3가지 방법으로 프로젝트와 채널을 연결 할 수 있습니다.</p>
    <div>
      <p> - 기존 채널과 연결하기 : 미리 만들어진 채널과 연결합니다.</p>
      <p> - 새로운 채널 만들고 연결하기 : 새로운 채널을 생성하고 프로젝트의 기본 채널로 설정합니다.</p>
      <p> - 연결하지 않기 : 채널과 연결하지 않습니다. 편집화면에서 채널과 연결 할 수 있습니다.</p>
    </div>
    <div style={{marginTop:'20px'}}/>
    <p> "새로운 채널 만들고 연결하기" 를 선택하고 채널 이름을 만들어주세요 </p>
    <p > 모든 설정을 완료한 후 <div className='iconBox' style={{background:'#222',display:'inline', padding:'10px'}}> 프로젝트 생성 </div> 버튼을 눌러 프로젝트를 생성합니다. </p> 

    <div style={{marginTop:'20px'}}/>
    <div className = "infoImage">
          <img src={EG_start9}/>
    </div>  
    <p> 새로운 프로젝트가 생성되었습니다. 프로젝트를 선택하면 편집 할 수 있습니다.</p>


    <div style={{marginTop:'20px'}}/>
    <div className = "infoImage">
          <img src={EG_start10}/>
    </div>  
    <p> 편집 하기 버튼을 눌러 새 프로젝트를 편집 할 수 있습니다. </p>
    <div style={{marginTop:'40px'}}/>
  </article>)