// 리엑트 연결


// 서비스 연결
import { functions } from 'lodash';
import * as Widget from 'service/edit/WidgetService'; 
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as widgetModel from 'service/model/widgetModel'
import * as Utility from 'service/other/Utility'; 
////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////
import store from "store";

const templateClick = value => ({type:'templateClick',click : value})
const selectTemplate = id => ({type:'selectTemplate',select : id})
////////////////////////////////////////////////////////

// 테스트 구문
export function connectTest() {
    console.log('Eecoder : 연결테스트')
  }



let actionTimer = null

/**
 * 편집화면에서 사용되는 단축키
 * @param {*} context EditProvider 객체
 * @param {string} code event.keycode 누른 단축키
 */
export function shortcut(context,code,optionKey=[]) {
    
    switch (code) {
        case 'Escape':
            context.modifySelectWidget(null)
            break;
        case 'Backspace':
            Widget.del(context)
            break;
         case 'ArrowUp':
            if(context.action==null){
                context.setAction('moveKeyAction')
            }
            if(actionTimer){
                clearTimeout(actionTimer);
            }
            actionTimer = setTimeout(() => { context.setAction(null) }, 500); 
            Widget.move(context,0,-1); 
            break;
        case 'ArrowDown':
            if(context.action==null){
                context.setAction('moveKeyAction')
            }
            if(actionTimer){
                clearTimeout(actionTimer);
            }
            actionTimer = setTimeout(() => { context.setAction(null) }, 500); 
            Widget.move(context,0,1); 
            break;  
        case 'ArrowRight':
            if(context.action==null){
                context.setAction('moveKeyAction')
            }
            if(actionTimer){
                clearTimeout(actionTimer);
            }
            actionTimer = setTimeout(() => { context.setAction(null) }, 500); 
            Widget.move(context,1,0); 
            break;
        case 'ArrowLeft':
            if(context.action==null){
                context.setAction('moveKeyAction')
            }
            if(actionTimer){
                clearTimeout(actionTimer);
            }
            actionTimer = setTimeout(() => { context.setAction(null) }, 500); 
            Widget.move(context,-1,0); 
            break;
        default:
            break;
    }
 
}




/**
 * [broadcast] 버튼을 눌러서 채널의 선택하고 있는 오버랩 페이지를 변경합니다.
 * @param {*} context EditProvider 객체
 * @param {string} targetScreen 변경할 스크린의 uuid 
 * @param {number} layer 변경할 스크린의 레이어 순서
 */

export function broadcast (context,targetScreen,layer=0) {

    const viewPoint = {
        'uuid' : targetScreen,
        'layer' : layer,
        'option' : null
    }

    if(!context.view){
        console.error(`broadcast : \n ⛔️ 에디터에 채널(view)가 연결되지 않았습니다.`);
        return
    }

     let pointerList = [...context.view.view_pointer];

    // 같은 레이어에 스크린이 존재하면 스크린을 삭제하고 선택된 스크린으로 교체 합니다. 
    const Prev = pointerList.findIndex(item => item.layer === viewPoint['layer']);
    let prevPage = null

    const Pages = context?.overlap?.content?.page;

    if (Prev !== -1) {
        // 이전페이지가 존재합니다.
        const PrevPageID =  context?.view?.view_pointer?.[Prev]?.uuid

        if(PrevPageID == targetScreen){
            console.error(`broadcast : \n ⛔️ 송출할 페이지가 이전의 페이지와 같습니다.`);
            context.setView({'view_status': {status : 'play'}},{});
            return
        }
        pointerList.splice(Prev, 1);
        prevPage = Pages.filter(page => page.uuid == PrevPageID);
        prevPage = prevPage?.[0]??{};
    }

    /*
    [autoPlay 및 캐쉬를 위한 구문]
    
    - 아래 구문은 채널에서 페이지 변경시점에 채널 캐쉬에 위젯이 자동으로 등록되는 오토플레이 옵션을 활성화 한 위젯을 찾고 캐쉬를 수정하는 구문입니다.
    - 오토플레이는 위챗 데이터에서 (data.meta.autoplay = true) 일때 활성화 됩니다. 
    - 오토플레이시 일어나는 일에 대해서는 WidgetModel.js 에서 연결한 펑션 에서 정의 할 수 있습니다.
    - 오토플레이가 실행되기 위해서는 다음과 같은 조건이 필요합니다. 위젯의 데이터에서 (data.meta.autoplay = true) 일것, WidgetModel.js 에서 해당 위젯 에 autoPlay : function 이 존재할것, 유효한 function이 설정되어 있을것

    [코드 로직]
    1. 선택한 페이지와 이전 페이지를 비교해서 처음 실행되는 위젯 배열을 만듭니다. (위젯 별명이 같으면 같은 위젯으로 판단)
    2. 처음 실행되는 위젯 배열을 순환하면서, 위젯 캐쉬에 autoPlay 펑션의 Return 값을 넣습니다.
    */

    // 지금 선택된 페이지를 가지고 옵니다.
    let nextPage =  Pages.filter(page => page.uuid == targetScreen);

    // 지금 선택된 페이지가 없다면 빈 배열(빈화면)을 반환합니다.
    nextPage = nextPage?.[0]??{};

    // 선택된 페이지 내 위젯을 위젯 레퍼런스와 합칩니다.
    const MergeWidget = nextPage?.page_widget.map((widget)=>{return {...widget,data:DeCoder.getMergeWidget(widget)}}) 

    // 이전 페이지에서 별칭이 있는 위젯 목록을 가지고 옵니다.
    const PrevAlias = new Set(prevPage?.page_widget?.map(item => item.alias?item.type+'_'+item.alias:null));
    const FilteredWidgetList = MergeWidget.filter(item => 
        !PrevAlias.has(item.type + '_' + (item.alias || ''))
      );
    const Cache =  {}

    
    // 새 위젯 배열을 순회하면서 각 위젯의 autoPlay 펑션을 실행합니다.
    FilteredWidgetList.forEach(newWidget => {
    const UUID = newWidget?.uuid
    const Alias = newWidget?.alias
    const TYPE = newWidget?.type
    // 오토플레이 사용 여부
    const AUTOPLAY = newWidget?.data?.meta?.autoplay??false
    if(AUTOPLAY){
        const WidgetModel =  DeCoder.getWidgetModel(newWidget)
        // autoPlay:function 활성화 여부 체크
        if(WidgetModel.hasOwnProperty('autoPlay')){
            const widgetCache  = WidgetModel?.autoPlay({widget:newWidget});
            const identifier = (Alias?`alias_${TYPE}_${Alias}`:`uuid_${TYPE}_${UUID}`);
            Cache[identifier] = widgetCache
        }else{
            console.error(`ActionService [autoplay] : \n ⛔️ ${TYPE}위젯정의에서 autoPlay:function 함수가 활성화 되지 않았습니다.`);
        }
    }
    }); 

    /* [변경된 정보로 채널 정보 변경하기] */

    pointerList.push(viewPoint);
    pointerList.sort((a, b) => a.layer - b.layer);
    const modifiedView = {'view_pointer': pointerList,'view_status': {status : 'play'}};
    context.setView(modifiedView,Cache);
}

export function broadcastPause (context) {

    if(!context.view){
        console.error(`broadcast : \n ⛔️ 에디터에 채널(view)가 연결되지 않았습니다.`);
        return
    }
    context.setView({'view_status': {status : 'pause'}},{});
}

export function broadcastPlay (context) {

    if(!context.view){
        console.error(`broadcast : \n ⛔️ 에디터에 채널(view)가 연결되지 않았습니다.`);
        return
    }
    if(!context.view?.view_status){
        console.error(`broadcast : \n ⛔️ 에디터에 채널(view)가 연결되지 않았습니다.`);
        return
    }
    context.setView({'view_status': {status : 'play'}},{});
}

export function broadcastStop (context) {

    if(!context.view){
        console.error(`broadcast : \n ⛔️ 에디터에 채널(view)가 연결되지 않았습니다.`);
        return
    }
    context.setView({'view_pointer': [],'view_status': {status : 'stop'}},{});
}