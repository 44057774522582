import store from "store";
import { produce } from 'immer'
import _ from 'lodash'

// 이미지 용량 정리 라이브러리
import axios from 'axios';
import Compressor from 'compressorjs';

export function browserCheck () {
    let agent = navigator.userAgent.toLowerCase()
    if (agent.indexOf("chrome") != -1) {
        return "chrome"
    }
    else if (agent.indexOf("safari") != -1) {
        return "safari"
    }
    else if (agent.indexOf("firefox") != -1) {
        return "firefox"
    }
    else if(agent.indexOf("chrome")){

    }
    else if(agent.indexOf("chrome")){

    }
}
////////////////////////////////////////////////////////
// 위젯의 사이즈 계산기 px 이 아닌 % 로 정의된것을 현 화면 사이즈에 맞춰 변경해줍니다.
////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    // width 계산의 경우
    ////////////////////////////////////////////////////////
    export function widthCalculator (value) {
        return sizeCalculator(value,'width')
    }
    ////////////////////////////////////////////////////////
    // height 계산의 경우
    ////////////////////////////////////////////////////////
    export function heightCalculator (value) {
        return sizeCalculator(value,'height')
    }
    ////////////////////////////////////////////////////////
    // 새 페이지 추가 (템플릿에서 가저오기)
    ////////////////////////////////////////////////////////
    export function sizeCalculator (value,type) {
    let result
    if (String(value).indexOf('%')!=-1){
        const state = store.getState(); // 현재 상태를 가져옵니다.
        const {broadCast} = state; // 편의상 비구조화 할당
        switch (type) {
            case 'width':
                return (broadCast.size.width/100*Number(value.substring(0,value.indexOf('%'))))
            case 'height':
                return (broadCast.size.height/100*Number(value.substring(0,value.indexOf('%'))))
            default:
                return null
            }
    } 
    else if (value=='auto'){
        return 'auto'
    } else {
        return value
    }
    }

////////////////////////////////////////////////////////
// 딥카피
////////////////////////////////////////////////////////
    /** deepCopy | 깊은 복사를 합니다. state 안에 있는 object 를 복사할때 유용합니다.
     * @param {object} value 복사할 대상 
     * @returns 깊은 복사된 값을 리턴합니다.
     *  */ 
export function deepCopy (value) {
    const copy = _.cloneDeep(value);
    return copy
}

    /** throttle | 지정된 시간내 이벤트 가 중복되는 경우 1회만 반복합니다.
     * @param {function} func 복사할 대상 
     * @param {function} time 시간 지정 기본값 : 16 (1초에 60번)
     * @returns 깊은 복사된 값을 리턴합니다.
     *  */ 
     export function throttle (func,time=16) {
        const throttlFunc = _.throttle(func,time);
        return throttlFunc
    }

////////////////////////////////////////////////////////
// 배열 비교
////////////////////////////////////////////////////////
  /** arrayEqual | 두 배열을 비교합니다. 다만 배열 안에 객체가 있는경우 주소값만 비교합니다.
     * @param {object} arr1 비교할 배열 1
     * @param {object} arr2 비교할 배열 2
     * @returns 순서와 내용이 같은 배열인경우 True 다른 배열인 경우 False 을 반환합니다.
     *  */ 
export function arrayEqual(arr1, arr2) {
    // 두 배열의 길이가 다르면, 배열은 같지 않습니다.
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    // 같은 길이의 배열의 경우, 각 요소를 비교합니다.
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
  
    // 모든 요소가 동일하면 배열이 같습니다.
    return true;
  }

  /**
   * lodash 를 사용한 빠른 비교
   * @param {*} element1 
   * @param {*} element2 
   * @returns 
   */
  export function elementEqual(element1, element2) {
    return _.isEqual(element1, element2);
  }



////////////////////////////////////////////////////////
// 이미지 용량 줄이기
////////////////////////////////////////////////////////
export function imageCompressor (ImageURL,callBack,quality=0.6) {
    const state = store.getState(); // 현재 상태를 가져옵니다.
    const {broadCast} = state; // 편의상 비구조화 할당

     new Compressor(ImageURL, {
        quality: quality,
        maxWidth: broadCast?.size?.width? broadCast?.size?.width: 1920,
        maxHeight: broadCast?.size?.height? broadCast?.size?.height: 1080,
        success(result) {
            console.log(result,'결과')
            callBack(result)
        },
        error(err) {
            console.log(err,'에러')
            // return null
        },
      })
}

/**
 * 이미지 압축 함수
 * @param {Object} options - 옵션 객체
 * @param {File} options.file - 압축할 파일
 * @param {number} options.quality - 압축 품질 (0에서 1 사이의 값)
 * @param {number} [options.maxWidth] - 이미지의 최대 너비 (픽셀)
 * @param {number} [options.maxHeight] - 이미지의 최대 높이 (픽셀)
 * @returns {Promise<File>} - 압축된 파일을 반환하는 Promise
 */
export async function compressImage({ file, quality=1, maxWidth=1920, maxHeight=1080 }) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxWidth,
      maxHeight,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
}

////////////////////////////////////////////////////////
// 이미지 사이즈 확인
////////////////////////////////////////////////////////
export async function getImageSize (imageUrl) {

    let img = new Image();
    img.src = imageUrl;
    
    let dumiWidth = await img.width
    let dumiHeight = await img.height
    // console.log(img.width,img.height)
    let width = await img.width? await img.width:200
    let height = await img.height? await img.height:200
    // console.log(imageWidth2,imageHeight2)
    return {width, height}
}

////////////////////////////////////////////////////////
// 이미지 사이즈 확인
////////////////////////////////////////////////////////
/**
 * 해당 파일이 어떤 류의 파일인지 분석합니다. 오버랩에서 지원하지 않는 파일이면 null을 반환합니다.
 * @param {*} fileType 파일의 MIME
 * @returns image,video,pdf
 */
export function fileMIME (fileType) {
  const imageTypes = [
    "image/png",
    "image/jpeg",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/svg+xml"
  ];

  const videoTypes = [
      "video/mp4",
      "video/webm",
      "video/ogg",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-matroska",
      "video/mpeg"
  ];

  const pdfType = "application/pdf";

  if (imageTypes.includes(fileType)) {
      return "image";
  } else if (videoTypes.includes(fileType)) {
      return "video";
  } else if (fileType === pdfType) {
      return "pdf";
  } else {
      return null;
  }
}


export function fileSize(bytes) {
  const ONE_KB = 1024; // 1 kB in bytes
  const ONE_MB = ONE_KB * 1024; // 1 MB in bytes
  const ONE_GB = ONE_MB * 1024; // 1 GB in bytes

  if (bytes >= ONE_GB) {
      // Convert to GB and format to 2 decimal places
      const sizeInGB = (bytes / ONE_GB).toFixed(2);
      return `${sizeInGB} GB`;
  } else if (bytes >= ONE_MB) {
      // Convert to MB and format to 2 decimal places
      const sizeInMB = (bytes / ONE_MB).toFixed(2);
      return `${sizeInMB} MB`;
  } else if (bytes >= ONE_KB) {
      // Convert to kB and format to 2 decimal places
      const sizeInKB = (bytes / ONE_KB).toFixed(2);
      return `${sizeInKB} KB`;
  } else {
      // Just return the size in bytes
      return `${bytes} bytes`;
  }
}



////////////////////////////////////////////////////////
// 랜덤 변수 생성
////////////////////////////////////////////////////////
export function random(option={}){

    
    let min = option?.min!=undefined ? option.min : 0 
    let max = option?.max!=undefined ? option.max : 1
    let type = option?.type!=undefined ? option.type : 'float'


    switch (type) {
        case 'int':
            return Math.floor(Math.random() * (max+0.99999 - min) + min)
        case 'float': 
            return Math.random() * (max - min) + min
        default:
            return Math.random() * (max - min) + min
    }
}

////////////////////////////////////////////////////////
// 두 객체를 합치는 유틸
////////////////////////////////////////////////////////
export const mergeDeep = (target,reference) => {
    const output = { ...reference };
      // source 객체의 각 프로퍼티에 대해 반복
      Object.keys(target).forEach(key => {
        // 현재 키에 대한 값이 객체인지 확인
        if (isObject(reference[key]) && isObject(target[key])) {
          // 두 값 모두 객체인 경우 재귀적으로 병합
          output[key] = mergeDeep( target[key] ,reference[key]);
        } else {
          // 아니라면 source의 값을 사용
          output[key] = target[key];
        }
      });
      return output;
  }
  



// 값이 객체인지 확인하는 함수
function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

////////////////////////////////////////////////////////
// 두 객체를 합치는 유틸
////////////////////////////////////////////////////////

/**
 * 객체 에서 keys 와 value 를 찾아 합칩니다.
 * @param {e} widget 위젯 원본 객체
 * @param {*} keys [카테고리,속성이름]
 * @param {*} value 
 */
export function setNestedObject(widget, keys, value) {
    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        if (!acc[key]) acc[key] = {};
      }
      return acc[key];
    }, widget);
  }
  
  

    /** uuidMake | uuid 를 생성합니다.
     * @returns uuid 값을 리턴합니다.
     *  */ 
export function uuidMake() {
    function s4() {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }

////////////////////////////////////////////////////////
// 글로벌 펑션 관련
////////////////////////////////////////////////////////
export const replaceGlobal  = (target,option='value') => {

  if(target?.indexOf('global_') === 0){
    let parts = target.split('_'); // 언더스코어(_)를 기준으로 배열 생성
    switch (option) {
      case 'value':
        return  parts[parts.length - 1]; // 배열의 마지막 요소 접근
      case 'key':
        return  parts[1]; 
      case 'all':
          return {'key' : parts[1], 'value' : parts[parts.length - 1]}
      default:
        break;
    }
  }else{
    console.error(`Utility : \n ⛔️ 글로벌 value 가 아닙니다. :  ${target} `);
  }
}

////////////////////////////////////////////////////////
// COLOR | 색상 계산기
////////////////////////////////////////////////////////
/**
 * 16진수 값을 10진수로 변환하는 함수
 * @param {string} hex - 16진수 값 (2자리 이상)
 * @returns {number} 10진수 값
 */
function hexToDecimal(hex) {
  return parseInt(hex, 16);
}
/**
 * 10진수 값을 16진수로 변환하는 함수
 * @param {number} decimal - 10진수 값
 * @returns {string} 16진수 값 (2자리 이상)
 */
function decimalToHex(decimal) {
  return decimal.toString(16).padStart(2, '0');
}

/**
 * 헥사 값에 10진수 차이를 적용하는 함수
 * @param {string} hex - 입력 헥사 값
 * @param {number} decimalDifference - 적용할 10진수 차이 값
 * @returns {string} 변경된 헥사 값
 */
export const colorDiff = (hex, redDiff,greenDiff,blueDiff) => {
  
    // 헥사 값에서 "#"을 제거하고 RGB 부분을 분리
    const hexClean = hex.startsWith('#') ? hex.slice(1) : hex;
    const rHex = hexClean.slice(0, 2);
    const gHex = hexClean.slice(2, 4);
    const bHex = hexClean.slice(4, 6);
  
    // 각 색상 값을 10진수로 변환
    const rDec = hexToDecimal(rHex);
    const gDec = hexToDecimal(gHex);
    const bDec = hexToDecimal(bHex);
  
    // 변경할 10진수 차이를 적용하고 범위(0-255)를 벗어나지 않도록 제한
    const newR = Math.max(0, Math.min(255, rDec + redDiff));
    const newG = Math.max(0, Math.min(255, gDec + greenDiff));
    const newB = Math.max(0, Math.min(255, bDec + blueDiff));
  
    // 새로운 색상 값을 16진수로 변환
    const newRHex = decimalToHex(newR);
    const newGHex = decimalToHex(newG);
    const newBHex = decimalToHex(newB);
  
    // 최종 헥사 값 반환
    return `#${newRHex}${newGHex}${newBHex}`;
  }