////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"


// 컴포넌트 연결
import MediaSourceModule from 'components/edit/editModule/MediaSourceModule'; // 텍스트 모듈
import InfoButtonModule from 'components/edit/editModule/InfoButtonModule'; // 이미지 버튼 모듈
import SizeModule from 'components/edit/editModule/SizeModule';
import FileUploadModule from 'components/edit/editModule/FileUploadModule'; // 파일 업로드 모듈
import ImageButtonModule from 'components/edit/editModule/ImageButtonModule'; // 이미지 버튼
// 서비스 연결
import * as EnCoder from 'service/engine/EnCoder'; 
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService'; 

//이미지
import modify from 'assets/sidebar/image/modify.svg';
import append from 'assets/sidebar/image/append.svg';

import link from 'assets/sidebar/image/link.svg';
import local from 'assets/sidebar/image/local.svg';
import online from 'assets/sidebar/image/online.svg';

import cover from 'assets/sidebar/image/cover.png';
import fill from 'assets/sidebar/image/fill.png';
import scaleDown from 'assets/sidebar/image/scaleDown.png';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ImageEdit extends Component {
    
    state = {
        editorToggle : true,
        imgData : null,
        imageAppend : false,
        imageAppendType : 'link',
        imageTempUrl : null,
    }

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    
    change = (name,value) =>{
        this.props.callBack('style',name,value)
    }
    valueChange = (name,value) =>{
        this.props.callBack('value',name,value)    
    }

    imageAppendTypeChange = (name,value) =>{
        this.setState({
            imageAppendType : value,
        })
    }
    
    imageSourceChange = (name,value) =>{   
        this.getImageSource(value).then((imgResize)=>{
            if(imgResize!=null&&!isNaN(imgResize.width/imgResize.height)){
                let heightValue = ''
                if(this.props.data.size.height){
                    heightValue = this.props.data.size.height
                    if(String(heightValue).indexOf('%')!=-1 ){
                        heightValue = EnCoder.percentValue(heightValue,'height') 
                    }
                }
                this.props.multiCallBack([
                    {property:'size',name:"width",value:Math.floor(heightValue*imgResize.width/imgResize.height)},
                    {property:'value',name,value}
                ])
                return
            }
        }).catch(function(errorUrl){

        })
        this.props.callBack('value',name,value)  
    }

    getImageSource = (url) => {
        return new Promise(function(resolve, reject){
            var img = new Image()
            img.onload = function(){
                resolve(img)
            }
            img.onerror = function(){
                reject(null)
                 
            }
            img.src = url
        })
    }

    imageDataCheck = () => {
        this.getImageSource(this.props.data.value.mediaSource).then((imgElement)=>{
            this.setState({imgData :imgElement });
        }).catch((errorUrl)=>{
     
            this.setState({imgData :null });
        })

    }

    imageFind  = (name,value) => {
        this.imageSourceChange(name,value)
        this.setState({
            imageAppend : false,
            imageAppendType : 'link',
            imageTempUrl : null,
        })
    }

    editTitle = () => {

  
    }

    copyTextToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);

        } catch (err) {
            console.error("Oops, unable to copy", err);
        }
    }      

  
    ////////////////////////////////////////////////////////
    // Image 이미지 위젯
    ////////////////////////////////////////////////////////

    fromLink = () => {
       return <MediaSourceModule value={this.state.imageTempUrl} target={this.props.type} type='mediaSource' title="URL"  placeholder={'여기에 이미지 URL을 복사해주세요'} change={this.imageFind} />
    }
    fromLocal = () => {
        return <FileUploadModule type='mediaSource' title="내 PC 에서" change={this.imageFind} />
    }
    fromOnline = () => {
        return <h5>준비중인 기능입니다.</h5>
    }


    getImageField = () => {

        let mediaSource = null
        switch (this.state.imageAppendType) {
            case 'link':
                mediaSource = this.fromLink()
                break;
            case 'local':
                mediaSource = this.fromLocal()
                break;
            case 'online':
                mediaSource = this.fromOnline()
                break;
            default:
                break;
        }

        let fieldTitle = (!this.props.data.value.mediaSource? '이미지 가저오기':'이미지 수정하기');
        let fileIcon = (!this.props.data.value.mediaSource? append:modify) ;

        return(
            <article className="EditPart MediaSourceModule" > 
                {/* <Image></Image> */}

                <button className="appendImageButton" onClick={() => this.setState({imageAppend : !this.state.imageAppend})}> 
                    <h5>{fieldTitle}</h5>
                    <div className="appendImageIcon">
                        <img src={fileIcon}/>
                    </div>
                </button>
                {this.state.imageAppend? 
                <Fragment>
                    <ImageButtonModule value={this.state.imageAppendType} type ="mediaType"  data={[{val:"link",icon:"add_link"},{val:"local",icon:"folder"},{val:"online",icon:"cloud_queue"},]} change={this.imageAppendTypeChange}/>
                    {mediaSource}   
                </Fragment>
                :""}
                <div>
                    </div> 
                
            </article>
        );
    }

    

    componentDidMount(){
        this.imageDataCheck()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.value.mediaSource!=this.props.data.value.mediaSource){ 
            this.imageDataCheck()
        }
    }


    render() { 

        const Style = {
            label: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            },
            textarea : {
                width: '100%',
                resize: 'none',
                boxSizing:'border-box',
            },
            mediaView : {
                width: '100%',
                MaxHeight : '500px',
            }
        }
        

        return (
            <Fragment>
            {/* <fieldset className="EditModuleField" style={Style.fieldset} > */}
            <section className='ImageEdit'>
                <div className='MediaSourceEditModule'>


                {/* <legend style={Style.legend} > {legend} </legend> */}
                {Editor.WidgetEditTitle(this,{title:'이미지',toggle:false,icon:'image'})}
                
                <div className={this.state.editorToggle?'editContainer containerOpen':'editContainer containerClose'}>
                    {Editor.WidgetEditSubhead({text:'이미지 가저오기',icon:"add_photo_alternate"})}
                    {this.getImageField()}
                    {this.props.data.value.mediaSource?
                    <Fragment>
                    {Editor.WidgetEditSubhead({text:'이미지 정보',icon:"image_search"})}
                    <article className="EditPart MediaInfoModule" > 
                                        
                    <div className='MediaSourcePreview'>
                            <div className='MediaSourceImgPreviewField'>
                                <picture className='MediaSourceImgPreview'>
                                    <source style={Style.mediaView} srcSet={this.props.data.value.mediaSource}/>
                                    <img style={Style.mediaView} src={this.props.data.value.mediaSource}/>
                                </picture>
                                <div className='MediaSourceImgPreviewLink'>
                                    <button className='Neumorphism optionButton ' onClick={()=>{this.copyTextToClipboard(this.props.data.value.mediaSource)}}>
                                    <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} className="material-symbols-outlined iconfont infoIcon">link</span> 
                                    </button>
                                </div>
                            </div>
            
                        {this.state.imgData?
                        <div className='MediaSourceSummary'>
            
                            <dl className='EditSummaryData'>
                                <dt>가로</dt>
                                <dd>{this.state.imgData?.naturalWidth +'px'}</dd>
                            </dl>
                            <dl className='EditSummaryData'>
                                <dt>세로</dt>
                                <dd>{this.state.imgData?.naturalHeight +'px'}</dd>
                            </dl>
                            <dl className='EditSummaryData'>
                                <dt>비율</dt>
                                <dd>{'1 : '+ (this.state.imgData?.width /this.state.imgData?.height).toFixed(2)}</dd>
                            </dl>
                        </div>
                        :''
                        }
                    
                    </div>

                    </article>
                    </Fragment>
                    :''}
                    {/* {Editor.WidgetEditSubhead({text:'이미지 설정',icon:"auto_fix_high"})} */}
                    <article className="EditPart MediaSourceModule" > 
                    <ImageButtonModule value={this.props.data.style.objectFit} type ="objectFit"  data={[{val:"fill",image:fill},{val:"cover",image:cover},{val:"scale-down",image:scaleDown},]} title="이미지 정렬" change={this.change}/>
                    </article>
                </div>
                </div>
            </section>
            </Fragment>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // screenData : state.screenData,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ImageEdit);
  