////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 
import AssistProvider from 'service/provider/AssistProvider';
// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';
import AreaHolder from 'assets/edit/areaHolder.svg';

// 컴포넌트 연결
import EditControl from 'components/edit/editScreen/EditControl';
import EditerDisplay from 'components/display/EditerDisplay';
// 서비스 연결
import * as Widget from 'service/edit/WidgetService' ; 
import * as Editor from 'service/edit/EditService'; 
import * as fileAPI from 'service/api/file' ; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function EditContainer({sizeDetector,panelSize}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);

    const editMargin = {x: 20, y:20};
    // 에딧 화면은 ( 전체 사이즈 - 에딧 패널의 양옆 20px 마진 - 오른쪽 끝 편집페이지와 마진 20px = 60px
    const EditAreaWidth = panelSize?.EditContainer?.width - panelSize?.PreferencesPanel?.width - (editMargin.x*2 + 20)??0;
    const EditAreaHeight = panelSize?.EditContainer?.height - panelSize?.UtilityPanel?.height- (editMargin.y*2 + 20)??0;

    // state
    const [Zoom, setZoom] = useState(0);
    const [widgetDrop, setWidgetDrop] = useState(false);
    const [action, setAction] = useState(null);

    // rel 
    const { width, height, ref } = useResizeDetector();

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    // [useEffect] sizeDetector 엘리먼트의 사이즈를 측정합니다. 
    useEffect(() => {
      if(width!=undefined||height!=undefined){
        sizeDetector('EditContainer',width,height)

        // sizeDetector('EditArea',EditAreaWidth,EditAreaHeight)
      }
      // sizeCalculator();
    }, [width,height]);

    useEffect(() => {
      if(EditAreaWidth!=undefined||EditAreaHeight!=undefined){
   

        sizeDetector('EditArea',EditAreaWidth,EditAreaHeight)
      }
      // sizeCalculator();
    }, [EditAreaWidth,EditAreaHeight]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////



    // EditContainer 의 시작 위치를 구합니다.

    // 확장메뉴 사용시 
    const EditorExtend = context.extendEditor==null ? 0 : (panelSize?.EditContainer?.width) - (panelSize?.PreferencesPanel?.width) ??0  ;

    // 위로 부터 얼마? 
    // 왼쪽으로 부터 얼마?
    const EditAreaTop = panelSize?.EditContainer?.height - panelSize?.UtilityPanel?.height - 40??0;
    const EditAreaLeft = panelSize?.PreferencesPanel?.width + 20 + EditorExtend ;

    // 익스텐드 모드인 경우

    // 편집 패널과 편집화면 사이의 마진 설정


    // console.log('EditAreaWidth',EditAreaWidth,'EditAreaHeight',EditAreaHeight,context.overlap.resolution.height);

    const TargetPage = context.nowPage();

    const previewWidth = EditAreaWidth ;
    const previewHeight = EditAreaHeight ;
    const referenceWidth = context.overlap.meta.resolution.width ;
    const referenceHeight = context.overlap.meta.resolution.height ;



    // 자식 컴포넌트 들의 스케일을 결정합니다.
    let scale = ( (height - editMargin.x) / referenceHeight)??0;
    // 자식 컴포넌트에서 가장 긴 축으로 사용할 기준점을 선택합니다 (가로,세로)
    let dimension = 'height';
    const Ratio = referenceHeight/referenceWidth;
   
    if(previewHeight>=previewWidth*Ratio){
      dimension = 'width';
      scale = (previewWidth / referenceWidth)??0;
    }else{
      dimension = 'height';
      scale = (previewHeight / referenceHeight)??0;
    }
    const broadcast = context?.view?.view_pointer.map(item => item.uuid)??[];





    /**
     * 에딧 화면을 설정합니다.
     * @returns 
     */
    const EditScreen = () => {

      const OverlapPage = context?.overlap?.content?.order.length;
      // 페이지가 하나도 없는 경우
      if(OverlapPage==0){
        return (
          <div id="EditNoti">
            <div id="EditScreenMessage">
              <div className="newWindow" >
                <div>
                {Editor.icon({icon:'new_window',size:70,lineHeight:80, color:'#adadad'})}
                </div>
      
              </div>
              <div className="messageText" >
              페이지가 없습니다.<br/> 새로운 페이지를 추가해보세요
              </div>
            </div>
          </div> 
        )}

      // 선택된 페이지가 없는 경우
      if(TargetPage == null){
        return (
        <div id="EditNoti">
          <div id="EditScreenMessage">
            <div className="newWindow" >
            {Editor.icon({icon:'warning',size:70,lineHeight:80, color:'#adadad'})}
            </div>
            <div className="messageText" >
            선택된 페이지가 없습니다 <br/> 왼쪽에서 페이지를 선택해주세요
            </div>
          </div>
        </div> 
        )
      }else{
        return (
        [<EditControl positionTop={0} positionLeft={EditAreaLeft} scale={scale} targetPage={TargetPage} editMargin={editMargin} baseDimension={dimension} previewWidth={EditAreaWidth+40??null} previewHeight={EditAreaHeight+40??null} context={context} panelSize={panelSize}/>,
        <EditerDisplay page={TargetPage} broadcast={broadcast.includes(context.selectPageUUID)} global={context?.overlap?.global} scale={scale} baseDimension={dimension} position={{top:editMargin.x,left:editMargin.y}} previewWidth={EditAreaWidth} previewHeight={EditAreaHeight} referenceWidth={context.overlap.meta.resolution.width} referenceHeight={context.overlap.meta.resolution.height}/>]
        )
      }
    }


    const dropWidgetGuide = (type) => {
      const BoxStyle = {}
      const Ratio = referenceHeight/referenceWidth;
      switch (type) {
        case null:
          return null
        case 'addWidget':
    
          if(EditAreaHeight>=EditAreaWidth*Ratio){
            BoxStyle['width'] = EditAreaWidth;
    
          }else{
            BoxStyle['height'] = EditAreaHeight;
            BoxStyle['width'] = (EditAreaHeight * referenceWidth / referenceHeight);
       
          }
      
    
          return(
            <div id = "DropWidgetGuideContainer"
            style={{
              height: EditAreaHeight,
              width : EditAreaWidth,
              top : editMargin.x,
              left : editMargin.y,
              alignItems: 'center',
              }}
            >
            <div id = "DropWidgetGuideBOX" style={{ aspectRatio: `${referenceWidth} / ${referenceHeight}`, ...BoxStyle,}}>
    
            <div className="messageText" >
              {Editor.icon({icon:'left_click',size:60,lineHeight:100})}
              
              <p>드래그 앤 드롭으로 위젯 추가하기</p>
            
              <small style={{marginTop:10}}>생성할 위젯을 여기로 드레그 해주세요</small>
            </div>
            <div>
              <img className='AreaHolder AreaHolderLT'
              style={{top : 20 ,left : 20,}}
              width={13} height={13} src={AreaHolder}/>
    
               <img className='AreaHolder AreaHolderRT'
              style={{top : 20 ,right : 20,}}
              width={13} height={13} src={AreaHolder}/>
    
              <img className='AreaHolder AreaHolderLB'
              style={{bottom : 20 ,left : 20,}}
              width={13} height={13} src={AreaHolder}/>
    
              <img className='AreaHolder AreaHolderRB'
              style={{bottom : 20 ,right : 20,}}
              width={13} height={13} src={AreaHolder}/>                       
            </div>
    
            </div>
            </div>
          )

        case 'addFile':

    
          if(EditAreaHeight>=EditAreaWidth*Ratio){
            BoxStyle['width'] = EditAreaWidth;
    
          }else{
            BoxStyle['height'] = EditAreaHeight;
            BoxStyle['width'] = (EditAreaHeight * referenceWidth / referenceHeight);
       
          }
      
    
          return(
            <div id = "DropWidgetGuideContainer"
            style={{
              height: EditAreaHeight,
              width : EditAreaWidth,
              top : editMargin.x,
              left : editMargin.y,
              alignItems: 'center',
              }}
            >
            <div id = "DropWidgetGuideBOX" style={{ aspectRatio: `${referenceWidth} / ${referenceHeight}`, ...BoxStyle,}}>
    
            <div className="messageText" >
              {Editor.icon({icon:'add_photo_alternate',size:60,lineHeight:100})}
              
              <p>이미지 위젯 생성하기</p>
            
              <small style={{marginTop:10}}>위젯으로 생성할 이미지을 여기로 드레그 해주세요</small>
            </div>
            <div>
              <img className='AreaHolder AreaHolderLT'
              style={{top : 20 ,left : 20,}}
              width={13} height={13} src={AreaHolder}/>
    
               <img className='AreaHolder AreaHolderRT'
              style={{top : 20 ,right : 20,}}
              width={13} height={13} src={AreaHolder}/>
    
              <img className='AreaHolder AreaHolderLB'
              style={{bottom : 20 ,left : 20,}}
              width={13} height={13} src={AreaHolder}/>
    
              <img className='AreaHolder AreaHolderRB'
              style={{bottom : 20 ,right : 20,}}
              width={13} height={13} src={AreaHolder}/>                       
            </div>
    
            </div>
            </div>
          )
        default:
          break;
      }

      

    }

   const dropControl = (e) => {
    console.log('context.drop',context,context?.drop?.data);
    switch (context.drop?.type) {
      case 'addWidget':
        Widget.create(context,context?.drop?.data)
        break;

      case 'addWidget':
        Widget.create(context,context?.drop?.data)
        break;
    
      default:
        const droppedFiles = Array.from(e.dataTransfer.files);
        fileUploadAndCreate(droppedFiles)
        break;
    }

  }


  const fileUploadAndCreate = async (files) =>{

        if (files) {
          const file = files[0];
          // 파일 타입 검사
          if(file?.type){
            const Type = Utility.fileMIME(file.type)
            if (Type == null) {
                alert('업로드가 불가능한 파일입니다.');
                return;
            }
    
            try {
                const res = await fileAPI.upload(context.spaceId, file);
                switch (Type) {
                  case 'image':
                    Widget.create(context,
                      {
                        "widget": "Image",
                        "value": {
                            "mediaSource": res.uuid,
                            "mediaType": "library"
                        }
                    })
                    break;
                
                  default:
                    break;
                }
              
            } catch (error) {
                console.error('파일 업로드 실패:', error);
            }
          }
      }
        console.log('context.drop null',files);
  }


    return (
        <Fragment>
          <AssistProvider>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "EditContainer" ref={ref}>
                    <div id = "EditArea" 
                    onDragEnter={(e)=>{setWidgetDrop(true)}}
                    onDrop={(e)=>{dropControl(e)}}
                    style={{top:'0px',left:EditAreaLeft,width:EditAreaWidth+40,height:EditAreaHeight+40}}>
                    {dropWidgetGuide(context.drop?.type)}
                    {EditScreen()}

                    </div>
                </div>
          </AssistProvider>
        </Fragment>
    );
// <PageDisplay page={[page]} previewWidth={width} previewHeight={height} referenceWidth={referenceWidth} referenceHeight={referenceHeight} option={{align:'center'}} />
}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(EditContainer);
  


  const EditPageStyle = `

  #EditContainer {
    width : 100%;
    height: 100%;
    display: flex;
    position: relative;
    animation: EditContainerStart ease 1s;

  }
  #EditArea{
    position: relative;
    box-sizing: border-box;
    transition : left 1s ease-in-out;
  }
  #EditNoti{
    width : 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  #EditScreenMessage{
    color : #adadad;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #EditScreenMessage .messageText{
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .newWindow{
    display: flex;
    justify-content: center;
    align-items: center;
    width : 100px;
    height: 100px;

    font-size: 50px;
    border-radius: 15px;
    color : rgb(173, 173, 173);
  }

  @keyframes EditContainerStart {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  #DropWidgetGuideContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index : 3;

  }

  #DropWidgetGuideContainer #DropWidgetGuideBOX{
    display: flex;
    justify-content: center;
    align-items: center;
    background : #00000099;
    backdrop-filter: blur(10px);
    animation: DropWidgetGuideContainer_Animation 0.2s ease-in;
  }


    #DropWidgetGuideContainer #DropWidgetGuideBOX .messageText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  #DropWidgetGuideContainer #DropWidgetGuideBOX .AreaHolder{
    position: absolute;
    animation: DropWidgetAreaHolder_Animation 1s ease-in-out infinite;

  }

  #DropWidgetGuideContainer #DropWidgetGuideBOX .AreaHolderLT{
    animation: DropWidgetAreaHolder_Animation_LT 1s ease-in-out infinite;
  }

  #DropWidgetGuideContainer #DropWidgetGuideBOX .AreaHolderRT{
    animation: DropWidgetAreaHolder_Animation_RT 1s ease-in-out infinite;
  }

  #DropWidgetGuideContainer #DropWidgetGuideBOX .AreaHolderLB{
    animation: DropWidgetAreaHolder_Animation_LB 1s ease-in-out infinite;
  }

  #DropWidgetGuideContainer #DropWidgetGuideBOX .AreaHolderRB{
    animation: DropWidgetAreaHolder_Animation_RB 1s ease-in-out infinite;
  }

  @keyframes DropWidgetGuideContainer_Animation {
    0% {
      background : #00000000;
      backdrop-filter: blur(0px);
    }
    100% {
      background : #00000099;
      backdrop-filter: blur(10px);
    }
  }


  @keyframes DropWidgetAreaHolder_Animation_LT {
    0% {
        transform: translate(0, 0) rotate(90deg);
    }
    50% {
      transform: translate(-10px, -10px) rotate(90deg);
    }
    100% {
        transform: translate(0, 0) rotate(90deg);
    }
  }


  @keyframes DropWidgetAreaHolder_Animation_RT {
    0% {
        transform: translate(0, 0) rotate(180deg);
    }
    50% {
      transform: translate(10px, -10px) rotate(180deg);
    }
    100% {
        transform: translate(0, 0) rotate(180deg);
    }
  }

  @keyframes DropWidgetAreaHolder_Animation_LB {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    50% {
      transform: translate(-10px, 10px) rotate(0deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
  }

  @keyframes DropWidgetAreaHolder_Animation_RB {
    0% {
        transform: translate(0, 0) rotate(270deg);
    }
    50% {
      transform: translate(10px, 10px) rotate(270deg);
    }
    100% {
        transform: translate(0, 0) rotate(270deg);
    }
  }


  `;

  