////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/page_account.scss"

// 이미지 연결

import logo from 'assets/logo.svg';
import editSetting from 'assets/edit/EditScreenSetting.png';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
import Tutorial from "../Tutorial"
import MiniView from "components/viewer/MiniView"


// 서비스 연결
import * as apiAccount from 'service/api/apiAccount';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class ProfileInit extends Component {
    
    state = {
        pageLoding :false,

        userId : '',
        nickName : '',
        userName : '',
        profileImage : '',

    }

    async componentDidMount (){
        const accountData = await apiAccount.getGoogleUserInfo();
        console.log('accountData', accountData);     
        this.setState({
            userName : accountData['name'],
            profileImage : accountData['picture'],
            pageLoding :true,
        })   
    }    


    handleInputChange = (event) => {
        this.setState({
            nickName: event.target.value
        });
      }

    summit = async () => {
        if(this.state.profileImage == '' || this.state.nickName==''){
        }else{
            const accountData = await apiAccount.setProfile({name:this.state.userName, image:this.state.profileImage, nick:this.state.nickName});
            this.props.history.push('/dashboard');
        }
    }

    render() { 
        return (
            <div id = "ProfileInitPage" >
              
            <section className='InfoSide'> 
            <p>
                {/* 오버랩에 <br/> 오신것을 <br/> 환영합니다 */}
            </p>
                    
            </section>
            <section className='SettingSide'>
                <div className='FieldMargin'>
                    
                </div>
            <div className='SetFieldList'>
                <div className='SetField'> 
                    <h3 className='fieldTitle'>
                    프로필 이미지
                    </h3>
                    <img className={'profileImage ' +  (this.state.pageLoding?'lodingTrue':'lodingFalse')} src={this.state.profileImage?this.state.profileImage:logo}/>
                
                </div>
                <div className='SetField'> 
                    <h3 className='fieldTitle'>
                    닉네임
                    </h3>
                    <input  
                        className='name'
                        maxLength={10}
                        value={this.state.nickName}
                        onChange ={(e)=>this.handleInputChange(e)}
                        placeholder='닉네임을 입력해주세요'
                    />
                </div>
            </div>
            <div className='buttonList'>

                <div className='summit' onClick={(e) => this.summit()}>
                    오버랩 가입 하기
                    {/* <span class="material-symbols-outlined iconfont"> arrow_right_alt </span> */}
                </div>
            </div>
            </section>
                

            </div>
        );
    }
}

////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    screenData : state.screenData,
    storeScreenNo : state.screenNo,
})

function mapDispatchToProps(dispatch){
    return {
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ProfileInit);
  
