////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import TemplateStoreContainer from "components/store/template/TemplateStoreContainer"
import OverlapCreateByTemplate from "components/lobby/space/module/OverlapCreateByTemplate"
import StoreUpload from "components/store/StoreUpload"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import LG_pre1 from 'assets/guide/LG_pre1.png';
import LG_pre2 from 'assets/guide/LG_pre2.png';
import LG_pre3 from 'assets/guide/LG_pre3.png';
import LG_pre4 from 'assets/guide/LG_pre4.png';
import LG_pre5 from 'assets/guide/LG_pre5.png';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Guide from 'service/other/Guide'; 
import * as overlap from 'service/api/overlap';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function GuideModulePage({lobbyRoutes,subAddress}) {
      ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // set
    const history = useHistory();
    // state
    const [viewSize, setViewSize] = useState('min');
    const [Select, setSelect] = useState(null);
    // ref
    // const { width, height, ref } = useResizeDetector();
    // const displayRef = useRef(null);
    


  const goBack = () => {
    history.goBack(); // Navigate to the previous page
  };
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    return (
        <div className='GuideModulePage' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <nav id='GuideModulePageNav'>
        <div className='categoryList'>
            <button className='category' onClick={()=>goBack()}>
                {Editor.icon({icon:'arrow_back',size:20,lineHeight:30})}
                &nbsp;
                돌아가기
            </button>

            {viewSize == 'full'?
            <button className='category' onClick={()=>setViewSize('min')}>
                {Editor.icon({icon:'zoom_out',size:20,lineHeight:30})}
                &nbsp;
                작게보기
            </button>
            :
            <button className='category' onClick={()=>setViewSize('full')}>
                {Editor.icon({icon:'zoom_in',size:20,lineHeight:30})}
                &nbsp;
                크게보기
            </button>
            
            }
            <button className='category' onClick={()=>lobbyRoutes({target:'Guide',mode:'replace'})}>
                {Editor.icon({icon:'apps',size:20,lineHeight:30})}
                &nbsp;
                가이드 목록
            </button>
            </div>
        </nav>
        <div id={'GuidePageContent'} className={"guidePage"+viewSize}>
        <div style={{marginTop:80}}/>
        {Guide.get(subAddress)}
        </div>
      
    </div>
    );
  }

  export default GuideModulePage;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    .GuideModulePage {
        background-color: #3F3F3F;
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        flex-direction: column;
        align-items: center;       
    }

    #GuideModulePageNav{
        background-color: #3F3F3F;
        top : 0;
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;

        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
   
    #GuideModulePageNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
    }
    #GuideModulePageNav .categoryList{
        display: flex;
    }
    #GuidePageContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow:auto;
    }
    .guidePagemin{
        width: 100%;  
    }
    .guidePagemin #GuideBook{
        max-width: 900px; 
        margin: 0 auto; 

    }
    
`;