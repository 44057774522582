import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"; 
// import Contorl from "./control/Control";
import App from "../App";
// import TestLogin from "pages/login/OldLogin";
import Login from "pages/login/Login";
// import OAuth from "pages/login/OAuthLogin";
// import OAuthT from "pages/login/OAuthTest";
import Edit from "pages/editer/EditPage";
import Edit2 from "pages/editer/EditPageV2";
import UploadPage from "pages/editer/UploadPage";
import Show from "./viewer/Show";
import ViewPage from "pages/view/ViewPage";
import Main from "pages/Main";
import LandingPage from "pages/LandingPage";
import LobbyPage from "pages/lobby/LobbyPage";
import ProfileInitPage from "pages/account/ProfileInit";


import Temp from "pages/temp";
import FrontPage from "pages/front/FrontPage";
import FrontGuide from "pages/front/FrontGuide";
import Widget from "pages/front/FrontWidget";
import Template from "pages/front/FrontTemplate";
import Tutorial from "pages/Tutorial"
import EditGuideVeiw from "pages/EditGuideVeiw"
import TEST from "pages/other/test"



// NewAcoountSetting
import NewAcoountSetting from "../pages/account/NewAcoountSetting"


// 임시연결
// import SingUp from "pages/login/SingUp";
// import TestSingUp from "pages/login/TestSingUp";


export default class Routes extends React.Component {

  state ={
    login: true,
  }

  render() {
    // console.log('라우터 조정됨',sessionStorage.getItem("JWT"))
    return (
    <Router >
      {sessionStorage.getItem("JWT")
        ?
        <Switch>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/test" component={TEST} />
        <Route exact path="/NewAcoountSetting" component={NewAcoountSetting} />

        <Route exact path="/initprofile" component={ProfileInitPage} />
        <Route exact path="/show" component={Show} />
        <Route path="/view/:uuid" component={ViewPage} />
        <Route path="/show/:userid/:screen" component={Show} />
        <Route path="/show/:userid" component={Show} />
        <Route exact path="/edit" component={Edit2} />
        <Route exact path="/upload" component={UploadPage} />
        <Route exact path="/oldedit" component={Edit} />
        <Route exact path="/main" component={LobbyPage} />

        <Route exact path="/widget" component={Widget} />
        <Route exact path="/template" component={Template} />
        <Route exact path="/tutorial" component={Tutorial} />
        <Route exact path="/temp" component={LandingPage}  />
        <Route exact path="/edittutorial" component={EditGuideVeiw} />

        {/* 로비페이지 */}
        <Route exact path="/guide" component={FrontGuide} />
        <Route exact path="/lobby" component={LobbyPage}/>
        {/* 
        <Route exact path="/lobby/store" component={LobbyPage} />
        <Route exact path="/lobby/project" component={LobbyPage}  />
        <Route exact path="/lobby/store" component={LobbyPage} /> */}

      </Switch>
        :
        <Switch>
        <Route exact path="/" component={FrontPage} />
        <Route exact path="/guide" component={FrontGuide} />
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/edit" component={Edit2} />
        <Route exact path="/oldedit" component={Edit} /> */}
        <Route exact path="/test" component={TEST} />
        {/* <Route exact path="/login2" component={TestLogin} /> */}
        {/* <Route exact path="/OAuth" component={OAuth} /> */}
        {/* <Route exact path="/OAuthT" component={OAuthT} /> */}
        {/* <Route exact path="/singup" component={TestSingUp} /> */}
        {/* <Route exact path="/singup2" component={SingUp} /> */}
        <Route exact path="/show" component={Show} />
        <Route exact path="/widget" component={Widget} />
        <Route exact path="/template" component={Template} />
        <Route path="/show/:userid/:screen" component={Show} />
        <Route path="/show/:userid" component={Show} />
        <Route path="/show/:userid" component={Show} />
        <Route path="/view/:uuid" component={ViewPage} />
        <Route exact path="/temp" component={LandingPage} />
        <Route path='*' component={FrontPage} />
        
      </Switch>
      }
    </Router>
  );
  }
}
const mapStateToProps = (state) => ({
  exemple : state.exemple,
})