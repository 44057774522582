////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/lobby/space/module/OverlapInfo"
import GuideBalloon from "components/common/form/GuideBalloon"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 컴포넌트 연결
import TemplateObject from 'components/store/template/TemplateObject';


// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as templateAPI from 'service/api/template' ; 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/**
 * 템플릿 리스트가 표시되는 컴포넌트 입니다.
 * @param {function} clickUpload 업로드페이지로 이동하는 함수
 * @param {function} clickTemplate 상세페이지로 이동하는 함수
 * @param {string} parent 해당 컴포넌트의 부모 컴포넌트 (lobby,editor)
 * @returns 
 */
function TemplateStoreList({clickUpload,clickTemplate,parent,backEditor}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [TemplateList, setTemplateList] = useState(null);
    // rel 

    useEffect(() => {
        getTemplate()
    }, []);



    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const getTemplate =  async () => {
        const Response = await templateAPI.getList();
        setTemplateList(Response) 
    }

    const templateComponent = (list) => {

        if(list == null ){
            return (
                <div className='none'>
                {Editor.icon({icon:'shop',size:200,lineHeight:200,color:'#999'})}
                <p>불러오는중.</p>
                </div>)
            }

            console.log('list',list);

        if(list.length == 0 ){
            return (
                <div className='none'>
                {Editor.icon({icon:'shop',size:200,lineHeight:200,color:'#999'})}
                <p>콘텐츠가 없습니다.</p>
                </div>
                )
            }

            const template = list.map((item) => (
                <TemplateObject page={item} clickTemplate={clickTemplate}/>
            ));



            return (
            
                <div className='TemplateBody'>
                    <div>
                        <h2> 최신 템플릿</h2>
                    </div>
                    <div  className='TemplateList'>
                    {template}
                    </div>
 
                </div>
                )

 
    }


    return (
        <div id='TemplateStoreList' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <nav  id='TemplateStoreListNav' >
            <div className='categoryList'>
                    <button className='category'>
                        {Editor.icon({icon:'apps',size:20,lineHeight:30})}
                        &nbsp;
                        전부
                    </button>
                    <button className='category'>
                        {Editor.icon({icon:'weight',size:20,lineHeight:30})}
                        &nbsp;
                        커머스
                    </button>

                    <button className='category'>
                        {Editor.icon({icon:'stadia_controller',size:20,lineHeight:30})}
                        &nbsp;
                        게임
                    </button>

                    <button className='category'>
                        {Editor.icon({icon:'pie_chart',size:20,lineHeight:30})}
                        &nbsp;
                        발표
                    </button>

                    <button className='category'>
                        {Editor.icon({icon:'mic',size:20,lineHeight:30})}
                        &nbsp;
                        스트리밍
                    </button>
            </div>
            {parent=='lobby'&&window.location.hostname === 'localhost'?
            <button className='upload' onClick={(()=>{clickUpload()})}>
            {Editor.icon({icon:'upload',size:20,lineHeight:30})}
            <p> 업로드 </p>
            </button>:null}
            {parent=='editor'?
            <button className='backButton' onClick={(()=>{backEditor()})}>
            <p> 돌아가기 </p>
            {Editor.icon({icon:'arrow_forward',size:20,lineHeight:30})}
            </button>:null}
        </nav>
        <div id='OverlapMain' >
            {templateComponent(TemplateList)}
        </div>

    </div>
    );
  }

  export default TemplateStoreList;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    #TemplateStoreList {
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        flex-direction: column;
    }
    #TemplateStoreListNav{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
    #OverlapMain{
        display: flex;
        width: 100%; 
        height: 100%;
    }
    #TemplateStoreListNav .upload {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
    }
    #TemplateStoreListNav .backButton {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        border-radius: 50px;
    }
    #TemplateStoreListNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
    }
    #TemplateStoreListNav .categoryList{
        display: flex;
    }
    #OverlapMain .none{
        display: flex;
        flex-direction: column;
        width: 100%; 
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    #OverlapMain .TemplateBody{
        margin : 80px 20px 0px 20px;
        width: 100%; 
        height: 100%;

    }
    #OverlapMain .TemplateList{
        width: 100%; 
        height: 200px;
        display : flex;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-auto-rows: 150px;
    }
   
   
`;