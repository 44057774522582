////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Preset from './preset/ShapePreset';
import * as Event from '../../service/event/Actions' ; 
import * as Widget from 'service/edit/WidgetService' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Shape extends Component {
    
    static defaultProps = {
        widgetName: 'Shape',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;
    
    state = {
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }


    widgetTest = (e) => {
        // e.preventDefault();
        // console.log('체크중')
        console.log(e,'체크중')
    }


    render() {

        const Style = this.props.getStyle(this.props.data)
        const Animation = this.props.getAnimation(this.props.animation,this.props.data)
        const MergeStyle = Widget.styleMerge(Style,Animation);

        const Height =this.props.data?.size?.height;
        const Width = this.props.data?.size?.width;
        // 드레그를 통한 위젯 이동
        MergeStyle.layout= { ...MergeStyle.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                MergeStyle.layout.transition = false
                MergeStyle.object.transition = false
                MergeStyle.line.transition = false
                MergeStyle.shapes.transition = false
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
                        {Preset.data(this.props.data,MergeStyle,this)}
                    </div>
                );
            case 'broadcast':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
                    {Preset.data(this.props.data,MergeStyle,this)}
                    </div>
                );
          
            case 'view':
                return (
                    <div className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.activate(this.props.data)} style={MergeStyle.layout} >
                        {Preset.data(this.props.data,MergeStyle,this)}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Shape);
  