////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
// 이미지 연결


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////




function VideoControllerV1({target,modify,cache,modifyCache}) {

/**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set

    // state
    const [Progress, setProgress] = useState(null); 

    const [currentTime, setCurrentTime] = useState(Date.now());
  
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////


 
    useEffect(() => {
      try {
        const intervalId = setInterval(() => {
          setCurrentTime(Date.now());
        }, 1000);
    
        // 컴포넌트 언마운트 시 인터벌 제거
        return () => clearInterval(intervalId);
      } catch (error) {
        console.error("An error occurred in the useEffect hook:", error);
      }
    }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록

    const formatTime = (seconds) => {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
    
      // 시간 부분은 1시간 이상일 때만 포함
      if (hrs > 0) {
        return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      } else {
        return `${mins}:${secs.toString().padStart(2, '0')}`;
      }
    }

    const getProgress = () => {

    let progressTime = 0
    if(cache?.playCache_state == 'play'){
      progressTime = Math.floor(Date.now() / 1000) - cache?.playCache_start + (cache?.playCache_progress ? cache?.playCache_progress : 0)
    }else if (cache?.playCache_state == 'pause') {
      progressTime = (cache?.playCache_progress ? cache?.playCache_progress : 0)
    }else if (cache?.playCache_state == 'stop') {
      progressTime = cache?.playCache_progress??0
    }

     return progressTime;
    }


    const videoStateControl = (state) => {
      const RequestCache ={}

      const now = Math.floor(Date.now() / 1000)

      const Cache = cache
      const videoDuration = cache?.playCache_duration? cache?.playCache_duration : 0;

      switch (state) {
          case 'play':
              RequestCache['playCache_state'] = state
              RequestCache['playCache_start'] = now
              RequestCache['playCache_progress'] =  Cache?.playCache_progress ? Cache?.playCache_progress : 0; 
              break;
          case 'pause':
              if(Cache?.playCache_start){
                  // 일시정지시 지금까지의 진행도를 저장합니다.
                  const Progress = now - Cache?.playCache_start + (Cache?.playCache_progress ? Cache?.playCache_progress : 0 )
                  if(videoDuration >= Progress){
                      RequestCache['playCache_state'] = state
                      RequestCache['playCache_start'] = null
                      RequestCache['playCache_progress'] =  Progress
                  }else{
                      RequestCache['playCache_state'] = 'stop'
                      RequestCache['playCache_start'] = null
                      RequestCache['playCache_progress'] = 0
                  }
              }else{
                  RequestCache['playCache_state'] = 'stop'
                  RequestCache['playCache_start'] = null
                  RequestCache['playCache_progress'] = 0
              }
              break;
          case 'stop':
              RequestCache['playCache_state'] = state
              RequestCache['playCache_start'] = null
              RequestCache['playCache_progress'] = 0
              break;        
          default:
              break;
      }
      modifyCache(RequestCache,target);

    }

    /**
     * 프로그레시브 슬라이더를 통해 영상의 시간을 조절합니다.
     * @param {*} sec 
     */
    const changeProgress = (sec) => {
      const RequestCache ={}

      const now = Math.floor(Date.now() / 1000)
      RequestCache['playCache_start'] = now
      RequestCache['playCache_progress'] = Number(sec)
      modifyCache(RequestCache,target);
    }

    /**
     * 부모로 이벤트 전파를 막습니다
     * @param {evnet} e 이벤트 객체 
     */
    const stopPropagation = (e) =>{
      e.stopPropagation()
    }

    const ProgressTime = getProgress() > cache?.playCache_duration ? cache?.playCache_duration : getProgress();

    return (
    <Fragment>
        <style jsx>{`${ControllerStyle}`}</style>
        <div className='widgetController'>
            <div className='videoController'
                onDragStart={stopPropagation}
                onDragt={stopPropagation}
                onDragEnd={stopPropagation}
      
                onMouseMove={stopPropagation}
                onMouseUp={stopPropagation}
                onMouseDown={stopPropagation}
            >
                <div className='buttonPlace'>
                <button onClick={()=>{changeProgress(ProgressTime - 10 > 0? ProgressTime-10 : 0)}}>
                {Editor.icon({icon:'replay_10',size:15,lineHeight:30})}
                </button>

                {cache?.playCache_state != 'play' ?  
                  <button onClick={()=>{videoStateControl('play')}}>
                  {Editor.icon({icon:'play_arrow',size:15,lineHeight:30})}
                  </button>
                :
                  <button onClick={()=>{videoStateControl('pause')}}>
                  {Editor.icon({icon:'pause',size:15,lineHeight:30})}
                  </button>
                }
                <button onClick={()=>{videoStateControl('stop')}}>
                {Editor.icon({icon:'stop',size:15,lineHeight:30})}
                </button>

                <button onClick={()=>{changeProgress(ProgressTime > cache?.playCache_duration?cache?.playCache_duration : ProgressTime+10)}}>
                {Editor.icon({icon:'forward_10',size:15,lineHeight:30})}
                </button>
                </div>
                <div className='progressivePlace'>
                <div className='progressiveTime'>
     
                {/* {formatTime(Progress)} */}
                {formatTime(ProgressTime??0)}
                </div>
                <input type="range" value={ProgressTime??0} min="0" max={cache?.playCache_duration??0} onChange={(e)=>changeProgress(e.target.value)}/>
                <div className='durationTime'>

                {formatTime(cache?.playCache_duration??0)}
                </div>
                </div>
            </div>
        </div>
    </Fragment>
    );

}

export default VideoControllerV1;



const ControllerStyle = `
.widgetController{
    display : flex;
    width : 100%;
    height : 100%;
    position: relative;
     animation: growAndFadeIn 0.5s forwards; /* 애니메이션 적용 */
}


.videoController{
    max-width : 250px;
    width : 100%;
    height : 60px;
    background-color : #4C4C4CF0;

    position: absolute;
    bottom : 20px;  
    left: 50%;
    transform: translateX(-50%); /* 중앙 정렬 */

    display : flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 60px; /* 둥근 모서리 */
    box-shadow: 
        0px 2px 4px rgba(0, 0, 0, 0.15), 
        0px 6px 8px rgba(0, 0, 0, 0.15), 
        0px 12px 16px rgba(73, 25, 25, 0.1), 
        0px 18px 24px rgba(0, 0, 0, 0.15); 
}
.videoController .buttonPlace{
    display : flex;
}

.videoController .buttonPlace button{
    height : 30px;
    width : 30px;
    margin : 2.5px;
    border-radius: 5px; /* 둥근 모서리 */
    display: flex;
    flex-direction: column;
    background-color: #2C2C2C;
    box-shadow: 0 2px 5px rgba(0,0,0,.15);
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out,  background 0.1s ease-out;
}
      .videoController .buttonPlace button:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border-radius: 10px;
      z-index: -1;
  }


  .videoController .buttonPlace button:active{
    transform: scale(0.80);
    background-color: #1C1C1C;
  }

  .videoController .buttonPlace button:active:before {
       transform: scale(1.25);
  }
  
  .videoController .progressivePlace{
    display: flex;
    justify-content: center;
    align-items: center;
    width : 100%;
  }
  .videoController .progressivePlace  .progressiveTime{
    width : 50px;
    text-align: right;
    padding-right : 5px;
    font-size : 15px;
  }
  .videoController .progressivePlace  .durationTime{
    width : 50px;
    text-align: left;
    padding-left : 5px;
    font-size : 15px;
  }

 .videoController .progressivePlace input[type="range"] {
  -webkit-appearance: none; /* 크롬, 사파리, 오페라 */
  -moz-appearance: none; /* 파이어폭스 */
  appearance: none; /* 기본 브라우저 스타일 제거 */
  
  width: 130px; /* 너비 설정 */
  height: 6px; /* 높이 설정 */
  margin 2px 0px;
  background: #bbb; /* 기본 배경색 */
  outline: none; /* 외곽선 제거 */
  opacity: 0.9; /* 불투명도 */
  transition: opacity .15s ease-in-out; /* 전환 효과 */
  border-radius: 10px; /* 둥근 모서리 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* 트랙 그림자 */
}

.videoController .progressivePlace input[type="range"]:hover {
  opacity: 1; /* 호버 시 불투명도 변경 */
}

.videoController .progressivePlace input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* 크롬, 사파리, 오페라 */
  appearance: none; /* 기본 브라우저 스타일 제거 */
  width: 10px; /* 너비 설정 */
  height: 10px; /* 높이 설정 */
  background: #e0e0e0; /* 슬라이더 색상 */
  cursor: pointer; /* 커서 모양 */
  border-radius: 50%; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* 슬라이더 그림자 */
  transition: background 0.3s ease, transform 0.3s ease; /* 전환 효과 */
}

.videoController .progressivePlace input[type="range"]::-moz-range-thumb {
  width: 10px; /* 너비 설정 */
  height: 10px; /* 높이 설정 */
  background: #e0e0e0; /* 슬라이더 색상 */
  cursor: pointer; /* 커서 모양 */
  border-radius: 50%; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* 슬라이더 그림자 */
  transition: background 0.3s ease, transform 0.3s ease; /* 전환 효과 */
}

.videoController .progressivePlace input[type="range"]::-ms-thumb {
  width: 10px; /* 너비 설정 */
  height: 10px; /* 높이 설정 */
  background: #e0e0e0; /* 슬라이더 색상 */
  cursor: pointer; /* 커서 모양 */
  border-radius: 50%; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* 슬라이더 그림자 */
  transition: background 0.3s ease, transform 0.3s ease; /* 전환 효과 */
}

.videoController .progressivePlace input[type="range"]::-webkit-slider-thumb:hover {
  background: #999; /* 호버 시 색상 변경 */
  transform: scale(1.5); /* 호버 시 확대 */
}

.videoController .progressivePlace input[type="range"]::-moz-range-thumb:hover {
  background: #999; /* 호버 시 색상 변경 */
  transform: scale(1.5); /* 호버 시 확대 */
}

.videoController .progressivePlace input[type="range"]::-ms-thumb:hover {
  background: #999; /* 호버 시 색상 변경 */
  transform: scale(1.5); /* 호버 시 확대 */
}

 @keyframes growAndFadeIn {
      from {
        opacity: 0;
        transform: scale(0.5);

      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

`;