////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// [프로바이더]
import EditContext from 'service/context/EditContext'; 

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import WidgetDisplay from 'components/display/WidgetDisplay' // 위젯 전용 디스플레이
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as DeCoder from 'service/engine/DeCoder'; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function PresetEditForm({target,modify,keyName,title,option,func}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const context = useContext(EditContext);
    const uuid = target.uuid

    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {positionX,positionY} = keyName ;

    const PresetList = DeCoder.getPreset({target});
    const TargetPreset = target.data.preset


  

    const NowPreset = PresetList.filter((preset) => preset.name == TargetPreset);


    console.log('PresetEditForm',NowPreset)
    
    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='PresetEditForm'>
            {Editor.editorDetail('모양','interests',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>

                <article className="EditPart" >

                <div className='presetBox' >
                    <div className='presetDisplay'>
                    <WidgetDisplay widgetObj={target}  global={context?.overlap?.global} previewWidth={220} previewHeight={150}/>  
                    </div>
                    <div className='presetText'>
                    <div className='TitleField'>
                        <h3 className='presetTitle'>
                        {NowPreset?.[0]?.nameKo??'알수없는 프리셋'}
                        </h3>
                        <p className='presetInfo'>
                        {NowPreset?.[0]?.info}
                        </p>
                    </div>
                    <p className='presetSummary'>
                    {NowPreset?.[0]?.summary}
                    </p>
                    </div>
                </div>

                    <div className='changeField'>
                    <button className='changeButton' onClick={()=>func.moveTab('preset')}>
                        {Editor.icon({icon:'autorenew',size:25,lineHeight:30})}
                        <p>
                        변경하기
                        </p>
                        
                    </button>
                    </div>

                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default PresetEditForm;

const FormStyle = `
.PresetEditForm{

}
.PresetEditForm .changeField{
    width: 100%;
    display :flex;
    justify-content : center;
}

.PresetEditForm .changeField .changeButton{
    width: 100%;
    margin : 10px 0px ;
    
    display :flex;
    justify-content : center;
    align-items : center;
    border-radius : 5px;
    background: #262626 ;
}


.PresetEditForm .presetBox{
    width: 100%;
    height : 250px;
    background-color:rgb(63, 63, 63);
    border-radius : 10px;
    display:flex;
    flex-direction: column;
    background-color: #333;
    margin : 10px 0px;
}

.PresetEditForm .presetDisplay{
    width: calc(100% - 40px);
    margin : 20px 20px 10px 20px;
    height : 150px;
    background-color: #fff;
    border-radius : 10px ;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
}

.PresetEditForm  .presetText{
    width: calc(100% - 40px);
    height : 50px;
    border-radius : 10px;
    margin : 0px 20px;
}

.PresetEditForm  .presetText .TitleField{
    display:flex;
    width: 100%;
    justify-content: space-between;
}

    
.PresetEditForm .presetText .presetTitle{
    height : 30px;
    display:flex;
    width: calc(100% - 20px);
    justify-content: start;
    align-items: center;
}

.PresetEditForm  .presetText .presetInfo{
    height : 30px;
    display:flex;
    justify-content: end;
    align-items: center;
}

.PresetEditForm  .presetText .presetInfo p{
    display:flex;
    font-size : 15px;
    line-height : 20px;
}

.PresetEditForm .presetText .presetSummary{
    height : 30px;
    display:flex;
    width: 100%;
    justify-content: start;
    align-items: center;
}

`;