////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/lobby/space/module/OverlapInfo"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as overlap from 'service/api/overlap';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleOverlapList(props) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [overlapList, setList] = useState(null);
    const [Loading, setLoading] = useState(false);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        getOverlapList() 
        return () => {
        };
      },[]);

      useEffect(() => {

        setTimeout(function() {
            getOverlapList()
          }, 1000);
        return () => {
        };
      },[props.mode,props.delete]);


      
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const handleChildClick = (uuid,id) => {
        console.log("Child clicked with data:", uuid,id);
      };


    const getOverlapList = async () => {
        let overLapComponent = [];
        let overLapList = [];
        overLapList = await overlap.list(props.spaceId);
        overLapComponent = overLapList
        .filter(overlap => overlap.uuid !== props.delete) 
        .map((overlap, index) => (
            <OverlapInfo 
                key={overlap.uuid} 
                setSelect={props.setSelect} 
                index={index} 
                data={overlap} 
                spaceId={props.spaceId}
            />
        ));
        setList(overLapComponent);
        setLoading(true);
    }
    const overlapContent = () =>{

        if(Loading == false){
            return(
                <div className='overlapLoading'>
                
                </div>
            )
        }
        if(Loading == true && overlapList?.length == 0){
            return(
                <div className='undefinedOverlap'>
                    <p>스페이스에 오버랩 프로젝트가 없습니다.</p>
                    <p>새 프로젝트 민들기 버튼을 눌러서 새로운 프로젝트를 생성해주세요</p>
                </div>
            )
        }
        if(Loading == true && overlapList?.length > 0){
            return(
                <div className='overlapList'>
                {overlapList}
                </div>
            )
        }

    }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='SpaceModuleContainer OverlapListContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='spaceField'>
            <div className='navField'>
            <nav className='spaceNav'>
     
                <span className='NavTitle'>
                   오버랩 프로젝트
                </span>
                <div className='bnt_List'>
                <button className='createOverlap' onClick={()=>props.setMode('appendType')}>
                    <img className='icon'  src={logoW}/>
                    <p> 새 프로젝트 민들기 </p>
                </button>
                </div>
            </nav>
            </div>
            <div className='overlapListWrap'>
                {overlapContent()}
                {/* <div className='testov'></div> */}
            </div>
        </div>
    </div>
    );
  }

  export default SpaceModuleOverlapList;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .OverlapListContainer {

    }
    .testov{
        width: 100%;
        height: 1000px;
    }

`;

const ContainerStyle = `
    .OverlapListContainer {
        z-index :2;
        flex-grow: 1;
        background-color: lightblue;
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        
    }
    .OverlapListContainer .spaceField{
        display: flex;
        width: 100%; 
        height: 100%;
        flex-direction: column;
        position: absolute; /* 부모에 대해 절대적인 위치 지정 */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #2D2D2D; 

    }
    .OverlapListContainer .overlapListWrap:hover {
        padding-right: 0px; /* 스크롤바의 너비 */

      }
    
      /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
      .OverlapListContainer .overlapListWrap::-webkit-scrollbar {
          width: 8px; /* 스크롤바의 너비 */
      }
    
    .OverlapListContainer .overlapListWrap::-webkit-scrollbar-thumb {
        background-color: #000000aa; /* 스크롤바의 색상 */
        border-radius: 4px; /* 스크롤바의 모서리 */
    }
    
    .OverlapListContainer .overlapListWrap::-webkit-scrollbar-track {
        background: transparent; /* 스크롤 트랙의 색상 */
        width: 8px; /* 스크롤바의 너비 */
    }
    .OverlapListContainer .overlapListWrap{
        display: flex;
        flex : 1;
        overflow-y: scroll; /* 마우스 오버 시 스크롤바를 표시합니다. */
        padding-top: 70px;
    }
    .OverlapListContainer .navField{
        width: calc(100% - 10px);
        height: 70px;
        background: #2D2D2DFF; /* fallback for old browsers */
        background: -webkit-linear-gradient(to top, #2D2D2D00, #2D2D2DFF, #2D2D2DFF); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to top, #2D2D2D00, #2D2D2DFF, #2D2D2DFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        position: absolute;
        z-index:1;
    }
    .OverlapListContainer .spaceNav{

        height: 50px;
        width: calc(100% - 10px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex ;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin : 10px 0px 10px 10px;
        border-radius: 50px;
    }
    .OverlapListContainer .NavTitle{
        margin: 0px 10px ;
        font-size: 20px;
        line-height: 50px;
    }
    .OverlapListContainer .bnt_List{
    
    }
    .OverlapListContainer .createOverlap{
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px ;
        background: #222;
        font-size: 15px;
        height: 30px;    
        padding: 0px 10px;
        margin: 10px;            
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
    }
    .OverlapListContainer .createOverlap .icon{
        height: 20px;
        width: 20px;
        font-size: 20px;
        object-fit: scale-down;
        margin: 5px;
    }

    .OverlapListContainer .overlapList{
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-auto-rows: 150px;
    }
    .OverlapListContainer .overlapLoading{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
    }
    .OverlapListContainer .undefinedOverlap{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
    }
`;