////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PagePreview from 'components/edit/editPanel/preferences/editPreferences/PagePreview';
import PageNav from 'components/edit/editPanel/preferences/editPreferences/PageNav';



// 서비스 연결
import * as PageService from 'service/edit/PageService' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Action from 'service/edit/ActionService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function PreviewList({PeferencesType,setPeferenceType,context}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    // state
    const [ContextReady, setContextReady] = useState(false);

    // rel 
    const { width, height, ref } = useResizeDetector(
        
      );
   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    
      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        if(context!=null){
            setContextReady(true);
        }
      }, [context]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    

    const contextHide = (e) => {
      context.hideContext();
      e.stopPropagation();
    }

    const contextShow = (e,uuid) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      console.log('테스트',uuid,'page',mouseX,mouseY)
      context.showContext(uuid,'page',mouseX,mouseY);
      e.stopPropagation();
      e.preventDefault();
    }

    const pageRename = (name,pageUUid) =>{
      context.modifyPage({'page_title':name},'modifyTitle',{pageUUid})
    }
    const selectPage = (pageUUid)=>{
      context.selectPage(pageUUid);
    } 

    /**
     * 
     * @param {*} pageUUid 
     */
    const setBroadcast = (pageUUid) => {
      Action.broadcast(context,pageUUid,0);
    }


    /**
     * 페이지를 복사한후에 해당 페이지 아래에 생성합니다.
     * @param {*} pageUUid 
     */
    const pageDuplicate = (pageUUid) => {
      PageService.duplicate(context,pageUUid);
    }

    /**
     * 오버랩의 페이지 순서를 변경합니다.
     * @param {*} orderList ReactSortable 에서 반환받은 오버랩 페이지의 리스트
     * @returns 
     */
    const setPageOrder = (orderList) => {
    
      if(ContextReady){

      }     
      // 순서 목록이 없으면 반환합니다. 
      if(orderList==undefined){return}

      const ModifyList = [];
      const PrevOrder = context.overlap?.content?.order;

      // ReactSortable 에서  받은
      orderList.forEach((order)=>{
        if (order?.uuid){
          ModifyList.push(order.uuid);
        }
      });

      // 오버랩 파일의 순서와 비교해서 변화가 있는지 확인합니다.
      const isEqual = ModifyList.length === PrevOrder.length && ModifyList.every((value, index) => value == PrevOrder[index]);
      // 수정이 된 경우에 한해서 오버랩 파일의 순서 목록을 수정합니다.
      if(!isEqual){
        context.modifyOverlap({'content':{'order':ModifyList}});
      }
    }

    // const selectPage = () =>{
      
    //   context.modifySelectWidget(uuid);
    // }

    const selectPageUUID = context?.selectPageUUID;
    const PageWidth = context?.overlap?.meta?.resolution?.width??0;
    const Channel = context?.view??null;
    const PageHeight = context?.overlap?.meta?.resolution?.height??0;
    const broadcast = context?.view?.view_pointer.map(item => item.uuid)??[];
    // 페이지 정보 와 순서를 가저옵니다.
    const OverlapPages = context.getPageList();
    const PageList = OverlapPages.page;
    const OrderList = OverlapPages.order.map((uuid)=>{return ({'uuid' : uuid})});
    // 페이지 컴포넌트를 만듭니다.

    // const PageElements = PageList.map((page,index)=><div> {page?.pageRename} </div>);
    

    const PageElements = PageList.map((page,index)=><PagePreview key={page?.uuid} uuid={page?.uuid} index={index+1} select={page?.uuid==selectPageUUID} broadcast={broadcast.includes(page?.uuid)} duplicate={pageDuplicate} page={page} width={width/2} height={100} referenceWidth={PageWidth} referenceHeight={PageHeight} pageRename={pageRename} selectPage={selectPage} contextShow={contextShow} setBroadcast={setBroadcast}  panelControl={context.panelControl} channel={Channel}/>);

    const ModifyList = [];

    // 다음 목록이 바뀌는 이유는 ? 

    // ReactSortable 에서  받은
    OrderList.forEach((order)=>{
      if (order?.uuid){
        ModifyList.push(order.uuid);
      }
    });

    return (
      <Fragment>
      <style jsx>{`${EditPageStyle}`}</style>
      <div id="PagePreviewList" ref={ref}>   
      <PageNav context={context}/>
      <ReactSortable
        className="PreviewSortable"
        list={OrderList}
        animation = { 200 }
        // handle = '.handle' 
        delayOnTouchStart = { true } 
        setList={(modifyOrder) => 
          setPageOrder(modifyOrder)
      }
        >
        {PageElements}
      </ReactSortable>
      </div>
      {/* <PreviewDisplay pageList={context?.overlap?.content?.page} referenceWidth={1920??0} referenceHeight={1080??0}/> */}
      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(PreviewList);
  


  const EditPageStyle = `

  #PagePreviewList {
    width: 100%;
    height: 100%;
  }

  .selectPage .PagePreviewContainer{
    background: rgba(255,153,0,.3);
    background: linear-gradient(to right, rgba(255,153,0,.0), rgba(255,153,0,.4), rgba(255,153,0,.5), rgba(255,153,0,.4), rgba(255,153,0,.0));
  }
  .PagePreviewArea{
    display:flex;
    justify-content: center;
  }

  .PagePreviewHeader{

    display:flex;
    height: 30px;
    margin-left : 70px;
    margin-top :10px;
    display:flex;
    justify-content: center;
  }

  .broadcastPage .PagePreviewIndex{
    background-color: red;
  }

  .PagePreviewIndex{
    height: 25px;
    min-width: 25px;
    background-color: #2c2c2c;
    text-align: center;
    margin-right : 5px;
    border-radius : 5px;
    display :flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .PagePreviewTitle{
    height: 25px;
    width: 200px;
    display :flex;
    justify-content: start;
    padding-left : 5px;
    align-items: center;
    // background-color: #2c2c2c;
    border-radius : 5px;
    
  }
  .PagePreviewTitle input {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-family: '프리텐다드SemiBold';
    border: none;
    outline: none;
    background: none;
    -webkit-appearance: none; /* Safari와 Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* 모든 브라우저 */
  }

  .PagePreviewTitle input::placeholder {
    color: #aaaaaa;
  }
  .PagePreviewArea .PagePreviewOption{
    width: 30px;

  }
  .PagePreviewContainer {
    flex : 1;
    display:flex;
    position:relative;
    
  }
  .PagePreviewContainer .PagePreviewHover{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index : 1 ;
    display: flex;
    justify-content: center;
  }
  .PagePreviewContainer .PagePreviewDisplay{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index : 0 ;
    display:flex;
    justify-content: center;
    pointer-events: none;
  }
  .PagePreviewContainer .PagePreviewObject{
    position: relative;

  }


  .PagePreviewContainer:hover .PagePreviewState{
    opacity : 1;
  }


  .PagePreviewContainer .PagePreviewState{
    width: 30px;
    margin : 0px 5px;
    display:flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    opacity : 0;
    transition: opacity 0.5s;
  }



  .PagePreviewContainer:hover .PagePreviewOptions{
    opacity : 1;
  }

  .PagePreviewContainer .PagePreviewOptions{

    width: 30px;
    margin : 0px 5px;

    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    opacity : 0;
    transition: opacity 0.5s;
  }


  .PagePreviewArea .PageButton{
    width: 25px;
    height: 25px;
    margin : 0px 10px 0px 10px;
    background : #363636;
    border-radius : 5px;
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.2), /* 상대적으로 가까운 그림자 */
        0 1px 3px rgba(0, 0, 0, 0.1); /* 멀리 떨어진 더 퍼진 그림자 */
    transition: transform 0.3s ease, box-shadow 0.3s ease;    
  }

  .PagePreviewArea .PageButton.disable{
    background-color: #555555;
    color: #A0A0A0;
  }
  .PagePreviewArea .PageButton.channelDisable{
    background-color: #666;
  }

  .PagePreviewArea .PageButton.able{
    background : #363636;
  }

  .PagePreviewArea .PageButton.able:hover {
    transform: translateY(-2px); /* 버튼을 위로 약간 올림 */
    background : #2C2C2C;
    /* 호버시 그림자 변경 */
    box-shadow: 
        0 6px 8px rgba(0, 0, 0, 0.25),
        0 2px 4px rgba(0, 0, 0, 0.15);
}


  `;