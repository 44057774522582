////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
import WidgetController from "components/edit/editScreen/controlObject/WidgetController"
import WidgetPlaceholder from "components/edit/editScreen/controlObject/WidgetPlaceholder"

// [프로바이더] 연결
import AssistContext from 'service/context/AssistContext';
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Model from 'service/value/Model' ; 
import * as Utility from 'service/other/Utility'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [디스플레이] 
 * 프리뷰용도로 제작된 디스플레이 입니다.
 * @param {*} page 오버랩의 페이지 객체 (1개)
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * @param {object<number>} reference 오버랩 프로젝트의 해상도 {width,height}
 * @param {object<number>} correction 편집화면의 해상도
 * @param {*} option 디스플레이에 적용할 옵션
 * 
 * @returns 
 */


const EditScreenPlaceholder =  React.memo(function EditScreenPlaceholder({ page = null, scale=0, previewWidth = null, previewHeight = null, editMargin, getSelect, reference, correction, setSelect, panelSize, option={} }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state

    // ref

    // const displayRef = useRef(null);
    const visible = true
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);

      const width = previewWidth;
      const height = previewHeight;

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const PlaceHolderCore = (correctionWidth,correctionHeight,assistState) => {

      if(scale){

        const WidgetList = page?.page_widget

        let WidgetBox = [];
        for (let index = 0; index < page.page_widget.length; index++) {
          const MergeData = DeCoder.getMergeWidget(WidgetList[index])
          if(MergeData == null){
            continue;
          }
          // 보정치 페이지 컨테이너의 시작점 (각각 편집기로 부터 20px) + 컨테이너와 편집페이지의 위치
          const  correction = 
          { 
            // 가로 세로 시작점은 편집 area 의 보정값 (페이지의 시작점) + 위젯의 위치 * 스케일 만큼 보여줍니다.
            x : MergeData.position.x*scale +editMargin.x + correctionWidth,
            y : MergeData.position.y*scale +editMargin.y + correctionHeight,
            w : MergeData?.size?.width*scale,
            h : MergeData?.size?.height*scale
            
          }
          if(WidgetList[index].uuid==getSelect){
            // 위젯이 선택된 경우

            const Widget = Utility.deepCopy(WidgetList[index])
            Widget['data'] = MergeData;

            // console.log('[버그체커] 위젯 정보 : ',`x = ${correction.x} y = ${correction.y} w = ${correction.w} h = ${correction.h}`,'widget',Widget,WidgetList[index]);
            WidgetBox.push(<WidgetController x={correction.x} y={correction.y} w={correction.w} h={correction.h} scale={scale} uuid={WidgetList[index].uuid} panelSize={panelSize} getWidget={Widget} setSelect={setSelect} assist={assistState} visible={visible} />)
          }else{
            // 선택되지 않은 위젯인 경우
            WidgetBox.push(<WidgetPlaceholder x={correction.x} y={correction.y} w={correction.w} h={correction.h} uuid={WidgetList[index].uuid} setSelect={setSelect} visible={visible} />)
          }

        }
          return WidgetBox
      }
  }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const ContainerSizeControl ={
      // top : position.top,
      // left : position.left,
        height: previewHeight,
        width : previewWidth,
        alignItems: option.align??'center',
   
    }
    const DisplaySizeControl = {
        aspectRatio:  `${reference.width} / ${reference.height}`,

      };

    if(previewHeight>=previewWidth*reference.ratio){
      DisplaySizeControl['width'] = previewWidth;

    }else{
      DisplaySizeControl['height'] = previewHeight;
    }


    const scaleEvent = (e) =>{
      if(e.shiftKey){
        if (e.deltaY > 0) {
          console.log('Scrolled Down',e.clientX,e.clientY);
      } else if (e.deltaY < 0) {
          console.log('Scrolled Up',e.clientX,e.clientY);

      }
      }

      // 이벤트 전파 방지
      e.stopPropagation();
      // 이벤트의 기본 동작 방지
      e.preventDefault();
    }
    
    const ScaleControl = {
        transform:  `scale(${1})`,
    };

      
    return (
        <div className='PlaceholderAreaContainer' style={ContainerSizeControl} onClick={(e)=>{setSelect(null);}}  >
        {/* <style jsx>{`${DisplayStyle}${SizeStyle}`}</style> */}
        <style jsx>{`${DisplayStyle}`}</style>
        <AssistContext.Consumer>
          {assistState => (
            <div style={DisplaySizeControl} className='PlaceholderArea' >
                <div style={ScaleControl} className='PlaceholderAreaCore' 
    
                // onWheel={(e)=>scaleEvent(e)}
                
                >
                    {PlaceHolderCore(correction.width,correction.height,assistState)}
                </div>
            </div>
            )}
          </AssistContext.Consumer>
        </div>
    );
  });

  export default EditScreenPlaceholder;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const DisplayStyle = `
.PlaceholderAreaContainer {
  display:flex;
  justify-content: center;
  position: absolute;
  z-index:2;

  
}
.PlaceholderAreaContainer .PlaceholderArea{
  box-sizing: border-box; 
  width: 100%;
  height: 100%;
}
.PlaceholderAreaContainer .PlaceholderAreaCore{
  width: 100%;
  height: 100%;
  transform-origin: 0% 0%; 
}

`;


