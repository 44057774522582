////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import AppendFile from 'components/edit/editPanel/append/AppendFile';
import VarTeamPlayer from 'components/edit/editVariable/varTeam/VarTeamPlayer';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendFileArea({context,panelHeight,panelWidth,listOption={}}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [FileList, setFileList] = useState(false);

    // state
    const [SelectFile, setSelectFile] = useState(null);

    const [SelectDetail, setSelectDetail] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        getFile();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


    
    const getFile = async () => {

     const Files  = await fileAPI.getList(context.spaceId);

     console.log('Files',Files);
      setFileList(Files)
    }

    const fileUpload = async (files) => {
      if (files) {
          const file = files?.[0];
          // 파일 타입 검사
          if(file?.type){
            const Type = Utility.fileMIME(file.type)
            if (Type == null) {
                alert('업로드가 불가능한 파일입니다.');
                return;
            }
            try {
                await fileAPI.upload(context.spaceId, file);
                getFile();
            } catch (error) {
                console.error('파일 업로드 실패:', error);
            }
          }
      }
    };



    const deleteFile = async () => {

      try {
        const isDelete = await fileAPI.del(context.spaceId,SelectFile?.uuid)
        setSelectFile(null)
        getFile()
      } catch (error) {
        
      }

    }


    
    /**
     * 파일을 드래그 앤 드랍을 해서 위젯을 생성합니다.
     * @param {*} file 데이터 베이스에서 받은 파일 객체
     */
    const DropAppendFile = (file) => {

     
      const Type = Utility.fileMIME(file?.file_type)

      console.log('dropControl',Type)
      switch (Type) {
        case 'image':
          context.dropControl('addWidget','image',{widget:'Image',value:{mediaSource:file?.uuid,mediaType:'library'}});
          break;
        default:
          context.dropControl(null);
          break;
      }

      if(Type){

        
        // context.dropControl('addFile',target,data);
      }else{
        // context.dropControl(null);
      }
    }

    let list = null
    console.log('FileList',FileList);
    
    if(FileList!==false){
      if(FileList.length==0){
        list = (
          <div id="FileListArea">
            <div className="Files" style={{background:'#303030'}}> 
                <label htmlFor="file-upload" className="fileUploadButton" style={{width: 100, height: 100,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                {Editor.icon({icon:'drive_folder_upload',size:40,lineHeight:50})}
                <p style={{fontSize:15}}>
                파일 가져오기 
                </p>
                </label>
                <input style={{display:'none',}} id="file-upload" type="file" onChange={(e)=>{fileUpload(e?.target?.files)}} />
                {/* <button>업로드</button> */}
                {/* {selectedFile && <p>선택된 파일: {selectedFile.name}</p>} */}
              </div>
          </div>
        )
      }else{
        list = (
          <div id="FileListArea"
            onDrop={(e)=>fileUpload(Array.from(e.dataTransfer.files))}
          >
              <div className="Files" style={{background:'#303030'}}> 
                <label htmlFor="file-upload" className="fileUploadButton" style={{width: 100, height: 100,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                {Editor.icon({icon:'drive_folder_upload',size:40,lineHeight:50})}
                <p style={{fontSize:15}}>
                파일 가져오기 
                </p>
                </label>
                <input style={{display:'none',}} id="file-upload" type="file" onChange={(e)=>{fileUpload(e?.target?.files)}} />
                {/* <button>업로드</button> */}
                {/* {selectedFile && <p>선택된 파일: {selectedFile.name}</p>} */}
              </div>

          {FileList.slice().reverse().map(file => (
              <AppendFile 
                  key={file.uuid} 
                  fileData={file} 
                  select={setSelectFile} 
                  dropAppendFile={DropAppendFile}
              />
          ))}

          </div>
        )
      }
    }


  function calculateTotalFileSize(FileList) {
      return FileList.reduce((total, file) => total + file.file_size, 0);
  }
  
  console.log('SelectFile',SelectFile);
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section id = 'AppendFileArea'>
                  <div id="FileContainer">
                  {list}
                  </div>
                  {SelectFile?
                  <div id = 'SelectFileDetail'>
                    <div id='FileDetailArea'>
                    <div className='filePreview'>
                      <img className='filePreviewImage' src={SelectFile?.file_path}/>
                    </div>
                    <div className='fileInfo'>
                      <div className='fileInfoContainer'>
                        <div className='fileName'>
                        <span>파일 이름</span>
                        <span>
                        {SelectFile?.file_name}
                        </span>
                        
                        </div>
                        <div className='fileSize'>
                          {SelectFile?.file_option?.resolution?
                            <span>이미지 해상도  {SelectFile?.file_option?.resolution.width}px * {SelectFile?.file_option?.resolution.height}px </span>
              
                          :null}
                          <span>파일 크기 {Utility.fileSize(SelectFile?.file_size)}</span>
                        </div>
                      </div>
                      <div className='fileOptionContainer'>
                        <div className='fileOption'>
                        <span>파일 삭제</span>
                        <div className='deleteButton'>
                          <button onClick={()=>deleteFile()}>
                          {Editor.icon({icon:'delete',size:25,lineHeight:30})}
                          </button>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div id="FileButtonArea">  
                    <div className="buttons">
                      <button className="backFileListButton" onClick={()=>setSelectFile(null)}>
                      {Editor.icon({icon:'list',size:25,lineHeight:30})}
                      &nbsp;
                      목록으로 이동
                      </button>
                      
                    </div>
                    
                  </div>
              
                    </div>

                  </div>
                  :null}
                </section>

        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendFileArea);
  


  const EditPageStyle = `

  #AppendFileArea {
    display: flex;
     flex : 1 ;
  }

  #AppendFileArea #FileContainer {
    flex : 1 ;
    display: flex;

  }
  #AppendFileArea #FileListArea{
    flex : 1 ;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-template-rows: repeat(auto-fill, 100px);
    gap: 10px; /* 아이템 간 여백 */

    overflow-y: auto;

    box-sizing: border-box;
    padding : 10px;
    border-radius: 0px 0px 5px 5px;
  }

  #AppendFileArea #FileContainer .noneFile{
    flex : 1 ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-aline : center;
    line-height : 30px;
  }

  

  #AppendFileArea #FileListArea .Files {
    width: 100px;
    height: 100px;
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 10px;
    position: relative;
    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out,  background 0.1s ease-out;
}

#AppendFileArea #FileListArea .Files:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border-radius: 10px;
    z-index: -1;
}


#AppendFileArea #FileListArea .Files:active:before {
       transform: scale(1.1111);
}

#AppendFileArea #FileListArea .Files:active {
      background-color: #1C1C1C;
      transform: scale(0.90);
}

  #AppendFileArea #FileListArea .Files .FilePreview{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 5px;

  }

  #AppendFileArea #FileListArea .Files .FilePreview .PreviewImage{
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
      border-radius: 5px;
  }


  #AppendFileArea #FileListArea .Files .FileInfo{
     position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

  }

  #AppendFileArea #FileListArea .Files .FileInfo .FileName{
      font-size : 15px;
      width: calc(100% - 10px) ;
      height: 20px;
      line-height : 20px;
      display: flex;
      margin : 0px 5px ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }


  #AppendFileArea #FileListArea .Files .FileInfo .FileType{
      position: absolute;
      right : 5px;
      top : 5px;
      border-radius : 2.5px;
      padding : 2.5px;
      height: 10px;
      line-height : 10px;
      font-size : 10px;
      background : #444444aa;
      font-family : 프리텐다드Regular;
}
  #AppendFileArea #FileListArea .Files .FileOption{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
  }
  
  #AppendFileArea #SelectFileDetail{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px) ;
    background-color : #3F3F3F;
    border-radius : 0px 0px 5px 5px;
  }

  #AppendFileArea #SelectFileDetail #FileDetailArea {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;

}

  #AppendFileArea #SelectFileDetail .filePreview{
      display: flex;
      height: 300px;
      width: 300px;
      padding : 20px;
      margin : 20px 0px 20px 20px;
      border-radius : 10px;
      background-color : #2c2c2c;

  }

  #AppendFileArea #SelectFileDetail .filePreview .backListButton{
      position: absolute;
      width: 30px;
      height: 30px;
      background-color : #2c2c2c;
      border-radius : 30px;
      top: calc( 170px );
      left : -15px;
  }

  #AppendFileArea #SelectFileDetail .fileInfo{
      display: flex;
      height: 340px;
      width: 340px;
      margin : 20px;
      border-radius : 10px;
 
      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
  }
  #AppendFileArea #SelectFileDetail .fileInfo .fileName{
      display: flex;
      flex-direction: column;
      width: 300px;
      padding : 20px;
      background-color : #2c2c2c;
      border-radius : 5px;
      word-wrap: break-word;
  }
  #AppendFileArea #SelectFileDetail .fileInfo .fileSize{
      display: flex;
      flex-direction: column;
      width: 300px;
      padding : 20px;
      margin-top : 20px;
      background-color : #2c2c2c;
      border-radius : 5px;
  }
      
  #AppendFileArea #SelectFileDetail .fileInfo .fileOption{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      padding : 20px;
      margin-top : 20px;
      background-color : #2c2c2c;
      border-radius : 5px;
  }

  #AppendFileArea #SelectFileDetail .fileInfo .deleteButton{
      background-color : red;
      border-radius : 5px;
      display: flex;
      width: 30px;
      justify-content: center;
      align-items: center;

  }

   
  #AppendFileArea #SelectFileDetail #FileButtonArea{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80px;
      position: absolute;
      background:linear-gradient(to bottom, rgba(44,44,44,0), rgba(44,44,44,1));
      border-radius: 0px 0px 5px 5px;
      bottom : 0px;
  }
  #AppendFileArea #SelectFileDetail #FileButtonArea .buttons{
      padding : 0px 10px;
      height: 40px;
      margin: 10px;
      border-radius : 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color : #3F3F3F;
      box-shadow: 
        0px 2px 4px rgba(0, 0, 0, 0.15),
        0px 6px 8px rgba(0, 0, 0, 0.15),
        0px 12px 16px rgba(73, 25, 25, 0.1),
        0px 18px 24px rgba(0, 0, 0, 0.15);
  }
    #AppendFileArea #SelectFileDetail #FileButtonArea .buttons .backFileListButton{
      display: flex;
      justify-content: center;
      align-items: center;
      color : #fff;
  }

  .filePreviewImage{
      width:  100% ;
      height: 100% ;
      object-fit: scale-down;

  }
  `;
  
