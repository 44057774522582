////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬


import ScoreboardModule from '../editModule/ScoreboardModule'; // 스코어 보드용 팀 묘듈
import BanPickModule from '../editModule/BanPickModule'; // 스코어 보드용 팀 묘듈
import SentenceModule from '../editModule/SentenceModule'; // 텍스트 모듈

import TextSizeModule from '../editModule/TextSizeModule'; // 텍스트 크기 모듈
import FontModule from '../editModule/FontModule'; // 폰트 모듈
import ColorModule from '../editModule/ColorModule'; // 색상 모듈
import SwitchModule from '../editModule/SwitchModule'; // 체크박스 모듈
import NumberModule from '../editModule/NumberModule'; // 숫자 모듈

import DropdownModule from '../editModule/DropdownModule'; // 숫자 모듈

// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import { dragTransparent } from 'service/event/common' ; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function BanPickRuleEditForm({target,modify,keyName,title,option}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid
    const {style={},meta={},size={},value={},position={},display={},preset=null} = keyName
    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [ScoreboardMode, setScoreboardMode] = useState('list');

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

       // 해당 에디터에서 편집이 가능한 값 목록
       const {infoFont,banPickRule,teamData} = keyName ;


    const getTeamData = () => {




        return(<div>

        </div>
            // <div className='teamEdit'>
            //     <StringModule value={'1'}  title="팀 이름" type ="matchInfo" change={()=>console.log(1)}/>
            //     <StringModule value={'1'}  title="팀 이미지" type ="matchInfo" change={()=>console.log(1)}/>
            //     <StringModule value={'1'}  title="팀 점수" type ="matchInfo" change={()=>console.log(1)}/>
            // </div>
        )

    }



    const appendRole = (type,team) => {
        const Role = ED.getValue(target,banPickRule)


        const NewRoleID = Utility.uuidMake();
        Role.push({type,team:'left',timer:'30'})
        modify(banPickRule.category,banPickRule.key,Role,uuid)

    }

    const deleteRole = (objectIndex) => {
         
        const Role = ED.getValue(target,banPickRule)

        const FilterRole = Role.filter((element,index) => index != objectIndex);

        modify(banPickRule.category,banPickRule.key,FilterRole,uuid)
    }


    const modifyRole = (index,key,value) =>{
        const Role = ED.getValue(target,banPickRule)
        // 객체의 id 값을 수정
        if (Role[index]) {
            Role[index][key] = value
        }

        modify(banPickRule.category,banPickRule.key,Role,uuid)
    }



    function arraysMatch(arr1, arr2) {
        // 먼저 배열의 길이를 비교
        if (arr1.length !== arr2.length) {
            return false;
        }
    
        // 배열의 각 요소를 비교
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false; // 요소가 서로 다르면 false 반환
            }
        }
    
        // 모든 요소가 일치하면 true 반환
        return true;
    }

    const updateRole = (RoleList) =>{

        const Role = ED.getValue(target,banPickRule)

        RoleList = RoleList.filter(element => element !== undefined);
        const ReversList = RoleList.slice();
        modify(banPickRule.category,banPickRule.key,ReversList,uuid)



        // const OriginalIDList = Role.map(item => item.id);
        // const ChangedIDList = RoleList.map(item => item.id);

        // if(!arraysMatch(OriginalIDList, ChangedIDList)){
    

        // }

    }

    const update = (teamList) =>{

        const TeamList = ED.getValue(target,teamData)


        teamList = teamList.filter(element => element !== undefined);

        const OriginalIDList = TeamList.map(item => item.id);
        const ChangedIDList = teamList.map(item => item.id);

        if(!arraysMatch(OriginalIDList, ChangedIDList)){
    
            const ReversTeamList = teamList.slice();
            modify(teamData.category,teamData.key,ReversTeamList,uuid)
        }

    }


    const Rule = ED.getValue(target,banPickRule)

    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='BanPickRuleEditForm'>
            {Editor.editorDetail('밴픽 규칙','rule',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>

                <article className="EditPart">
                    <nav className="banPickRuleNav">
                        <div className='banPickDropdown'>
                            <DropdownModule value={'드레프트픽'} type ="device" change={()=>console.log()} title="밴픽 규칙"  dropdown ={['드레프트픽','자유픽']}/>
                        </div>
                    </nav>
                </article>

                <article className="EditPart">
                    <nav className="banPickRuleNav">
       
                        <div  className="banPickRuleTitle">
                            규칙 숫자<small> &#40; {Rule?.length} &#41;</small>
                        </div>
                        <div className='banPickRuleNavButtons'>
                            <GuideBalloon  width={155} guideText={<p> 새로운 밴을 추가합니다. </p>} direction='topright'>
                            <div className='banPickButton' onClick={()=>appendRole('ban')}>
                            {Editor.icon({icon:'ad_off',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon> 
                            <GuideBalloon width={155} guideText={<p> 새로운 픽을 추가합니다. </p>} direction='topright'>
                            <div className='banPickButton' onClick={()=>appendRole('pick')}>
                            {Editor.icon({icon:'ad',size:25,lineHeight:30})}
                            </div>
                            </GuideBalloon> 
                        </div>
                     
                    </nav>

                    <div>

                    </div>
                    <div className="banPickRuleField" onDragStart={dragTransparent}>
                    <ReactSortable
                        className="banPickList" 
                        list={Rule}
                        setList={(order) => updateRole(order)}
                        animation = { 200 }
                        // handle = '.handle' 
                        delayOnTouchStart = { true } 
                    >  
                    {Rule.map((item,index) => (
                    <BanPickModule key={index} target={item} index={index} modify={modifyRole} removeModule={deleteRole}/>
                    ))}
                    </ReactSortable>
                    </div>
                  
   
      

                </article>
                
            </div>
        </section>
    </Fragment>
    );

}

export default BanPickRuleEditForm;


const FormStyle = `

.BanPickRuleEditForm {
    justify-content: space-between;
}

.BanPickRuleEditForm article.EditPart{
    width : 100%;
    // background : #333;
    padding: 5px 0px 0px 0px;
}
.BanPickRuleEditForm .banPickRuleNav{
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;

}
.BanPickRuleEditForm .banPickRuleField{
    display: flex;
    width : 100%;
    height: 400px;
    background : #333;

    
}

..BanPickRuleEditForm .banPickRuleField::-webkit-scrollbar {
    display: none; /* 스크롤바 숨김 */
}
.BanPickRuleEditForm .banPickRuleTitle{
    line-height : 30px;
    height: 30px;
}
.BanPickRuleEditForm .banPickRuleNavButtons{
    display: flex;
    margin : 0px 0px 5px 0px;
}
.BanPickRuleEditForm .banPickButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    height: 30px;
    margin : 0px 0px 0px 10px;
    border-radius: 5px;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
}
.banPickList{
    width : 100%;
    overflow: auto;
}
.banPickDropdown{
    width : calc(100% - 30px);
}

.BanPickModule{
    font-family: "프리텐다드SemiBold", sans-serif;
    border-radius: 5px;
    background: #444444;
    margin : 10px;
    padding : 10px;
    overflow :hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.BanPickModule .BanPickModuleHeader{
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;   
}

.BanPickModule.ban .BanPickModuleIndex{
    background: red;
}

.BanPickModule.pick .BanPickModuleIndex{
    background: blue;
}

.BanPickModuleIndex{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
}
.BanPickModuleDel{
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.BanPickModuleDel:hover{
    background: orange;
}

.BanPickModule .BanPickTeam{
    width: 65px;
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.BanPickModule .BanPickTeam button{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.BanPickModule .BanPickTeam button.select{
    border-radius: 2.5px;
    background: #262626 !important;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
}
.BanPickModule .BanPickTimer{
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.BanPickModule .BanPickTimer button{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.BanPickModule .BanPickTimer button.select{
    border-radius: 2.5px;
    background: #262626 !important;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
}
`;



const TempScoreboardData = [
    {
        teamName : 'team1',
        teamColor : '#ff0000',
        teamLogo : '',
        score :  0,
        subScore : 0, 
    },    {
        teamName : 'team2',
        teamColor : '#ffff00',
        teamLogo : '',
        score :  1,
        subScore : 1, 
    }
] 