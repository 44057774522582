////////////////////////////////////////////////////////
// 오버랩에 관한 설정
////////////////////////////////////////////////////////
export const  VERSION = '0.5.2' // 오버랩 엔진에 대한 버전입니다.

export const  SERVICE = 'OVERLAP'; // 오버랩 모드에 대한 설정

/*

OVERLAP : 인터넷 방송 및 프레젠테이션 용 서비스 입니다.

NEON : 샤이니지 및 키오스크 서버스입니다.

DISPLAY : 오버렙 서비스의 뷰어 역활만을 담당합니다. 

*/

////////////////////////////////////////////////////////
// 원격지 설정
////////////////////////////////////////////////////////


export function SERVERSSET() {


    sessionStorage.setItem("key", "value");

    // const overlap = io(config.SERVERS[0].serverEndPoint+":"+'3030'+'/overlap');
    // return overlap;
}

export const SERVERS = [
    {
        serverName : 'overlapBata',
        serverEndPoint : 'https://api.overlap.pe.kr',
        serverPort : '2020',
        defalut : true,
    },
    // {
    //     serverName : 'overlapBataHttp',
    //     serverEndPoint : 'http://api.overlap.digital',
    //     serverPort : '2022',
    //     defalut : true,
    // },
    // {
    //     serverName : 'overlapTest',
    //     serverEndPoint : 'http://localhost',
    //     serverPort : '2022',
    //     defalut : false,
    // },
    // {
    //     serverName : 'jongho',
    //     serverEndPoint : 'https://jongho.shop',
    //     serverPort : null,
    //     defalut : false,
    // },
];

////////////////////////////////////////////////////////
// 인증과 관련됨
////////////////////////////////////////////////////////

export const  GOOGLE_REST_API_KEY = '605865020300-opjdh80qpqqrdedrcbjjt6kl23k68bqp.apps.googleusercontent.com';
