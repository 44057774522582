////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 위젯 
import MiniView from "components/viewer/MiniView"
import ViewList from "components/lobby/space/wrap/ViewList"
import OverlapList from "components/lobby/space/wrap/OverlapList"
import OverlapCreate from "components/lobby/space/module/OverlapCreate"
import ViewCreate from "components/lobby/space/wrap/ViewCreate"

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';

// 서비스 연결
import * as Space from 'service/api/space' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceDropDown(props) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [spaceList, setList] = useState(null);
    // rel 
    // const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        initSpace()
        console.log('componentDidMount[Function]',props);
    }, []);
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


    async function initSpace() {
         // 1. 백엔드에서 스페이스 리스트를 가저옵니다.
        const spaceList = await getSpaceList();

        // 1. 계정이 가지고 있는 스페이스가 하나인경우 자동으로 해당 스페이스로 이동합니다.
        console.log('spaceList',props);
        if(spaceList.length<=1){
            props.setSpace(spaceList[0]);

            return ;
        }


    }
    async function getSpaceList() {
        const spaceList = await Space.getSpace();
        setList(spaceList)
        return spaceList;
    }

    function viewListDropDown () {
        // switch (this.state.viewListDropDown) {
        //     case true:
        //         const ViewList = this.context.viewList
        //         const List = ViewList.map(
        //             (view,index) => (
        //                 <li onClick={()=>this.context.selectView(view.uuid)}>
        //                     <p title={view.uuid}>{view.view_name}</p>
        //                 </li>
        //             ) 
        //         );    
        //         return(
        //             <ul className="dropdown" onClick={()=>this.setState({viewListDropDown:false})}>
        //                 {List}
        //             </ul>
        //         );
        //     case false:
        //         return(
        //         ''
        //         );
        
        // }
    }
      

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    let selectSpace = <p className='selectSpace'>스페이스 선택</p> 

    if(props.space){
        selectSpace = <p className='selectSpace'>{props.space.space_name}</p> 
    }
    console.log('select',props.space);

    return (
        
        <div id = "SpaceDropDown" >
            {selectSpace}
        </div>
        
    );
  }

  export default SpaceDropDown;

  