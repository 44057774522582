import * as Utility from 'service/other/Utility' ; 

function preset(preset,self,data,style){
    let fullsize = data.size.height
    let width = data.size.width
    let profile = self.state.profile
    let dataList = []
    const FillColor =  data.style.fill;
    let dataField =''
    switch (preset) {
        case 'Postit':
            const PostHead =  Utility.colorDiff(FillColor,20,20,20)
            const ContentSubColor =  Utility.colorDiff(PostHead,62,62,62)
            if(data.style.direction !="vertical"){
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset+" horizontal"} ref={self.widget} style={{...style.object,background:`linear-gradient(to right, ${FillColor},${FillColor},#00000000,#00000000)`}} >
                        <div className="postSticker" style={{backgroundColor:FillColor}}/>
                        <div className="postContent" style={{width:(width-20)+'px',background:`linear-gradient(135deg, ${PostHead} 60%, ${PostHead} 60%, ${PostHead} 60%, ${ContentSubColor} 100%)`}}>
                            <div className="postTextPadding"/>
                            <p className="postMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                            <div className="postTextPadding"/>
                        </div>
                    </div>
                )
            }else{
                return(
                    <div className={"object "+self.props.widgetName+"_preset_"+preset+" vertical"} ref={self.widget} style={{...style.object,background:`linear-gradient(to right, ${FillColor},${FillColor},#00000000,#00000000)`}} >
                        <div className="postSticker" style={{backgroundColor:FillColor}}/>
                        <div className="postContent" style={{width:(width)+'px',background:`linear-gradient(135deg,  ${PostHead} 60%, ${PostHead} 60%, ${PostHead} 60%, ${ContentSubColor} 100%)`}}>
                            {/* <div className="postTextPadding"/> */}
                            <p className="postMain" style={{fontFamily:style.object.fontFamily,width:(width)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                            {/* <div className="postTextPadding"/> */}
                        </div>
                    </div>
                )  
            }

        
        case 'Modern':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,backgroundColor:FillColor}} >
                        <p className="ModernMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                </div>
            )
        case 'Apps':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object,backgroundColor:FillColor}} >
                        {/* <p className="ModernMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p> */}
                        <div class="notepad">
                            <div class="notepadNav">
                                <p>메모장</p>
                                <div class="notepadButtonArea">
                                    <div class="notepadButton" style={{background: "rgb(43, 200, 65)"}}></div>
                                    <div class="notepadButton" style={{background: "rgb(255, 187, 48)"}}></div>
                                    <div class="notepadButton" style={{background: "rgb(254, 95, 87)"}}></div>
                                </div>
                            </div>
                            <p class="notepadMain">
                            {data.value.text}
                            </p>
                        </div>
                </div>
            )
        case 'yellowPostit_Horizontal':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div className="postSticker"/>
                    <div className="postContent" style={{width:(width-20)+'px'}}>
                        <div className="postTextPadding"/>
                        <p className="postMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                        <div className="postTextPadding"/>
                    </div>
                </div>
            )
        case 'yellowPostit_vertical':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div className="postSticker"/>
                    <div className="postContent" style={{width:(width)+'px'}}>
                        {/* <div className="postTextPadding"/> */}
                        <p className="postMain" style={{fontFamily:style.object.fontFamily,width:(width)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                        {/* <div className="postTextPadding"/> */}
                    </div>
                </div>
            )    
        case 'pinkPostit_Horizontal':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div className="postSticker"/>
                    <div className="postContent" style={{width:(width-20)+'px'}}>
                        <div className="postTextPadding"/>
                        <p className="postMain" style={{fontFamily:style.object.fontFamily,width:(width-20)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                        <div className="postTextPadding"/>
                    </div>
                </div>
            )    
        case 'pinkPostit_vertical':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div className="postSticker"/>
                    <div className="postContent" style={{width:(width)+'px'}}>
                        {/* <div className="postTextPadding"/> */}
                        <p className="postMain" style={{fontFamily:style.object.fontFamily,width:(width)+'px',alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                        {/* <div className="postTextPadding"/> */}
                    </div>
                </div>
            )   
        case 'twitchNotice':
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}} >
                    <div className="logo">
                        <svg height={fullsize/1.5+'px'} viewBox="0 0 256 268" >
                            <g>
                                <path d="M17.4579119,0 L0,46.5559188 L0,232.757287 L63.9826001,232.757287 L63.9826001,267.690956 L98.9144853,267.690956 L133.811571,232.757287 L186.171922,232.757287 L256,162.954193 L256,0 L17.4579119,0 Z M40.7166868,23.2632364 L232.73141,23.2632364 L232.73141,151.29179 L191.992415,192.033461 L128,192.033461 L93.11273,226.918947 L93.11273,192.033461 L40.7166868,192.033461 L40.7166868,23.2632364 Z M104.724985,139.668381 L127.999822,139.668381 L127.999822,69.843872 L104.724985,69.843872 L104.724985,139.668381 Z M168.721862,139.668381 L191.992237,139.668381 L191.992237,69.843872 L168.721862,69.843872 L168.721862,139.668381 Z" fill="#FFFFFF"></path>
                            </g>
                        </svg>
                    </div>
                    <div className="content">
                        <p className="postMain" style={{fontFamily:style.object.fontFamily,alignItems:style.object.textVerticalAlign}}> {data.value.text}</p>
                    </div>
                </div>
            )   
        default:
            return(
                <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                    <p>알수없는 프리셋</p>
                </div>
                )
    }
}
export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

