////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import ViewInfo from "components/lobby/space/module/ViewInfo"
import AppendFile from 'components/edit/editPanel/append/AppendFile';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleFileList({space,fileList,getFileList,loading}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state

    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {

    //     return () => {
    //     };
    //   },[]);

    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     setTimeout(function() {
    //         getFileList()
    //       }, 1000);
    //     // Cleanup function (optional)
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[props.mode]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const fileUpload = async (files) => {
        console.log('space',space)
        if (files) {
            const file = files?.[0];
            // 파일 타입 검사
            if(file?.type){
              const Type = Utility.fileMIME(file.type)
              if (Type == null) {
                  alert('업로드가 불가능한 파일입니다.');
                  return;
              }
              try {
                  await fileAPI.upload(space.id, file);
                  getFileList();
              } catch (error) {
                  console.error('파일 업로드 실패:', error);
              }
            }
        }
      };
      

    const fileContent = () =>{

        if(loading == false){
            return(
                <div className='veiwLoading'>
  
                </div>
            )
        }
        if(loading == true && fileList?.length == 0){
            return(
                <div className='undefinedView'>
                    <p>라이브러리에 파일이 없습니다.</p>
                    <p>파일 가져오기 버튼을 눌러서 파일을 추가해주세요</p>
                </div>
            )
        }
        if(loading == true && fileList?.length > 0){
            return(
                <div className='FileListWrap'>
                    {fileList.map(file => (
                        <AppendFile key={file.uuid} fileData={file} select={(e)=>{console.log(e)}}/>
                    ))}
                </div>
            )
        }

    }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='SpaceModuleContainer ViewListContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='spaceField'>
        <nav  id='TemplateStoreListNav' >
            <div className='categoryList'>
                    <button className='category'>
                        {Editor.icon({icon:'folder_open',size:20,lineHeight:30})}
                        &nbsp;
                        모든파일
                    </button>
                    <button className='category'>
                        {Editor.icon({icon:'photo_library',size:20,lineHeight:30})}
                        &nbsp;
                        이미지
                    </button>

                    <button className='category'>
                        {Editor.icon({icon:'video_library',size:20,lineHeight:30})}
                        &nbsp;
                        동영상
                    </button>

                    <button className='category'>
                        {Editor.icon({icon:'picture_as_pdf',size:20,lineHeight:30})}
                        &nbsp;
                        문서
                    </button>

                    {/* <button className='category'>
                        {Editor.icon({icon:'mic',size:20,lineHeight:30})}
                        &nbsp;
                        스트리밍
                    </button> */}
            </div>


            <div className="upload" style={{ display :'flex'}}> 
                <label htmlFor="file-upload" className="fileUploadButton" style={{width: 130, height: 30,display:'flex',margin:0,padding:0,justifyContent:'center'}}>
                {Editor.icon({icon:'drive_folder_upload',size:25,lineHeight:30})}
                <p style={{fontSize:15,marginLeft:10}}>
                파일 가져오기
                </p>
                </label>
                <input style={{display:'none',}} id="file-upload" type="file" onChange={(e)=>{fileUpload(e?.target?.files)}} />
                {/* <button>업로드</button> */}
                {/* {selectedFile && <p>선택된 파일: {selectedFile.name}</p>} */}
            </div>

            
        </nav>

            <div  id='FileContainer'
                onDrop={(e)=> {
                    e.preventDefault();
                    fileUpload(Array.from(e.dataTransfer.files));
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
            >
                {fileContent()}
            </div>
        </div>
    </div>
    );
  }

  export default SpaceModuleFileList;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .ViewListContainer {

    }

`;

const ContainerStyle = `
   #TemplateStoreListNav{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
    #OverlapMain{
        display: flex;
        width: 100%; 
        height: 100%;
    }
    #TemplateStoreListNav .upload {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 15px;
        height: 30px;
        width : 130px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
    }

    #TemplateStoreListNav .fileUploadButton{
        display: flex;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
    }
    #TemplateStoreListNav .backButton {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        border-radius: 50px;
    }
    #TemplateStoreListNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
    }
    #TemplateStoreListNav .categoryList{
        display: flex;
    }


     .ViewListContainer {
        width: 100%;
        flex :1;
    }
    .ViewListContainer .spaceField{
        display: flex;
        width: 100%; 
        height: 100%;
        flex-direction: column;
        background-color: #2D2D2D;
    }
    .ViewListContainer #FileContainer{
        width: calc(100% - 0px);
        margin-top : 50px;
        height: 100%;
        display: flex;
        align-items: center;
        overflow-y : auto;
    }

    .ViewListContainer .FileListWrap{
        flex : 1 ;
        width: calc(100% - 40px);
       
        height: 100%;
        display: grid;
        gap: 10px; /* 아이템 간 여백 */
        overflow-y: auto;
        box-sizing: border-box;
        padding: 30px 20px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-auto-rows: 150px;
        justify-content: center;
        align-items: center;
    }    

    .ViewListContainer  #FileContainer .noneFile{


    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-aline : center;
    line-height : 30px;
  }

   
  .ViewListContainer  #FileContainer .Files{

    width: 150px;
    height: 150px;
    background-color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border-radius: 10px;
    position: relative;
    align-self: center; /* 수직 중앙 정렬 */
    justify-self: center; /* 수평 중앙 정렬 */
  }
  .ViewListContainer  #FileContainer .Files .FilePreview{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    border-radius: 5px;

  }

  .ViewListContainer  #FileContainer .Files .FilePreview .PreviewImage{
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
      border-radius: 5px;
  }
  .ViewListContainer  #FileContainer .Files .FileInfo{
     position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

  }

  .ViewListContainer  #FileContainer .Files .FileInfo .FileName{
      font-size : 15px;
      width: calc(100% - 10px) ;
      height: 20px;
      line-height : 20px;
      display: flex;
      margin : 0px 5px ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  
  }
  .ViewListContainer  #FileContainer .Files .FileOption{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

  }

    .ViewListContainer  #FileContainer .Files .FileType{
        background: #222;
        display: flex ;
        width: 40px;
        height: 20px;
        padding: 5px;
        font-size : 15px;
    }

    .ViewListContainer .spaceNav{
        height: 40px;
        width: 100%;
        padding: 0px 10px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex ;
        justify-content: space-between;
    }
    .ViewListContainer .NavTitle{
        margin: 0px 10px ;
        font-size: 20px;
    }
    .ViewListContainer .bnt_List{
    
    }
    .ViewListContainer .createView{
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px ;
        background: #222;
        font-size: 15px;
        height: 30px;    
        padding: 0px 7.5px;
        margin: 20px 10px;            

        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: red;
    }
    .ViewListContainer .createView .icon{
        height: 20px;
        width: 20px;
        font-size: 20px;
        object-fit: scale-down;
        margin: 5px 5px 5px 0px;
    }
  
    .ViewListContainer .veiwList{
        width: 100%;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        grid-auto-rows: 150px;
    }



    .ViewListContainer .veiwLoading{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
    }
    .ViewListContainer .undefinedView{
        flex :1;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #adadad;
        flex-direction: column;
      
    }

    
`;