////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 

// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditProvider from 'service/provider/EditProvider';

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import WidgetListPanel from 'components/edit/editPanel/preferences/utilPreferences/WidgetListPanel';//오버레이
import ScreenPlaceholder from 'components/edit/editScreen/EditScreenPlaceholder';

import WidgetController from 'components/edit/editScreen/controlObject/WidgetController';
// 서비스 연결
import * as Action from 'service/edit/ActionService'; 

import * as Event from 'service/event/Actions' ; 
import { dragTransparent } from 'service/event/common' ; 
import overlapContext from 'service/context/overlapContext';
import { filter } from 'lodash';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [편집화면-컨트롤러] 
 * 프리뷰용도로 제작된 디스플레이 입니다.
 * @param {*} targetPage 편집화면-컨트롤러가 가르키고 있는 대상 페이지
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * 
 * @param {object<number>} editMargin 편집화면과 편집 패널 사이의 마진 {x: x마진, y: y마진}
 * @param {string} baseDimension max 사이즈를 정할 때 기준이 되는 축
 * @param {number} positionTop 편집기의 세로 시작위치 (드레그 위치를 잡기 위해 필요),
 * @param {number} positionLeft 편집기의 가로 시작위치 (드레그 위치를 잡기 위해 필요),
 * @param {number} scale 편집 화면의 배율,
 * @param {number} panelSize 편집 화면의 배율,
 * @param {*} context EditContext api
 * 
 * @returns 
 */

function EditControl({positionTop,positionLeft,scale,editMargin,targetPage,baseDimension,previewWidth,previewHeight,context,panelSize}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    const [position, setPosition] = useState({ startX: 0, startY: 0, endX: 0, endY: 0, drag:false});
    // rel 


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////




    const handleDragStart = (e) => {
      setPosition({
        ...position,
        startX: e.clientX - positionLeft ,
        startY: e.clientY - positionTop,
        endX: e.clientX - positionLeft,
        endY: e.clientY - positionTop,
        drag:true,
 
      });
      // console.log(`Drag started at (${e.clientX}, ${e.clientY})`);
    };

    const handleDrag = (e) => {
      if(position.drag){
        setPosition({
          ...position,
          endX: e.clientX - positionLeft,
          endY: e.clientY - positionTop,
          
        });
        // console.log(`Drag ended at (${e.clientX}, ${e.clientY})`);
      }
    };
  
    const handleDragEnd = (e) => {
      setPosition({
        ...position,
        drag:false,
      });
    };   

    const positionControl = () =>{

      let positionY;
      let positionX;

      // 드레그의 세로 위치가 시작점이 더 작고 종료점이 더 큰 경우
      if(position.startY < position.endY){
        positionY = {
          top:position.startY,
          height:position.endY - position.startY,
        };
      }
      // 드레그의 세로 위치가 시작점이 더 작고 종료점이 더 큰 경우
      else{
        positionY = {
          top:position.startY - (position.startY - position.endY),
          height:position.startY - position.endY,
        };
      }

      // 드레그의 가로 위치가 시작점이 더 작고 종료점이 더 큰 경우
      if(position.startX < position.endX){
        positionX = {
          left:position.startX,
          width:position.endX - position.startX,
        };
      }
      // 드레그의 가로 위치가 시작점이 더 작고 종료점이 더 큰 경우
      else{
        positionX = {
          left:position.startX - (position.startX - position.endX),
          width:position.startX - position.endX,
        };
      }
      const DragPosition = {...positionY,...positionX}
      return DragPosition

    }
    
    const SelectWidget = context.selectWidget;

    /**
     * 편집기에서 선택된 위젯을 변경합니다.
     * @param {number|null} uuid 선택된 위젯의 uuid | null 인경우 선택된 위젯이 없음
     */
    const SetSelectWidget = (uuid)=>{
      // console.log('SetSelectWidget',uuid);
      context.modifySelectWidget(uuid)
    }

   





    const DragStyle = positionControl();


    // reference 오버랩 프로젝트의 설정값
    const Reference = {
      // [width] 오버랩 프로젝트의 가로 사이즈
      width : context.overlap.meta.resolution.width,
      // [height] 오버랩 프로젝트의 세로 사이즈
      height : context.overlap.meta.resolution.height,
      // [ratio] 오버랩 프로젝트의 세로대 가로비 오버랩 스크린 사이즈를 제어하기 위해 필요
      ratio : context.overlap.meta.resolution.height / context.overlap.meta.resolution.width
    }

    // ScreenSize 편집화면에서 보이는 사이즈
    const ScreenSize = {}
    // 스크린 위치에 따라 컨트롤 시작 위치 보정값
    const Correction = {
      width : 0,
      height : 0,
    }

    // 화면 사이즈 제한이 세로크기에 걸렸을 경우 (auto Width & Max Heigth)
    // console.log('정밀 비율',previewHeight,previewWidth*Reference.ratio)

    // 20을 빼주면 정상 작동하나 이유를 확인해봐야함
    const CorrectionError = 20
    if(previewHeight-CorrectionError>=previewWidth*Reference.ratio){
        // console.log('정밀 세로')
        // 에디터 스크린의 기준 실제 사이즈를 구합니다.
        ScreenSize['width'] = previewWidth - editMargin.x*2;

        // 에디터 스크린의 실제 사이즈를 구합니다.
        ScreenSize['height'] = (Reference.height / Reference.width) * ScreenSize.width;
    
        // 보정값을 구합니다. (에디터 마진 + 유동적인 화면에 따른 추가 마진값)
        Correction['height'] = (previewHeight-ScreenSize.height)/2 - editMargin.y;

    }
    // 화면 사이즈 제한이 가로크기에 걸렸을 경우 (Max Width & auto Heigth)
    else{

        // 에디터 스크린의 기준 실제 사이즈를 구합니다.
        ScreenSize['height'] = previewHeight - editMargin.y*2;

        // 에디터 스크린의 실제 사이즈를 구합니다.
        ScreenSize['width'] = ( Reference.width / Reference.height) * ScreenSize.height;
        // console.log('정밀 가로',ScreenSize.width,ScreenSize.height*Reference.ratio);
        // 보정값을 구합니다. (에디터 마진 + 유동적인 화면에 따른 추가 마진값)
        Correction['width'] = (previewWidth-ScreenSize.width)/2 - editMargin.x;   
    }

    // console.log('정밀체커 ','Correction :',Correction,'ScreenSize :',ScreenSize,'preview :',previewHeight,previewWidth,editMargin);

    if(!previewWidth==null||!previewHeight==null){
      return (
        <div></div>
      )
    }

    const SelectObject = targetPage?.page_widget?.filter((widget)=>(widget.uuid === context.selectWidget))

    let selectData = null
    if(SelectObject&&SelectObject.length != 0){

      selectData = SelectObject[0]

    }


    const contextDisable = (e) => {
      context.hideContext();
      e.stopPropagation();
    }

    const contextAble = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      context.showContext(null,'EditScreen',mouseX,mouseY);
      e.stopPropagation();
      e.preventDefault();
    }


    


    const EditControlAction = context.action? {zIndex: 10} : {zIndex: 2} ;

    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id="EditControl" 
                  style={EditControlAction}
                  onMouseDown={handleDragStart}
                  onMouseUp={handleDragEnd}
                  onMouseMove={handleDrag}
                  onClick={contextDisable}
                  onContextMenu={contextAble}
                  onKeyDown={(e)=> Action.shortcut(context,e.code)}
                  tabindex="0"
                >
  
                <ScreenPlaceholder page={targetPage} scale={scale} correction={Correction} baseDimension={baseDimension} editMargin={editMargin} previewWidth={previewWidth} positionLeft={positionLeft} positionTop={positionTop} previewHeight={previewHeight} reference={Reference} referenceWidth={context.overlap.meta.resolution.width} referenceHeight={context.overlap.meta.resolution.height} getSelect={SelectWidget} setSelect={SetSelectWidget} panelSize={panelSize}/>

                  {position.drag?   
                  <div id="DragArea" style={DragStyle}/>:''
                  }
                </div>

                {/* <WidgetController x={selectData.data.position.x} y={selectData.data.position.y} w={correction.w} h={correction.h} uuid={WidgetList[index].uuid} select={WidgetList[index].uuid==getSelect} setSelect={setSelect} /> */}

        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(EditControl);
  


  const EditPageStyle = `

  #EditControl {
    width : 100%;
    height: 100%;
    position: absolute;
    z-index : 2;
    outline: none;
  }

  #EditControl #DragArea{
    position: absolute;
    box-sizing: border-box;
    border: 2px solid #222;
    backdrop-filter : grayscale(20%);
    pointer-events: none;
  }
  `;

  