////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../edit/editController/WidgetOverRay'; // 메뉴 헤더

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class Countdown extends Component {
    
    static defaultProps = {
        widgetName: 'Countdown',
      }

    //[임시] 컨텍스트 연결
    static contextType = EditContext;

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
        mode : 'timer',
        play : false,
        remain : this.props.data.value.time,
        time : this.props.data.value.time,
    }

    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }
    
    componentDidMount() {
        if (this.props.parents == 'broadcast'){
            let count = setInterval(this.timer, 1000)
            let now = new Date().getTime()
            this.setState({
                interval: count,
                selectTime : this.props.data.value.time+Math.floor(now/1000),
            },)
        }
    }

    componentWillUnmount() {
        if (this.props.parents == 'broadcast'){
            clearInterval(this.state.interval);
        }
    }

    timer = ()=> {
        let now = new Date().getTime()
        console.log(this.state.selectTime,Math.floor(now/1000))
        this.setState({remain: (this.state.selectTime - Math.floor(now/1000))});
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.data.value.time!=this.state.time){
            this.setState({
                remain : this.props.data.value.time,
                time : this.props.data.value.time,
            })
        }
        // console.log(this.props.time)
    }

    timerView = () => {
        let now = new Date().getTime()
        let count = this.state.remain
        if ( this.props.data.value.endTime) {
            count =  this.props.data.value.endTime-Math.floor(now/1000)
        }
        let text = []
        // 1시간 이상 시간남음
        if (count>3600){
            let hrs = Math.floor(count/3600)
           text.push(<span>{hrs}:</span>) 
        }
        // 1분 이상 시간남음
        if (count>=60){
            let min = Math.floor(count%3600/60)
            if (min<10) {min= '0'+min}
            text.push(<span>{min}:</span>) 
        }
        else {
            text.push(<span>00:</span>) 
        }
        // 1분 이하 시간남음
        if (count>0){
            let sec = Math.floor(count%60)
            if (sec<10) {sec= '0'+sec}
            text.push(<span>{sec}</span>) 
        }
        // 0초
        else if (count==0){
            text.push(<span>00</span>) 
        }
        // 남은시간 음수
        else {
            text.push(<span>00</span>) 
        }
        return text

    }
    
    render() {
        let Style = Model.widget(this.props)
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {

            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false
                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                            <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                                onKeyDown={(e)=>Widget.keyDown(e,this)}
                                onClick={(e)=> Widget.isfocus(e,this)} 
                                onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                                //드레그 이벤트	
                                onDragOver={(e)=>Widget.alt_drag(e,this)} 
                                onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                                onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                                onDrag={(e)=>e.stopPropagation()} 
                                //드랍 이벤트 
                                onDrop={(e)=> Widget.drop(e,this)} 
                                onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                                onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                            >
                            <p className ="Timer" style={Style.object} ref={this.widget} > {this.timerView()} </p>   
                                {/* <div className='overRay'/> */}
                                { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'} dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                            </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragOver={(e)=> Widget.dragOver(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                        <p className ="Timer" style={Style.object} ref={this.widget} > {this.timerView()} </p>   
                            {/* <div className='overRay'/> */}
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'}/> :"" } 
                        </div>
                    );
                }

            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                       <p className ="Timer" style={Style.object} > {this.timerView()} </p>   
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)} style={Style.layout} >
                       <p className ="Timer" style={Style.object} > {this.timerView()} </p>   
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}


  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(Countdown);
  