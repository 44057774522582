////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 위젯 
import MiniView from "components/viewer/MiniView"
import ViewList from "components/lobby/space/wrap/ViewList"
import OverlapList from "components/lobby/space/wrap/OverlapList"
import OverlapCreate from "components/lobby/space/module/OverlapCreateType"
import ViewCreate from "components/lobby/space/wrap/ViewCreate"

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceBox(props) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [overlapList, setList] = useState(null);
    const [select, setSelect] = useState(null);
    const [boxState, setboxState] = useState('list');
    const [windowSize, setWindowSize] = useState(null);
    // rel 
    // const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    //   useEffect(() => {
 
    //     getOverlapList() 
    //     function handleResize() {
    //       setWindowSize(SpaceRef?.current?.clientWidth);
    //     }
    //     window.addEventListener('resize', handleResize);
    
    //     return () => window.removeEventListener('resize', handleResize);
    //   }, []);

      useEffect(() => {
        console.log('componentDidMount[Function]',props);
    }, []);
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

      function BoxModule() {
          
        switch (boxState) {
            case 'list':
                return <OverlapList spaceId={props.spaceId}/>
            case 'broadcast':
                return <ViewList spaceId={props.spaceId}/>
            case 'info':
                return <OverlapList spaceId={props.spaceId}/>
            case 'overlapCreate':                
                return <OverlapCreate spaceId={props.spaceId}/>
            case 'viewCreate':                
                return <ViewCreate spaceId={props.spaceId}/>
        
            default:
                break;
        }
      }

      function BoxButton() {
        console.log('boxState',boxState);
        switch (boxState) {
            case 'list':
                return  (
                <button className='createOverlap' onClick={() =>setboxState('overlapCreate')}>
                    <img className='icon'  src={logoW}/>
                    <p> 오버랩 만들기 </p>
                </button>
                )
            case 'broadcast':
                return(
                <button className='createView' onClick={() =>setboxState('viewCreate')}>
                    <img className='icon'  src={logoW}/>
                    <p> 뷰 만들기 </p>
                </button>
                )
            case 'info':
                return ''
            case 'create':                
                return ''
        
            default:
                break;
        }
      }



    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        
        <div className = "spaceInfo" >
            <header>
                <div className = "spaceTitle" >
                {props.name}
                </div>
                <div>
                    <div className = "spaceShare" >
                        <div className = "shareProfile" >
                    
                        </div>
                    </div>
                    <nav className = "spaceNav" >
                        <button>
                            <span className="material-symbols-outlined iconfont icon"> add_link </span>
                        </button>
                    </nav>
                    
                </div>

            </header>
            
  
            <div className = "spaceMain" >
                
                <section className = "spaceContent" >
                    <nav className = "spaceContentNav" >
                        <div className = "leftSideNav">
                        <button className={boxState == 'list' || boxState == 'create'?'select':''} onClick={() =>setboxState('list')}>
                            <img className='icon'  src={logoW}/>
                            <p>
                                오버랩 설정
                            </p>
                        </button>
                        <button className={boxState == 'broadcast'?'select':''} onClick={() =>setboxState('broadcast')}>
                            <svg className='icon' ><circle cx="10" cy="10" r="8" fill="gray" stroke="#fff" stroke-width="3"></circle></svg>
                            <p>
                                방송 설정
                            </p>
                        </button>
                        <button className={boxState == 'info'?'select':''} onClick={() =>setboxState('info')}>
                            <span className="material-symbols-outlined iconfont icon"> info </span>
                            <p>
                                스페이스 정보
                            </p>
                        </button>
                        <button className={boxState == 'share'?'select':''} onClick={() =>setboxState('share')}>
                            <span className="material-symbols-outlined iconfont icon"> share </span>
                            <p>
                                공유
                            </p>
                        </button>
                        </div>
                        <div className = "rightsideNav">
                            {BoxButton()}
                        </div>
                       
                        {/* <button>
                            파일만
                        </button>
                        <button>
                            미리보기
                        </button>
                        <button>
                            활성화
                        </button> */}
                    </nav>  
                    <div className = "spaceSelect">
                    {BoxModule()}
                    </div>
               
                   
                </section>
        
            </div>
            
        
        </div>
        
    );
  }

  export default SpaceBox;

  