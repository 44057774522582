////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// 라이브러리 

// [리덕스]스토어 연결

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결

import ImageButtonModule from 'components/edit/editModule/ImageButtonModule' ; 

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import { dragTransparent } from 'service/event/common' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/**
 * TabDebug 디버그용 탭입니다.


 */

function TabDebug() {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);

    // state
    // const [PageTitle, setPageTitle] = useState(page?.page_title);


    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
    
      

      
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    // const addPage = () =>{
    //   context.addPage();
    // }


    const getWidget = () =>{
        // context.selectWidget
    }



    console.log('overlap',context.selectWidget,JSON.stringify(context.overlap))
    return (
      <Fragment>
      <style jsx>{`${EditPageStyle}`}</style>
        <div id="TapDebug">

        {Editor.WidgetEditTitle(null,{title:'위젯 정보',toggle:false,icon:'indeterminate_question_box'})}

        <article className="EditPart MediaSourceModule" >
            <div  className="widgetUUID">
            선택된 위젯 : <span className='uuid'>{context.selectWidget?context.selectWidget:'없음'}</span>
            </div>
        
        </article>
        {Editor.WidgetEditTitle(null,{title:'페이지 오브젝트 데이터',toggle:false,icon:'code_blocks'})}
        <textarea className='JsonWidgetData' value={JSON.stringify(context.nowPage()?.page_widget??null)}></textarea>

        {Editor.WidgetEditTitle(null,{title:'해당 페이지 초기화',toggle:false,icon:'scan_delete'})}
        
        <article className="EditPart MediaSourceModule" >
        <ImageButtonModule value={'debugOption'} type ="pattern_blur" data={[{val:20,icon:'disabled_by_default',text:'text',title:"삭제"}]} title="삭제"  change={(e,val)=>context.modifyPage({'page_widget':[]},'pageClean')}/>        
        </article>

        
        </div>



      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(TabDebug);
  


  const EditPageStyle = `

  #TapDebug{
    flex: 1 1;
    padding: 0px 5%;
  }

  #PageNav .PageButton{
    width: 30px;
    height: 30px;
    margin : 10px 10px 0px 10px;
    background : #2C2C2C;
    border-radius : 5px;
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.2), /* 상대적으로 가까운 그림자 */
        0 1px 3px rgba(0, 0, 0, 0.1); /* 멀리 떨어진 더 퍼진 그림자 */
    transition: transform 0.3s ease, box-shadow 0.3s ease;    
    overflow : hidden; 
  }

  .PageButton:hover {
    transform: translateY(-2px); /* 버튼을 위로 약간 올림 */
    
    /* 호버시 그림자 변경 */
    box-shadow: 
        0 6px 8px rgba(0, 0, 0, 0.25),
        0 2px 4px rgba(0, 0, 0, 0.15);
}

/* 위젯정보 위젯 아이디*/
#TapDebug.widgetUUID{
    
}
#TapDebug .widgetUUID .uuid{
    font-size : 14px;
}   


#TapDebug .JsonWidgetData{
    height: 300px;
}

  `; 

  