
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 

// 설정 파일

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import * as EnCoder from 'service/engine/EnCoder' ; 
import { withRouter } from 'react-router-dom';

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////

////////////////////////////////////////////////////////
/** login
 * @param authData 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function singin_google(authData,props) {

    let response =  await Rest.request('/auth/google','Post',authData);

    if(response?.status == 200){
        await sessionStorage.setItem("JWT",response.data.accessToken);
        window.location.replace('/lobby');
        return true;
    }else{
        props.history.push('/');
    }
    return false ;
}


////////////////////////////////////////////////////////
/** getAccount
 * @param authData 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function getAccount(props) {

    let response =  await Rest.request('/account/info','Post',null,sessionStorage.getItem("JWT"));
    console.log('전달완료')
    if(response?.status == 200){
        console.log('response',response)
        if(response?.data?.data.profile == null){
            // props.history.goBack();

            // 스플레쉬 화면을 하나 만드는게 좋을려나 ? 
        }
        return false;
    }
    return;
}

////////////////////////////////////////////////////////
/** getGoogleUserInfo
 * @param accessToken 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function getGoogleUserInfo() {
    let response =  await Rest.request('/account/getGoogleUserInfo','Post',null,sessionStorage.getItem("JWT"));
    return response?.data?.data;
}

////////////////////////////////////////////////////////
/** setProfile
 * @param profileData 프로필 데이터
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function setProfile(profileData) {
    let response =  await Rest.request('/profile/set','Post',profileData,sessionStorage.getItem("JWT"));
    return response?.data?.data;
}

////////////////////////////////////////////////////////
/** getProfile
 * @param accessToken 구글 Auth 를 통해서 받은 정보
 * @returns 오버랩 계정의 토큰
 */
////////////////////////////////////////////////////////

export async function getProfile() {
    let response =  await Rest.request('/profile','get',null,sessionStorage.getItem("JWT"));
    return response?.data?.data;
}
