////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";


// SASS&CSS 연결
import "sass/widget.scss"
import "./preset/BackgroundPatternDesignPreset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/BackgroundPatternDesignPreset';

// 서비스 연결
import * as Event from '../../service/event/Actions' ; 
import * as Widget from '../../service/event/widget' ; 
import * as Model from '../../service/value/Model' ; 
import * as EnCoder from '../../service/engine/EnCoder' ;
import * as Utility from '../../service/other/Utility' ;  
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/** BackgroundPatternDesign | 패턴 배경을 만드는 위젯
 * @returns 오버랩 위젯 컴포넌트를 리턴합니다.
 *  */ 
class BackgroundPatternDesign extends Component {

    //[임시] 컨텍스트 연결
    static contextType = EditContext;
    
    static defaultProps = {
        widgetName: 'BackgroundPatternDesign',
      }

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
        polling : 0,
    }
    
    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }

    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }

    // componentDidMount() {
    //     if(this.props.parents != 'view'){
    //     // AJAX 통신 마운트
    //     // 내부 카운터 마운트
    //     let polling = setInterval(this.polling, 1000)
    //     this.setState({
    //         pollingInterval : polling,
    //     },)
    //     }
    // }

    // componentWillUnmount() {
    //     if(this.props.parents != 'view'){
    //         // 내부 카운터 언마운트
    //         clearInterval(this.state.pollingInterval);
    //     }
    // }
    // polling = () =>{
    //     this.setState({
    //         polling : this.state.polling +1
    //     })
    // }

    
    render() {
        const Style = Model.widget(this.props)

        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        switch (this.props.parents) {
    
            case 'edit':
                return (         
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} >
                        {Preset.data(this,Style)}
                    </div>
                );
            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} >
                        {Preset.data(this,Style)}
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} >
                        {Preset.data(this,Style,'view')}
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(BackgroundPatternDesign);
  