////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import ViewInfo from "components/lobby/space/module/ViewInfo"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as overlap from 'service/api/overlap';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceGuide({ GuideTitle, GuideText, GuideImageComponet=null, GuideIcon='info', IconColor='#444' }) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [ViewList, setList] = useState(null);
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
    
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    // const getViewList = async () => {
    //     let viewComponent = []
    //     let viewList = []
    //     viewList = await view.list(props.spaceId);
    //     console.log('viewList',viewList);
    //     viewComponent = viewList.map((view,index) => (
    //     <ViewInfo 
    //         key={view.uuid} 
    //         index={index} 
    //         setSelect={props.setSelect} 
    //         spaceId={props.spaceId}
    //         data={view}/>));    
    //     setList(viewComponent);
    // }
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////



    return (
        <div className='SpaceModuleContainer SpaceGuideContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            <div className='SpaceGuide'>
                <div className='GuideImage' style={{backgroundColor: IconColor}}>
                    {GuideImageComponet ? GuideImageComponet : <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} className="material-symbols-outlined iconfont infoIcon">{GuideIcon}</span>  }
                    {/* <span style={{fontSize:'20px !important',lineHeight:'25px !important'}} className="material-symbols-outlined iconfont infoIcon">info</span> */}
                </div>
                <div className='GuideInfo'>
                    <h3 className='GuideTitle'>
                       {GuideTitle}
                    </h3>
                    <p className='GuideText'>
                        {GuideText}
                    </p>

                </div>
            </div>
            <div className='SpaceButton'>
           

            </div>
        </div>
    );
  }

  export default SpaceGuide;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .SpaceGuideContainer {

    }

`;

const ContainerStyle = `
    .SpaceGuideContainer {
        margin-bottom: 10px;
        display:flex;
        
    }

    .SpaceGuideContainer .SpaceGuide {
        display:flex;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 7px 0px;

        border-radius: 100px;
   
        background-color: #2A2A2A;
    }

    .SpaceGuideContainer .GuideInfo{
        width: 250px;
        height: 50px;

        display: flex;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        padding : 0px 10px
    }
    .SpaceGuideContainer .GuideImage{
        width: 50px;
        height: 50px;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        background-color: #444;
        border-radius: 50px;
    }
    .SpaceGuideContainer .GuideTitle{
        font-size : 15px;
        
    }
    .SpaceGuideContainer .GuideText{
        font-size : 12px;
    }
   
`;