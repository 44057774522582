////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 

// [프로바이더]
// import EditProvider from 'service/provider/EditProvider';

// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import VarTeam from 'components/edit/editVariable/varTeam/VarTeam';
import VarTeamPlayer from 'components/edit/editVariable/varTeam/VarTeamPlayer';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendTeamArea({context,panelHeight,panelWidth,listOption={}}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    const [SelectTeam, setSelectTeam] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다
        console.log('SelectTeam',SelectTeam)
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [SelectTeam]);

    
      const modify = (key,value) => {
         
        const Team = Utility.deepCopy(SelectTeam)
        Team[key] = value;
        setSelectTeam(Team)

      }




      function getRandomColor(colors) {

      }
      const update = () => {
        const TeamData = Utility.deepCopy(context.overlap?.global?.variable?.team??[])
        const TargetIndex = TeamData.findIndex(team => team.id === SelectTeam?.id);
        if(TargetIndex == -1){return;}
        TeamData[TargetIndex] = SelectTeam;
        context.setGlobalVariable('team',TeamData)
      }

      const playerAdd = () => {
        const Team = Utility.deepCopy(SelectTeam)
        const Player = Team?.player??[]
        Player.push({name:'',image:''})
        Team['player'] = Player;
        console.log('팀',Team)
        setSelectTeam(Team)
        update()
      }
      
      const playerDel = (index) => {
        const Team = Utility.deepCopy(SelectTeam)
        const Player = Team?.player??[]
        Player.splice(index, 1); 
        Team['player'] = Player;
        setSelectTeam(Team)
        update()
      }

      const playerModify = (index,key,value) => {
        const Team = Utility.deepCopy(SelectTeam)
        const Player = Team?.player
        console.log('Player',Player);
        if(!Player[index]){
          return
        }
        Player[index][key] = value;
        Team['player'] = Player;
        console.log('Player',Player);
        setSelectTeam(Team)
      }

      

      /**
       * 팀을 추가합니다.
       */
      const add = () => {
        const TeamData = Utility.deepCopy(context.overlap?.global?.variable?.team??[])
        const NewTeamID = Utility.uuidMake();
        const randomIndex = Math.floor(Math.random() * modernColors.length);
        TeamData.push({id: NewTeamID,teamName:"",teamColor:modernColors[randomIndex],teamFontColor:"#ffffff"})

        context.setGlobalVariable('team',TeamData)
      }

      const remove = (id) => {
        const TeamData = Utility.deepCopy(context.overlap?.global?.variable?.team??[])

        const OtherTeam = TeamData.filter(team => team.id != id);

        context.setGlobalVariable('team',OtherTeam)
        setSelectTeam(null)
      }
      

      /**
       * 팀원 정보 드레그시 순서를 변경해주는 펑션
       * @param {*} order  ReactSortable 에서 반환받는 리스트 목록 팀원 배열
       */
      const dragPlayerData = (order) =>{
        const Team = Utility.deepCopy(SelectTeam)
        order.forEach(obj => {
          delete obj?.chosen;
          delete obj?.selected
        });
        Team.player = order
        setSelectTeam(Team)
        update()
      }

      /**
       * 팀원 정보 드레그시 순서를 변경해주는 펑션
       * @param {*} order  ReactSortable 에서 반환받는 리스트 목록 팀원 배열
       */
        const dragData = (order) =>{
          order.forEach(obj => {
            delete obj?.chosen;
            delete obj?.selected
          });
          context.setGlobalVariable('team',order)
        }
  
      

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const TeamData = context.overlap?.global?.variable?.team??[];

    const TeamList = TeamData.map(
      (data,index) => (<VarTeam target={data} index={index} key={data.id} selectTeam={SelectTeam?.id == data?.id} select={setSelectTeam} remove={remove}/> ) 
    );  

    const PlayerData = SelectTeam?.player??[];
    const Player = PlayerData.map(
      (data,index) => ( <VarTeamPlayer player={data} index={index} key={index} modify={playerModify} del={playerDel} update={update}/>)
    )


    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section id = 'AppendTeamArea'>
                  <div id = 'TeamList'>
                    <div id = 'TeamAppendNav'>
                      <div id = 'TeamAppendButton' onClick={()=>add()}>
                      {Editor.icon({icon:'group_add',size:30,lineHeight:50})}
                      &nbsp;
                      팀 추가
                      </div>
                    </div>
                    <div className='dummyArea'/>

                    <ReactSortable
                      className="TeamPlayerList"
                      list={TeamData}
                      animation = { 200 }
                      // handle = '.handle' 
                      delayOnTouchStart = { true } 
                      setList={(modifyOrder) => 
                        dragData(modifyOrder)
                      }
                    >
                      {TeamList}
                    </ReactSortable>

                  </div>
                  {SelectTeam!=null? 
                  <div id = 'TeamEditArea'>
                    <div className='TeamBasicTitle' >
                    <h2> 팀 정보 </h2>
        
                    </div>
 
                    <div className='TeamBasic' >
                      <div  className='TeamImagePreview' >
                        <img src={!SelectTeam?.teamLogo?logoW :SelectTeam?.teamLogo }/>
                      </div>
                      <div  className='TeamBasicData' >
                        <input className='TeamNameInput' placeholder='팀 이름 입력' value={SelectTeam?.teamName??''} onChange={(e)=>modify('teamName',e.target.value)} onBlur={()=>update()} />

                        <div className='TeamColor'>
                        <small>팀 색상</small> <input className='TeamColorInput'value={SelectTeam?.teamColor} type='color'  onChange={(e)=>modify('teamColor',e.target.value)} onBlur={()=>update()}  />
                        </div>
                        <div className='TeamColor'>
                        <small>팀 폰트 색상</small> <input className='TeamColorInput' value={SelectTeam?.teamFontColor} type='color'  onChange={(e)=>modify('teamFontColor',e.target.value)} onBlur={()=>update()}  />
                        </div>
                        
                      </div>
                      <div  className='TeamBasicData' >

                        <div className='TeamNumber'>
                        <small>메인 스코어</small> <input className='TeamNumberInput'value={SelectTeam?.teamScore??0} type='number'  onChange={(e)=>modify('teamScore',e.target.value)} onBlur={()=>update()}  />
                        </div>
                        <div className='TeamNumber'>
                        <small>서브 스코어</small> <input className='TeamNumberInput'value={SelectTeam?.teamSubScore??0} type='number'  onChange={(e)=>modify('teamSubScore',e.target.value)} onBlur={()=>update()}  />
                        </div>
                      </div>
           
                    </div>
                    
                              
                    <div className='TeamOptions'>
                      <div className='OptionKey'>
                      로고 이미지 주소
                      </div>
                      <div className='OptionValue'>
                      <textarea className='TeamLogoInput InputURL' value={SelectTeam?.teamLogo??''} onChange={(e)=>modify('teamLogo',e.target.value)} onBlur={()=>update()} ></textarea>
                      </div>
                    </div>


                    <div className='TeamTitle' >
                    <h2> 팀원 정보 </h2>
                      <div className='buttonList'>
                        <button onClick={()=>playerAdd()}>
                          {Editor.icon({icon:'person_add',size:20,lineHeight:30})}
                        </button>
                      </div>
                    </div>
 
                    <ReactSortable
                      className="TeamPlayerList"
                      list={PlayerData}
                      animation = { 200 }
                      // handle = '.handle' 
                      delayOnTouchStart = { true } 
                      setList={(modifyOrder) => 
                        dragPlayerData(modifyOrder)
                      }
                    >
                      {Player}
                    </ReactSortable>
     
                  </div>
                  :<div id = 'TeamEditArea'>

                    <div id = 'TeamEditAreaInfo'>

                      {/* <div className='infoTitle'>
                      {Editor.icon({icon:'function',size:30,lineHeight:50})}
                      <span className='varName'>
                        Team
                      </span>
                      </div> */}
                      {Editor.icon({icon:'group',size:150,lineHeight:200})}
                      <div className='infoText'>
                        <p>
                        오버랩 프로젝트 내에서 사용할 수 있는 팀을 설정합니다.<br/>이렇게 생성된 팀은 오버랩 내 여러 위젯에서 불러와 사용할 수 있습니다.
                        </p>
                      </div>

                    </div>

                    
                  </div>}
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendTeamArea);
  


  const EditPageStyle = `

  #AppendTeamArea {
    display: flex;
    flex : 1 ;
    overflow: auto;
  }
  #AppendTeamArea #TeamList{
    width: 240px;
    height: 100%;
    padding: 0px 20px;
    overflow: auto;
  }
  #AppendTeamArea #TeamAppendNav{
    width: 240px;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    box-shadow: 1px 1px 5px #1c1c1c;
    background-color: #444;
    margin: 10px 0px 0px 0px;
    border-radius: 50px;
    z-index: 2;
    position: absolute;
  }
  #AppendTeamArea #TeamAppendNav #TeamAppendButton{
    width: 240px;
    height: 50px;
    font-size : 20px;
    display: flex;
    justify-content: center;
  }
  #AppendTeamArea .dummyArea{
    width: 240px;
    height: 60px;
  }

  #AppendTeamArea #TeamEditArea{
    width: calc(100% - 320px);
    background-color: #222;
    padding : 0px 20px; 
    height: 100%;
    overflow: auto;

  }

  #AppendTeamArea #TeamEditArea .TeamBasicTitle{
    display :flex;
    align-items: center;

  }

  #AppendTeamArea #TeamEditArea #TeamEditAreaInfo{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #AppendTeamArea #TeamEditArea #TeamEditAreaInfo .infoText{
    text-align: center;
    padding : 20px;
    font-size: 15px;
    line-height: 20px;
    border-radius : 10px;
    background-color: #444;
  }

  #AppendTeamArea #TeamEditArea #TeamEditAreaInfo .infoTitle{ 
    display: flex;
    background-color: #444;
    padding : 0px 10px;
    border-radius : 10px;
  }

  #AppendTeamArea #TeamEditArea #TeamEditAreaInfo .infoTitle .varName{ 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left : 5px;
    font-size: 20px;
  }

  #AppendTeamArea #TeamEditArea .TeamImagePreview{
    width: 120px;
    height: 120px;
    margin-right : 20px;
    margin-bottom : 20px;
    background-color: #444;
    border-radius : 10px;
    overflow:hidden;
  }

  #AppendTeamArea #TeamEditArea .TeamImagePreview img{
    display : flex;
    width: 100%;
    height: 100%;
    object-fit: scale-down;

  }

  #AppendTeamArea .TeamTitle{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    height: 50px;
  }
  #AppendTeamArea .TeamTitle h2{
    height: 30px;
    margin : 10px 0px;
  }
  #AppendTeamArea .TeamTitle .buttonList{
    height: 50px;
    display: flex;
  }
  #AppendTeamArea .TeamTitle .buttonList button{
    height: 30px;
    width: 30px;
    margin : 10px;
    padding : 0px;
    border-radius : 40px;
    background-color: #444444;
  }
  #AppendTeamArea .TeamBasic{
    margin : 10px 10px;
    display: flex;
    flex-wrap: wrap;
  }
  #AppendTeamArea .TeamBasic .TeamBasicData{
    width: 227.5px;
  }

  #AppendTeamArea .TeamBasic .TeamNameInput{
    width: 200px;
    height: 30px;
    line-height : 30px;
    background-color: #444444;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    border: none;
    font-family: '프리텐다드SemiBold';
    font-size: 20px;
    margin-left: 7.5px;
  }
  #AppendTeamArea .TeamBasic .TeamNumber{
    margin :  0px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

  #AppendTeamArea .TeamBasic .TeamNumberInput{
    width: 40px;
    height: 15px;
    line-height : 15px;
    background-color: #444444;
    padding: 10px;
    border-radius: 5px;
    
    color: #fff;
    border: none;
    font-family: '프리텐다드SemiBold';
    font-size: 20px;
    margin-left: 7.5px;
  }
  
  #AppendTeamArea .TeamBasic .TeamColorInput{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #727272;
    padding: 0;
    margin : 5px 0px;
    cursor: pointer;
    -webkit-appearance: none;
    overflow: hidden;
  }
  #AppendTeamArea .TeamBasic .TeamColor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    margin: 5px 7.5px;
  }

  #AppendTeamArea .TeamBasic .TeamColor input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    width: 20px;
    height: 20px;
  }

  #AppendTeamArea .TeamBasic .TeamColor input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
  }

  .TeamOptions{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .TeamOptions .OptionKey{
    width: 150px;
  }

  .TeamOptions .OptionValue{
    width: calc(100% - 150px);
  }

  .TeamOptions .OptionValue .InputURL{
    width: calc(100% - 150px);
    height: 30px;
    line-height: 15px;
    background-color: #444444;
    padding: 5px;
    border-radius: 5px;
    color: #fff;
    border: none;
    font-family: '프리텐다드Regular';
    font-size: 15px;
  }
  .TeamPlayerList{
    display:flex;
    flex-wrap: wrap;
  }
  .TeamPlayerList .PlayerData{
    margin : 10px 10px;
    width: 400px;
    height: 130px;
    display:flex;
    flex-direction: column;
    min-height: 50px;
    display: flex;
    background-color: #444444;
    border-radius: 5px;
  }

  #AppendTeamArea .PlayerData .PlayerHeader{
    height: 50px;
    display:flex;
    justify-content: space-between;
  }

  #AppendTeamArea .PlayerData .PlayerLeft{
    width: 120px;
    height: 120px;
    display:flex;
  }

  #AppendTeamArea .PlayerData .PlayerRight{
    width: 280px;
    height: 120px;
    display:flex;
    flex-direction: column;
  }
  #AppendTeamArea .PlayerData .PlayerImage{
    display:flex;
    margin : 10px;
    height: 100px;
    width: 100px;
    border-radius: 5px;
    padding: 5px;
    background-color: #222;
  }
  #AppendTeamArea .PlayerData .PlayerImage img{
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 5px;
  }

  #AppendTeamArea .PlayerData .PlayerContent{
    width: 100%;

    display: flex;
    margin-top: 5px;
    background-color: #444444;
    border-radius: 5px;
  }

  #AppendTeamArea .PlayerData .PlayerField{
    display: flex;
    width: 240px;
    height: 60px;
  }
  #AppendTeamArea .PlayerData .PlayerDel{
    display: flex;
    width: 40px;
    height: 40px;
  }


  #AppendTeamArea .PlayerData .PlayerNameInput{
    display:flex;
    width: 200px;
    height: 30px;
    margin : 10px 10px 10px 0px;
    line-height : 30px;
    background-color: #444444;
    padding: 5px 10px;
    color: #fff;
    border: none;
    font-family: '프리텐다드SemiBold';
    font-size: 20px;
    margin-left: 7.5px;
  }

  #AppendTeamArea .PlayerData .PlayerImageInput{
    resize: none;
    display:flex;
    width: 240px;
    height: 30px;
    margin : 10px 10px 10px 0px;
    line-height : 10px;
    background-color: #444444;
    padding: 5px 10px;
    color: #fff;
    border: none;
    font-family: '프리텐다드SemiBold';
    font-size: 10px;
    margin-left: 7.5px;
  }


  `;
  

  const modernColors = [
  "#e4002b", // PANTONE 17-1564
  "#aa8a00", // PANTONE 18-1764
  "#0ec7b5", // PANTONE 18-1550
  "#e73312", // PANTONE 18-1945
  "#5a8dff", // PANTONE 19-3540
  "#00492b", // PANTONE 19-3642
  "#FF0A07", // PANTONE 19-4052
  "#de2027", // PANTONE 18-4244
  "#0277BD", // PANTONE 18-4250
  "#FFC900", // PANTONE 18-4728
  "#ff6b01", // PANTONE 18-5624
  "#b4936a", // PANTONE 17-6153
  "#ff3e24", // PANTONE 18-0538
  "#b69265", // PANTONE 16-0737
  "#C8102E", // PANTONE 14-0957
  "#dc0e27", // PANTONE 15-1046
  "#221814", // PANTONE 16-1356
  "#23aee5", // PANTONE 18-1451
  "#b04ec4", // PANTONE 18-1449
  "#231f20", // PANTONE 19-1317
  "#d4d7dc", // PANTONE 19-0201
  "#212021", // PANTONE 19-4019
  "#231815", // PANTONE 19-4008
  "#808080", // PANTONE 18-1655
  "#0a3091", // PANTONE 19-2047
  "#216ce2", // PANTONE 19-3542
  ];