// 서비스 연결
import { forEach, forIn, map } from 'lodash';
import { style } from 'service/model/widget/UnknownModel';
import * as Utility from 'service/other/Utility'; 

////////////////////////////////////////////////////////
// WidgetEditTitle (components,option)
// 에딧페이지의 위젯내 에디트 타이틀을 생성해줍니다.
// 이는 state 안에 value값을 보관하고 있는경우 value 값을 갱신해줍니다.
////////////////////////////////////////////////////////

/**
 * ContentEditable 을 통해서 만들어진 ROWHTML을 오버랩에서 사용하는 format:TEXTV2 으로 변경합니다.
 * @param {*} html ContentEditable 를 통해서 받은 ROWHTML
 * @param {*} beforeJson 위젯이 기존에 가지고 있는 JSON 값 *스타일 분석을 위해 필요
 * @param {*} DefaultStyle 스타일 지정이 안되면 기본으로 가지고 있을 스타일값
 * @param {*} option 
 * @returns 
 */
export function encoder(html,beforeJson,DefaultStyle,option) {

  console.log('기본데이터 option',option);
  // console.log('스타일키 html : ',html)
  // console.log('스타일키 beforeJson 2 : ',beforeJson, DefaultStyle )

  const rowHtml = Utility.deepCopy(html)
  const div = document.createElement('div');
  const div2 = document.createElement('div');
  div.innerHTML = rowHtml;
  div2.innerHTML =  Utility.deepCopy(rowHtml);

  const plainText = convertHtml2(div2);

  console.log('기본데이터 plainText',plainText)

  const AfterJson = []
  for (const char of plainText) {

    if (char.styleKey==null) {
      char.style = DefaultStyle
      AfterJson.push(char)
    }
    else{
      function getStyleByKey(styleKey) {
        if(beforeJson){
          const PrevText = beforeJson.find(char => char.styleKey === styleKey);
          return PrevText ? PrevText.style : DefaultStyle;
        }
      }
      char.style = getStyleByKey(char.styleKey)
      AfterJson.push(char)
      console.error(`[textEditorService] encoder : styleKey 키가 NULL 또는 number가 아닙니다 키 값 "${plainText.styleKey}" `)
    }
  }
    // key중복을 바꿉니다.
    const keysSet = new Set();
    for (const index in AfterJson) {
      if (keysSet.has(AfterJson[index]?.styleKey)) {
        /* 문자열에 스타일키가 중복된 경우 */
        AfterJson[index]['styleKey'] = Utility.uuidMake(); 
      } else {
        if(AfterJson[index]?.styleKey){
          /* 문자열에 스타일키가 중복되지 않은경우 */
          keysSet.add(AfterJson[index].styleKey);
        }else{
          /* 문자열에 스타일키가 없는경우 */
          AfterJson[index]['styleKey'] = Utility.uuidMake(); 
        }
      }
    }
  
    // 최근 스타일변경을 했다면 그것을 반영합니다.
    if(option?.styleModify){

      const LastValue = beforeJson[beforeJson.length - 1];

      let targetIndex = AfterJson.findIndex(obj => obj.styleKey === LastValue.styleKey);
      console.log('LastValue 2 ',LastValue,targetIndex,AfterJson.length);
      if(targetIndex != -1 || targetIndex < AfterJson.length ){
        for (let index = targetIndex + 1; index < AfterJson.length; index++) {
          console.log('기본 데이터 스타일변경',index,AfterJson)
          AfterJson[index]['style'] = DefaultStyle;
        }
      }
      // AfterJson[AfterJson.length].style = DefaultStyle

    }
  return AfterJson
}

 
export function decoder(words,option) {

  const keysSet = new Set();
  const combinedHTML = words.reduce((acc, text, index) => {
    let styleKey = text?.styleKey ?? Utility.uuidMake();
    if (keysSet.has(styleKey)) {
      styleKey = Utility.uuidMake(); 
    } else {
      keysSet.add(styleKey);
    }
    const color = text.style?.color ?? `rgb(${Math.floor(Math.random() * 120) + 100},${Math.floor(Math.random() * 120) + 100},${Math.floor(Math.random() * 120) + 100})`;
    const size = text.style?.size ?? '15px';
    const content = text.char === '\n' ? "<br/>" : text.char;
    return `${acc}<span data-key=${styleKey} style="color: ${color}; font-size: ${size};">${content}</span>`;
  }, '');
  return combinedHTML;
}

/**
 * 
 * HTML을 JSON으로 바꾼다.
 *
 */

const convertHtml2 = (html,key=null) => {
  let res = [];
  let lastKey = null; // 마지막으로 사용한 스타일키가 담깁니다 신규 텍스트 추가시 앞의 스타일을 가져오기 위함입니다.

  
  console.log('[convertHtml] 시작합니다',html)
  for (const child of html.childNodes) {

    if (child.nodeType === 1) { // Element node
      const StyleKey = child?.getAttribute('data-key')
      
      // 스타일키가 있는 경우 *폼으로 입력한 정상 HTML 태그의 경우
      if(StyleKey!=null){
        for (const node of child.childNodes) {
          lastKey = StyleKey
          switch (node.nodeType) {
            case 3:
              for (const child of node.textContent) {
                res.push({
                  char: child,
                  styleKey:StyleKey,
                 })
              }
              break;

            case 1:
              console.log('[convertHtml] BR')
              res.push({
                char: '\n',
                styleKey:StyleKey,
               })
              break;
          
            default:
              break;
          }
        }
      }else{

        
        // console.log('[convertHtml]예외경우 발생 : ',childConvertHtml(child.childNodes,lastKey))
        const ChildRes = childConvertHtml(child.childNodes,lastKey)
        if(isValidObjectArray(ChildRes)){
          res = [...res,...ChildRes]
        }
      }
    }
    
    // 해당 node가 HTML 태그가 아니고 그냥 텍스트인경우 *빈 텍스트 일때
    else if (child.nodeType === 3){
      console.log('[convertHtml] 자식',child)
      for (let index = 0; index < child.textContent.length; index++) {
        res.push({
          char: child.textContent[index],
          styleKey:null,
         })
      }
    }
  }
  return res;
} 


function isValidObjectArray(arr) {
  // 배열이 아닌 경우 false 반환
  if (!Array.isArray(arr)) {
      return false;
  }

  // 배열의 모든 객체가 char 문자열과 styleKey 문자열 또는 null인지 확인
  return arr.every(obj => 
      typeof obj.char === 'string' && 
      (obj.styleKey === null || typeof obj.styleKey === 'string')
  );
}

const childConvertHtml = (childText,lastKey) => {

  console.log('[convertHtml] childText',childText) 
  let res = [];

  for (const nodeList of childText) {
    // const element = array[index];
    console.log('[convertHtml] childConvertHtml',nodeList) 
    
    if (nodeList.nodeType === 1) { // Element node
      const StyleKey = nodeList?.getAttribute('data-key')
  
      if(StyleKey!=null){
        for (const node of nodeList) {
          switch (node.nodeType) {
            case 3:
              for (const child of node.textContent) {
                res.push({
                  char: child.textContent,
                  styleKey:StyleKey,
                 })
              }
              break;
  
            case 1:
              console.log('[convertHtml] BR')
              res.push({
                char: '\n',
                styleKey:StyleKey,
               })
              break;
          
            default:
              break;
          }
        }
      }else{
        // console.log('[convertHtml] 커버',child) 
        // for (const node of child.childNodes) {
            
        // }
      }
    }  
    else if (nodeList.nodeType === 3){
  
      for (let index = 0; index < nodeList.textContent.length; index++) {
        res.push({
          char: nodeList.textContent[index],
          styleKey:lastKey,
         })
      }
    }

    return res;
  }



  
}


const convertHtml = (node,key=null) => {
  let res = [];
  for (const child of node.childNodes) {

    if (child.nodeType === 1) { // Element node
      const StyleKey = child?.getAttribute('data-key')??key

      if (child.tagName === 'BR' && child.getAttribute('data-key') === 'null') {
        continue;
      }
      if (child.tagName === 'BR') {
        res.push({
          char: '\n',
          styleKey:StyleKey,
         })
      } else if(child?.getAttribute('data-key')!=null){
        // 자식 태그에 대해 엔터 BR인지 확인
        const text = child.textContent;

        const hasSingleBrChild = child?.children?.length === 1 && child?.children?.[0]?.tagName?.toLowerCase() === 'br';
        if(hasSingleBrChild){
          res.push({
            char: '\n',
            styleKey:StyleKey,
            })
            continue;
        }
        for (let index = 0; index < text.length; index++) {
          res.push({
            char: child.textContent[index],
            styleKey:StyleKey,
           })
        }
      } 
      else if (['P', 'DIV'].includes(child.tagName)) {
        res = [...res,...convertHtml(child,StyleKey)]
        res.push({
          char: '\n',
          styleKey:StyleKey,
         })
      } else {
        console.log('[엔코더 테스트] 예외경우',child )
        res = [...res,...convertHtml(child,StyleKey)]
      }
    } else if (child.nodeType === 3) { // Text node

      // 텍스트 그 자체일때 


      // // console.log('child.textContent',child.textContent,child.textContent.length)
      for (let index = 0; index < child.textContent.length; index++) {
        // console.log('child.textContent', index, child.textContent,)

        res.push({
          char: child.textContent[index],
          styleKey:key,
         })
      }
    }
  }
  console.log('child.textContent',res)
  return res;
};
