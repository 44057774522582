////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 위젯 


// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
import SpaceModuleOverlapList from './sm_overlap/SpaceModuleOverlapList';
import SpaceModuleOverlapPreview from './sm_overlap/SpaceModuleOverlapPreview';
import SpaceModuleOverlapDummy from './sm_overlap/SpaceModuleOverlapDummy';
import SpaceGuideList from "components/lobby/space/module/SpaceGuideList"
import OverlapCreateType from "components/lobby/space/module/OverlapCreateType"
import OverlapCreate from "components/lobby/space/module/OverlapCreate"

// 서비스 연결
import * as Space from 'service/api/space' ; 
import * as guide from 'service/event/guide';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SpaceOverlap extends Component {
    
    state = {
        mode : null,
        select : null,
        overlap : null,
        delete : null,
    }

    async componentDidMount () {
        
    }

    constructor(props) {
        super(props);
        this.spaceRef = React.createRef();
      }
      
    componentDidUpdate(prevProps){
        
        if(this.state.mode=='store'){
            this.props.lobbyRoutes({target:'Store'});
        }

        
    }
    setSelect = (id,uuid,overlap)=>{
        if(id == null || uuid == null || uuid == this.state.delete){
            return;
        }
        const select = {id,uuid};
        this.setState({
            select,
            overlap,
            mode : null
        })
    }

    setMode = (mode) => { 
        this.setState({
            mode : mode,
            select : null,
            overlap : null,
        })
    }

    deletePreview = (target) => { 
        this.setState({
            delete : target,
        })

        setTimeout(() => {
            this.setState({
                mode : null,
                select : null,
                overlap : null,
            })
        }, 1000);
    }
    


    render() { 
        switch (this.state.mode) {
            case 'append':
                return (
                    <div className = "Lobby_innerPage" >
                        <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
                            <OverlapCreate spaceId={this.props.space?.id} setMode={this.setMode} />
                        </div>
                    </div>
                )
        
            case 'appendType':
                return (
                    <div className = "Lobby_innerPage" >
                        <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
                            <OverlapCreateType spaceId={this.props.space?.id} setMode={this.setMode} />
                        </div>
                    </div>
                )            
            case 'store':
                return (
                <div>
                    
                </div>
                )
        
            default:
                return (
                    <div className = "Lobby_innerPage" >
                        <div className = "Lobby_common Lobby_SpaceOverlap" ref={this.spaceRef} >
            
                            <div className={this.state.select != null ?'upperSide abled':'upperSide disabled'}>
                            <SpaceGuideList guideList={guide.overlapGuide}  lobbyRoutes={this.props.lobbyRoutes}/>
                            {this.state.select != null ?
                            <SpaceModuleOverlapPreview overlap={this.state.overlap} spaceId={this.props.space?.id} deletePreview={this.deletePreview}/>
                
                            :
                            <SpaceModuleOverlapDummy/>
                            }     
                            </div>      
                            <SpaceModuleOverlapList spaceId={this.props.space?.id} setSelect={this.setSelect} delete={this.state.delete} mode={this.state.mode} setMode={this.setMode}/>
                        
                        </div>
                    </div>
                )
        }
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  // export default Login;
  export default SpaceOverlap;
  