////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, Fragment , useContext, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';

// [리덕스]스토어 연결

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import AppendPanel from 'components/edit/editPanel/append/AppendPanel'; // 상단 메뉴

// 서비스 연결
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function BottomPanelArea({sizeDetector,panelSize}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);

    // state

    // rel 


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);

    const objectDrop = (e) =>{
      e.preventDefault();
      e.stopPropagation();
    }



    // 확장메뉴 사용시 
    const EditorExtend = context.extendEditor==null ? 0 : (panelSize?.EditContainer?.width) - (panelSize?.PreferencesPanel?.width) ??0  ;

    
    const LeftPanel =  (panelSize?.PreferencesPanel?.width??320)+10;

    
    const PanelSize = {
        left: `${LeftPanel + EditorExtend}px`,
        width : `calc( 100vw - ${LeftPanel}px - 40px)`,
  
    }


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "BottomPanelArea" style={PanelSize}>
                <AppendPanel sizeDetector={sizeDetector}/>
   
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(BottomPanelArea);
  


  const EditPageStyle = `

  #BottomPanelArea {
    display: flex;
    flex-direction: column;
    margin : 20px;

    position: absolute;
    animation: bottomPanelAreaStart ease 1s;
    z-index: 5;
    bottom : 0px;
    transition : left 1s ease-in-out;
        /* background-color: red; */
  }
  #BottomPanelArea .marginSpace{
    width: 100%;
    height: 7.5px;
  }
  
  @keyframes bottomPanelAreaStart {
    0% {
      transform : translateY(340px);
    }
    100% {
      transform : translateY(0px);
    }
  }
  `;