// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// Scoreboard | 스코어보드 
// 경기의 점수판을 실시간으로 기록합니다.
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import BanPick from 'components/widget/BanPick';// 컴포넌트
import { forEach } from 'lodash';

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기
// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 


////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = BanPick; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
export const Reference = {
  position: {x: "0", y: "730", show : true},
  size: {width: "1920", height: "350"},
  style : {
    shadowValue: "0", 
    shadowColor: "#000000",
    shadowDirection : "RightBottom",
    morphing: true, 
    fill : "#000000",
    borderRadius : 0,
    objectFit : 'fill',
    clipNode : [],
    ...common.filterDefault,
    ...common.transformDefault,
    
    fontSize : 50,
    FontFamily : '프리텐다드SemiBold',
    PickBoxFontFamily : '프리텐다드SemiBold',

    TimerFill : '#ffffff',
    BenBoxFill : '#ffffff',
    PickBoxFill : '#ffffff',
    LeftTeamFill : '#ffffff',
    rightTeamFill : '#ffffff',

  },
  value: {
    banPickAsset : 'overwatch',
    banPickOption : [],
    banPickRule : [{"team":"right","type":"ban","timer":"30","chosen":false,"selected":false},{"team":"left","type":"pick","timer":"30","chosen":false,"selected":false}],"banPickRule":[{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"left","timer":"30","chosen":false,"selected":false},{"type":"ban","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"left","timer":"30","chosen":false,"selected":false},{"type":"pick","team":"right","timer":"30","chosen":false,"selected":false}],
    teamData : [],
    option: null,
  },
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
  preset : 'default'
}



////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let layout = {}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))
  }
  if(property.includes('size')){
    object = Object.assign(object,common.size({widget:data.size}))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,common.border({widget:data.style}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.filter({widget:data.style}))
    // 모핑
    layout = Object.assign(layout,common.morphing({widget:data.style}))
    object = Object.assign(object,common.morphing({widget:data.style}))
    // 변형
    object = Object.assign(object,common.transform({widget:data.style}))
    // 자르기
    object = Object.assign(object,common.clipPath({widget:data.style}))
    // 이미지정렬
    object = Object.assign(object,common.objectFit({widget:data.style}))
  }
  return {layout,object}
}
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
// export const Preset = null
// 프리셋을 사용하는 위젯의 경우
export const Preset = [
    {name:'default', nameKo:'기본',},
]

////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset=null) {

  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'presetName':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  let output = {}
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }
  return output
}
////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////


export const Asset = [
  {name:'overwatch', nameKo:'오버워치',summary:'오버워치 캐릭터들로 이루워진 에셋입니다.',
    data:[
    {"name": "Ana", "nameKo": "아나", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3429c394716364bbef802180e9763d04812757c205e1b4568bc321772096ed86.png"},
    {"name": "Ashe", "nameKo": "애쉬", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8dc2a024c9b7d95c7141b2ef065590dbc8d9018d12ad15f76b01923986702228.png"},
    {"name": "Baptiste", "nameKo": "바티스트", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f979896f74ba22db2a92a85ae1260124ab0a26665957a624365e0f96e5ac5b5c.png"},
    {"name": "Bastion", "nameKo": "바스티온", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4d715f722c42215072b5dd0240904aaed7b5285df0b2b082d0a7f1865b5ea992.png"},
    {"name": "Brigitte", "nameKo": "브리기테", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/48392820c6976ee1cd8dde13e71df85bf15560083ee5c8658fe7c298095d619a.png"},
    {"name": "Cassidy", "nameKo": "캐서디", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/6cfb48b5597b657c2eafb1277dc5eef4a07eae90c265fcd37ed798189619f0a5.png"},
    {"name": "D.Va", "nameKo": "디바", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/ca114f72193e4d58a85c087e9409242f1a31e808cf4058678b8cbf767c2a9a0a.png"},
    {"name": "Doomfist", "nameKo": "둠피스트", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/13750471c693c1a360eb19d5ace229c8599a729cd961d72ebee0e157657b7d18.png"},
    {"name": "Echo", "nameKo": "에코", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f086bf235cc6b7f138609594218a8385c8e5f6405a39eceb0deb9afb429619fe.png"},
    {"name": "Genji", "nameKo": "겐지", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/4edf5ea6d58c449a2aeb619a3fda9fff36a069dfbe4da8bc5d8ec1c758ddb8dc.png"},
    {"name": "Hanzo", "nameKo": "한조", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/aecd8fa677f0093344fab7ccb7c37516c764df3f5ff339a5a845a030a27ba7e0.png"},
    {"name": "Illari", "nameKo": "일리아리", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5ea986038f9d307bd4613d5e6f2c4c8e7f15f30ceeeabbdd7a06637a38f17e1f.png"},
    {"name": "Junker Queen", "nameKo": "정커퀸", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cef2406b2244b80506f83b8fb9ebaf214f41fa8795cbeef84026cd8018561d04.png"},
    {"name": "Junkrat", "nameKo": "정크랫", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/037e3df083624e5480f8996821287479a375f62b470572a22773da0eaf9441d0.png"},
    {"name": "Kiriko", "nameKo": "키리코", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/088aff2153bdfa426984b1d5c912f6af0ab313f0865a81be0edd114e9a2f79f9.png"},
    {"name": "Lifeweaver", "nameKo": "라이프위버", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/39d4514f1b858bc228035b09d5a74ed41f8eeefc9a0d1873570b216ba04334df.png"},
    {"name": "Lucio", "nameKo": "루시우", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/e2ff2527610a0fbe0c9956f80925123ef3e66c213003e29d37436de30b90e4e1.png"},
    {"name": "Mei", "nameKo": "메이", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1533fcb0ee1d3f9586f84b4067c6f63eca3322c1c661f69bfb41cd9e4f4bcc11.png"},
    {"name": "Mercy", "nameKo": "메르시", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2508ddd39a178d5f6ae993ab43eeb3e7961e5a54a9507e6ae347381193f28943.png"},
    {"name": "Mauga", "nameKo": "마우가", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/9ee3f5a62893091d575ec0a0d66df878597086374202c6fc7da2d63320a7d02e.png"},
    {"name": "Moira", "nameKo": "모이라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/000beeb5606e01497897fa9210dd3b1e78e1159ebfd8afdc9e989047d7d3d08f.png"},
    {"name": "Orisa", "nameKo": "오리사", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71e96294617e81051d120b5d04b491bb1ea40e2933da44d6631aae149aac411d.png"},
    {"name": "Pharah", "nameKo": "파라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/f8261595eca3e43e3b37cadb8161902cc416e38b7e0caa855f4555001156d814.png"},
    {"name": "Ramattra", "nameKo": "라마트라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/3e0367155e1940a24da076c6f1f065aacede88dbc323631491aa0cd5a51e0b66.png"},
    {"name": "Reaper", "nameKo": "리퍼", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/2edb9af69d987bb503cd31f7013ae693640e692b321a73d175957b9e64394f40.png"},
    {"name": "Reinhardt", "nameKo": "라인하르트", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/490d2f79f8547d6e364306af60c8184fb8024b8e55809e4cc501126109981a65.png"},
    {"name": "Roadhog", "nameKo": "로드호그", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/72e02e747b66b61fcbc02d35d350770b3ec7cbaabd0a7ca17c0d82743d43a7e8.png"},
    {"name": "Sigma", "nameKo": "시그마", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/cd7a4c0a0df8924afb2c9f6df864ed040f20250440c36ca2eb634acf6609c5e4.png"},
    {"name": "Sojourn", "nameKo": "소전", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a53bf7ad9d2f33aaf9199a00989f86d4ba1f67c281ba550312c7d96e70fec4ea.png"},
    {"name": "Soldier: 76", "nameKo": "솔저: 76", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/20b4ef00ed05d6dba75df228241ed528df7b6c9556f04c8070bad1e2f89e0ff5.png"},
    {"name": "Sombra", "nameKo": "솜브라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bca8532688f01b071806063b9472f1c0f9fc9c7948e6b59e210006e69cec9022.png"},
    {"name": "Symmetra", "nameKo": "시메트라", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/7f2024c5387c9d76d944a5db021c2774d1e9d7cbf39e9b6a35b364d38ea250ac.png"},
    {"name": "Torbjörn", "nameKo": "토르비욘", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/1309ab1add1cc19189a2c8bc7b1471f88efa1073e9705d2397fdb37d45707d01.png"},
    {"name": "Tracer", "nameKo": "트레이서", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a66413200e934da19540afac965cfe8a2de4ada593d9a52d53108bb28e8bbc9c.png"},
    {"name": "Venture", "nameKo": "벤처", "image": ""},
    {"name": "Widowmaker", "nameKo": "위도우메이커", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/a714f1cb33cc91c6b5b3e89ffe7e325b99e7c89cc8e8feced594f81305147efe.png"},
    {"name": "Winston", "nameKo": "윈스턴", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/bd9c8e634d89488459dfc1aeb21b602fa5c39aa05601a4167682f3a3fed4e0ee.png"},
    {"name": "Wrecking Ball", "nameKo": "레킹볼", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/5c18e39ce567ee8a84078f775b9f76a2ba891de601c059a3d2b46b61ae4afb42.png"},
    {"name": "Zarya", "nameKo": "자리야", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/8819ba85823136640d8eba2af6fd7b19d46b9ee8ab192a4e06f396d1e5231f7a.png"},
    {"name": "Zenyatta", "nameKo": "젠야타", "image": "https://d15f34w2p8l1cc.cloudfront.net/overwatch/71cabc939c577581f66b952f9c70891db779251e8e70f29de3c7bf494edacfe4.png"}
]

},
{name:'leagueOfLegends', nameKo:'리그오브레전드',summary:'롤 캐릭터들로 이루워진 에셋입니다.',
data:[
  { name: 'D.Va', nameKo: '가렌', image: 'https://images.contentstack.io/v3/assets/blt731acb42bb3d1659/blte41a162aed9339b7/5db05fc60b39e86c2f83dc0d/RiotX_ChampionList_garen.jpg?quality=90&width=250', position: 'Tank', summary: '기동성이 뛰어난 메카 조종사 탱커' },
  { name: 'Reinhardt', nameKo: '갈리오', image: 'https://images.contentstack.io/v3/assets/blt731acb42bb3d1659/blt5890d1ab5c8be01f/5db05fc6823d426762825fe5/RiotX_ChampionList_galio.jpg?quality=90&width=250', position: 'Tank', summary: '방패로 동료를 보호하는 중무장 탱커' },
  { name: 'Mercy', nameKo: '갱플랭크', image: 'https://images.contentstack.io/v3/assets/blt731acb42bb3d1659/bltfdff3781ccfb2a5c/5db05fc689fb926b491ed811/RiotX_ChampionList_gangplank.jpg?quality=90&width=250', position: 'Support', summary: '팀원을 치유하고 부활시키는 서포터' },
  { name: 'Lucio', nameKo: '루시우', image: 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/e2ff2527610a0fbe0c9956f80925123ef3e66c213003e29d37436de30b90e4e1.png', position: 'Support', summary: '음악으로 팀을 치유하고 가속하는 서포터' },
  { name: 'Tracer', nameKo: '트레이서', image: 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/a66413200e934da19540afac965cfe8a2de4ada593d9a52d53108bb28e8bbc9c.png', position: 'Damage', summary: '시간을 조종하며 빠르게 움직이는 딜러' },
  { name: 'Genji', nameKo: '겐지', image: 'https://d15f34w2p8l1cc.cloudfront.net/overwatch/4edf5ea6d58c449a2aeb619a3fda9fff36a069dfbe4da8bc5d8ec1c758ddb8dc.png', position: 'Damage', summary: '빠른 기동력과 강력한 근접 공격을 가진 딜러' },
  { name: 'Widowmaker', nameKo: '위도우메이커', image: 'widowmaker', position: 'Damage', summary: '장거리 저격을 통해 적을 제거하는 딜러' },
  { name: 'Hanzo', nameKo: '한조', image: 'hanzo', position: 'Damage', summary: '활을 사용하는 정밀한 사격으로 적을 제압하는 딜러' },
  { name: 'Sigma', nameKo: '시그마', image: 'sigma', position: 'Tank', summary: '중력을 조종하여 전장을 지배하는 탱커' },
  { name: 'Moira', nameKo: '모이라', image: 'moira', position: 'Support', summary: '생명 에너지를 조종하여 치유와 피해를 동시에 주는 서포터' },
  { name: 'Brigitte', nameKo: '브리기테', image: 'brigitte', position: 'Support', summary: '전방에 있는 동료들을 보호하고 치유하는 서포터' },
  { name: 'Ashe', nameKo: '애쉬', image: 'ashe', position: 'Damage', summary: '불을 다루는 무법자로 폭발적인 화력을 가진 딜러' }
]
},
]

