////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widget.scss"
import "./preset/NoticePreset.scss"

// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결
import WidgetOverRay from '../edit/editController/WidgetOverRay'; // 메뉴 헤더
import * as Preset from './preset/NoticePreset';

// 서비스 연결
import * as Event from 'service/event/Actions' ; 
import * as Widget from 'service/event/widget' ; 
import * as Model from 'service/value/Model' ; 
import * as EnCoder from 'service/engine/EnCoder' ; 
import * as Utility from 'service/other/Utility' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/** ADalert | 광고 여부를 표시하는 위젯 
 * @returns 오버랩 위젯 컴포넌트를 리턴합니다.
 *  */ 
class ADalert extends Component {
    
    //[임시] 컨텍스트 연결
    static contextType = EditContext;
    
    static defaultProps = {
        widgetName: 'ADalert',
      }

    state = {
        ...Widget.DefaultState(this),
        moveStartX : 0,
        moveStartY : 0,
    }
    
    constructor(props) {
        super(props);
        this.widget = React.createRef();
    }
    
    DefaultMethod = (type,value) =>{
        Widget.DefaultMethod(this,type,value)
    }

    render() {
        let Style = Model.widget(this.props)
        // 드레그를 통한 위젯 이동
        Style.layout= { ...Style.layout,transform: 'translate('+this.state.dragMoveX+'px, '+this.state.dragMoveY+'px)'}
        let text =''
        let fontSize = (this.props.data.size.height-this.props.data.size.height/5) +'px'
        let lineHeight = (this.props.data.size.height) +'px'
        switch (this.props.data.value.type) {
            case 'AD':
                text ='유료 광고 포함'
                break;
            case 'support':
                text ='제조사로 부터 제품을 제공받음'
                break;
            case 'noneAD':
                text ='무료 광고 포함'
                break;
            case 'other':
                text = this.props.data.value.text
                break;
            default:
    
                break;
        }
        switch (this.props.parents) {
    
            case 'edit':
                // 편집시 화면이동 트렌스레이션 효과 방지
                Style.layout.transition = false
                Style.object.transition = false

                if(this.props.userState.browser== "firefox"&&"safari"){
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트	
                            onDragOver={(e)=>Widget.alt_drag(e,this)} 
                            onDragStart={(e) => Widget.alt_dragStart(e,this)} 
                            onDragEnd={(e) => Widget.alt_dragEnd(e,this)} 
                            //드랍 이벤트 
                            onDrop={(e)=> Widget.drop(e,this)} 
                            onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                            onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                            <p style={{...Style.object,'fontSize':fontSize,'lineHeight':lineHeight}} >{text}</p>
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'} dragLock={this.state.dragLock} DefaultMethod={this.DefaultMethod}/> :"" } 
                        </div>
                    );
                }else{
                    return (         
                        <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} tabIndex={0} draggable="true" 
                            onKeyDown={(e)=>Widget.keyDown(e,this)}
                            onClick={(e)=> Widget.isfocus(e,this)} 
                            onMouseDown={(e)=> Widget.mouseControl(e,this)} 	
                            //드레그 이벤트
                            onDrag={(e)=>Widget.drag(e,this)} 
                            onDragStart={(e) => Widget.dragStart(e,this)} 
                            onDragEnd={(e) => Widget.dragEnd(e,this)} 
                             //드랍 이벤트 
                             onDrop={(e)=> Widget.drop(e,this)} 
                             onDragOver={(e)=> Widget.dragOver(e,this)} 
                             onDragLeave={(e)=> Widget.dragLeave(e,this)} 
                             onDragEnter={(e)=> Widget.dragEnter(e,this)} 
                        >
                            <p style={{...Style.object,'fontSize':fontSize,'lineHeight':lineHeight}} >{text}</p>
                            { this.props.focus ? <WidgetOverRay target={this.props} object={this.widget.current} mode={'8_Direction'} dragLock={this.state.dragLock}/> :"" } 
                        </div>
                    );
                } 
            case 'broadcast':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} >
                        <p style={{...Style.object,'fontSize':fontSize,'lineHeight':lineHeight}} >{text}</p>
                    </div>
                );
          
            case 'view':
                return (
                    <div ref={(ref) => this.object=ref }  className = {this.props.parents + " widgetObject "+this.props.widgetName+Widget.display(this.props)}  style={Style.layout} >
                        <p style={{...Style.object,'fontSize':fontSize,'lineHeight':lineHeight}} >{text}</p>
                    </div>
                );
        }

    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    userState : state.userState,
})

function mapDispatchToProps(dispatch){
    return {
        //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(ADalert);
  