////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"
import { BackgroundV1 } from 'service/engine/decoder/EditorDecoder';


// 컴포넌트 연결
// import Show from '../show/Show'; // 메뉴 헤더

// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ColorEditFormV2({value,type,title,change}) {

    /**
     * 위젯을 수정합니다.
     *  @param {bool} classify : 수정할 값의 분류를 적습니다.
     *  @param {bool} key : 수정할 키의 이름을 적습니다.
     *  @param {bool} value : 해당페이지가 선택한 뷰에서 방송중인지 확인합니다.
     */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set

    // state
    const [ColorValue, setColorValue] = useState(value.toUpperCase());
    const [ValidColor, setValidColor] = useState(true);

    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        const isValid = /^#[0-9A-Fa-f]{6}$/.test(ColorValue);
        if(isValid){
            setValidColor(true);
            change(type,ColorValue)
        }else{
            setValidColor(false);
        }

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [ColorValue]);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

        return (
            <Fragment>
            <div className="EditModule ColorModuleV2"> 
            <style jsx>{`${ModuleStyle}`}</style>
                <p>{title?title:'색상'}</p>
                <div  className='ColorForm'>


                    <input type="color" value={value} onChange={(e) => setColorValue(e.target.value.toUpperCase())} />
{/* 
                    <div className='SelectColorBox' style={{backgroundColor:value}}>
             
                    </div> */}
                    <input className='SelectColorCode' style={{color:ValidColor?"#FFFFFF":"#FF0000"}}  maxLength="7"  value={ColorValue} onChange={(e)=>setColorValue(e.target.value.toUpperCase())}/>
                        


                    
                </div>

            </div>
            </Fragment>
        );
    }


export default ColorEditFormV2;


const ModuleStyle = `

.ColorModuleV2 .ColorForm{
    width: 100px;
    box-sizing: border-box;
    background-color: #222;
    height: 24px;
    padding: 2px 2px;
    border-radius: 5px;
    color: #fff;
    display : flex;
    border: none;
    justify-content: space-between;

}

.ColorModuleV2 .ColorForm .SelectColorCode{
    height: 20px;
    width: 70px;
    margin : 0px;
    padding : 0px;
    font-family: "프리텐다드SemiBold", sans-serif;
    font-size : 14px;
    line-height : 20px;
    text-align : center;    
    outline: none;
}



.ColorModuleV2 input[type="color"] {
    -webkit-appearance: none;
    border: none;
    width: 18px;
    height: 18px;
    margin : 1px;
    border-radius: 2px;
    cursor: pointer;
    padding: 0;
  }

  /* Remove default internal padding and appearance for webkit browsers */
.ColorModuleV2 input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 5px;
  }
.ColorModuleV2 input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
  }

`;