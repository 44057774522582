////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// 라이브러리 

// [리덕스]스토어 연결

// [프로바이더]
import EditContext from 'service/context/EditContext'; 

// SASS&CSS 연결
import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import WidgetDisplay from 'components/display/WidgetDisplay' // 위젯 전용 디스플레이
import PageDisplay from 'components/display/StaticDisplay';
import ImageButtonModule from 'components/edit/editModule/ImageButtonModule' ; 
import LegacyTextEditFrom from 'components/edit/editorField/LegacyTextEditForm';
// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from'service/other/Utility.js' ; 
import * as DeCoder from 'service/engine/DeCoder'; 
import * as Widget from 'service/edit/WidgetService' ; 
import { dragTransparent } from 'service/event/common' ; 
import { utils } from 'sortablejs';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
/**
 * 값이 없는 경우 나옵니다.
 */

function TabPreset({move}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);

    // state
    // const [PageTitle, setPageTitle] = useState(page?.page_title);


    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        const TargetPage = context?.nowPage();
        
        console.log('TargetPage : 0',TargetPage);
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context]);
    
    ////////////////////////////////////////////////////////
    // 
    ////////////////////////////////////////////////////////
    
    /**
     * 위젯을 편집합니다.
     * @param {*} property 
     */
    /**
       * 위젯 데이터를 수정합니다. 단일 수정 및 다중수정을 지원합니다. 
       * 단일수정 : modify(category,key,val,uuid)
       * 다중수정 : modify(category,[key1,key2],[val1,val2],uuid) 또는 modify([category1],[category2],[key1,key2],[val1,val2],uuid)
       * @param {*} category 위젯의 수정할 카테고리, cat1 또는 [cat1,cat2]
       * @param {*} keyName 위젯의 수정할 키, key1 또는 [key1,key2]
       * @param {*} value 위젯의 수정할 카테고리, val1 또는 [val1,val2]
       * @param {string} uuid 대상위젯
       * @returns 
       */
    const modifyWidget = (category,keyName,value,uuid) => {
      let keyList 
      let valueList 
      let categoryList 
      // 위젯의 단일 속성 수정인지, 복수의 속성 수정인지를 keyName이 배열인지 아닌지 보고 판단합니다.
      if (typeof keyName === 'string') {
        keyList = [keyName]
        valueList = [value]
        categoryList = [category] 
      } else if (Array.isArray(keyName)) {
        keyList = keyName
        valueList = value
        categoryList = Array.isArray(category) ? category : [category];
      } else {
        return 
      }

      // 키리스트 숫자만큼 값 리스트가 있는지 확인합니다.
      if (keyList?.length != valueList?.length){
        console.error(`modifyWidget : \n ⛔️ 위젯의 여러 값을 동시에 수정할때는 [키,키] [값,값] 형식으로 키의 숫자만큼 값을 넣어야합니다.`);
        return
      }

      // 카테고리가 하나거나 또는 키 리스트 만큼 있는지 확인합니다.
      if (!(categoryList.length === 1 || categoryList?.length === keyList?.length)) {
        console.error(`modifyWidget : \n ⛔️ 위젯의 카테고리는 하나거나 키숫자만큼의 배열이어야 합니다. 하나면 전역적용 배열이면 각각 순서에 맞춰 적용됩니다.`);
        return;
      }
      const TargetWidget = context.findwidget(uuid)?.[0];    
      const ModifyData = {}
      for (let index = 0; index < keyList.length; index++) {
        const Category = categoryList.length == 1 ? categoryList[0] :categoryList[index];
        Utility.setNestedObject(ModifyData,[Category,keyList[index]],valueList[index])
      }
      const WidgetData = TargetWidget.data;
      const MergeData = Utility.mergeDeep(ModifyData ,WidgetData)
      TargetWidget.data = MergeData;
      context.modifyWidget(TargetWidget,'tapWidget')
    }

    const modifyPreset = (preset,data) => {
      const TargetWidget = context.findwidget(context?.selectWidget)?.[0];    

      if(data){
        TargetWidget.data = Utility.mergeDeep(data,TargetWidget.data )
      }

      TargetWidget.data.preset = preset;

      context.modifyWidget(TargetWidget)
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    
    // const addPage = () =>{
    //   context.addPage();
    // }

    const moveTab = (tab) =>[
      move(tab)
    ]

    const TargetPage = context?.nowPage();
    const TargetPageWidget = context.nowPage()?.page_widget;
    const TargetWidget = context?.selectWidget;
    const TargetIndex = TargetPage.page_widget.findIndex(item => item.uuid === TargetWidget)
    const TargetWidgetOBJ = Utility.deepCopy(TargetPage.page_widget[TargetIndex])
    
    let tabContent = []

    const preViewPreset = (widget,preset) => {
      const modifyWidget = Utility.deepCopy(widget)
      modifyWidget['data']['preset'] = preset
      return modifyWidget ; 
    }

    // 선택된 페이지가 없거나 선택된 위젯이 없으면 이렇게 됩니다.
    if(TargetPage==null||TargetWidget==null){
      moveTab('screen')
    }else{
      const PresetList = DeCoder.getPreset({target : TargetPage.page_widget[TargetIndex]});

      TargetWidgetOBJ['data'] = Widget.get(context,TargetWidget)
      
      tabContent = PresetList.map(
        (preset,index) => (
        
      
          <div className='presetBox' onClick={()=>modifyPreset(preset?.name,preset?.data)}>
            <div className='presetDisplay'>
            <WidgetDisplay widgetObj={preViewPreset(TargetWidgetOBJ,preset?.name)} global={context?.overlap?.global} previewWidth={250} previewHeight={150}/>    
            </div>
            <div className='presetText'>
              <div className='TitleField'>
                <h3 className='presetTitle'>
                {preset?.nameKo}
                </h3>
                <p className='presetInfo'>
                  {preset?.info}
                </p>
              </div>
              <p className='presetSummary'>
              {preset?.summary}
              </p>
            </div>
          </div>
  

        )   
      );    
    }




    return ( 
      <Fragment>
      <style jsx>{`${EditTapStyle}`}</style>
      <div id='TabPreset'>

      <div id="TabNavigationField" onClick={()=>moveTab('edit')}>
          <div className='backTabIcon'>
          {Editor.icon({icon:'arrow_back_ios_new',size:18,lineHeight:20})}
          </div>
          <p>위젯 편집화면으로 이동</p>     
      </div>
      <div>
      {tabContent}
      </div>
      </div>

      </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(TabPreset);
  
const EditTapStyle = `

  #TabPreset{
    width: calc(100% - 30px);
    margin : 0px 15px;
  }

  #TabPreset #TabNavigationField{
    display:flex;
    width: 100%;
    margin : 10px 0px;
    padding : 10px 0px; 
    height : 25px;
    background-color: #333;
    border-radius : 5px;
    justify-content: center;
    align-items: center;
  }
  #TabPreset #TabNavigationField .backTabIcon{
    height : 20px;
    margin : 2.5px;
  }

  #TabPreset .presetBox{
    width: 100%;
    height : 250px;
    background-color:rgb(63, 63, 63);
    border-radius : 10px;
    display:flex;
    flex-direction: column;
    background-color: #333;
    margin : 10px 0px;
  }

  #TabPreset .presetBox:hover{

    background: rgba(255, 153, 0, .5);

  }


  #TabPreset .presetDisplay{
    width: calc(100% - 40px);
    margin : 20px 20px 10px 20px;
    height : 150px;
    background-color: #fff;
    border-radius : 10px ;
    box-shadow: 3.5px 3.5px 10px #252525, -3.5px -3.5px 10px #636363;
  }
  #TabPreset .presetText{
    width: calc(100% - 40px);
    height : 50px;
    border-radius : 10px;
    margin : 0px 20px;
  }

  #TabPreset .presetText .TitleField{
    display:flex;
    width: 100%;
    justify-content: space-between;
  }

  
  #TabPreset .presetText .presetTitle{
    height : 30px;
    display:flex;
    width: calc(100% - 20px);
    justify-content: start;
    align-items: center;
  }

  #TabPreset .presetText .presetInfo{
    height : 30px;
    display:flex;
    justify-content: end;
    align-items: center;
  }

  #TabPreset .presetText .presetInfo p{
    display:flex;
    font-size : 15px;
    line-height : 20px;
  }

  #TabPreset .presetText .presetSummary{
    height : 30px;
    display:flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    
  }


 
  `; 

  