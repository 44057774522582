

function hexToRGB(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }
  
  function darkenRGB([r, g, b], percentage) {
    const factor = (100 - percentage) / 100;
    const darkened = [r, g, b].map(value => Math.floor(value * factor));
    return `rgb(${darkened[0]}, ${darkened[1]}, ${darkened[2]})`;
  }
  
  function darkenHex(hex, percentage) {
    const rgb = hexToRGB(hex);
    return darkenRGB(rgb, percentage);
  }
  

function preset(preset,self,data,style){

    let left = Object.assign(style.left,style.team)
    let right = Object.assign(style.right,style.team)

    const TeamData = data.value.teamData
    const LeftName= TeamData?.[0]?.teamName??'TEAM';
    const RightName= TeamData?.[1]?.teamName??'TEAM';

    switch (preset) {
        case 'default':
            return(
            <div className="ScoreBoard defaultScoreBoard" ref={self.widget} style={{...style.object}}>
           
                <div className="leftTeam teamInfo" style={{background:style?.teamStyle[0]?.background??"#59CBE8",color:style?.teamStyle[0]?.color??"#ffffff",...style.team}}>
                    <div className="teamName" style={style.team}>
                        {LeftName}
                    </div>
                    {TeamData?.[0]?.teamLogo?
                    <div className="teamLogo">
                        <img src={TeamData?.[0]?.teamLogo} />
                    </div>
                    : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[0]?.teamScore??0}
                    </div>
                </div>
                {data.value.matchInfo?
                <div className="matchInfo"> 
                    <pre style={style.info} >{data.value.matchInfo}</pre>
                </div>
                :null}
                <div className="rightTeam teamInfo" style={{background:style?.teamStyle[1]?.background??"#F77397",color:style?.teamStyle[1]?.color??"#ffffff",...style.team}}>
                    <div className="teamName" style={style.team}>
                        {RightName}
                    </div>
                    {TeamData?.[1]?.teamLogo?
                    <div className="teamLogo">
                        <img src={TeamData?.[1]?.teamLogo} />
                    </div>
                         : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[1]?.teamScore??0}
                    </div>
                </div>
            </div>
        )

        case 'tilted':
            return(
            <div className="ScoreBoard tiltedScoreBoard" ref={self.widget} style={{...style.object}}>
           
                <div className="leftTeam teamInfo">
                    <div className="teamName" style={{background:style?.teamStyle[0]?.background??"#59CBE8",color:style?.teamStyle[0]?.color??"#ffffff",transform:  (TeamData?.[0]?.teamLogo?'translateX(-250px)':'translateX(-100px)'),...style.team}}>
                        {LeftName}
                    </div>
                    {TeamData?.[0]?.teamLogo?
                    <div className="teamLogo" style={{background:darkenHex(style?.teamStyle[0]?.background??"#59CBE8",100)}}>
                        <img src={TeamData?.[0]?.teamLogo} />
                    </div>
                    : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[0]?.teamScore??0}
                    </div>
                </div>
                <pre className="matchInfo" style={style.info}> 
                    {data.value.matchInfo}
                </pre>
                <div className="rightTeam teamInfo">
                    <div className="teamName" style={{background:style?.teamStyle[1]?.background??"#F77397",color:style?.teamStyle[1]?.color??"#ffffff",transform:  (TeamData?.[1]?.teamLogo?'translateX(250px)':'translateX(100px)'), ...style.team}}>
                        {RightName}
                    </div>
                    {TeamData?.[1]?.teamLogo?
                    <div className="teamLogo"style={{background:darkenHex(style?.teamStyle[1]?.background??"#F77397",100)}}>
                        <img src={TeamData?.[1]?.teamLogo} />
                    </div>
                         : null}
                    <div className="teamScroe" style={style.team}>
                        {TeamData?.[1]?.teamScore??0}
                    </div>
                </div>
            </div>
        )

        case 'box':

            const boxHeight = (Number(data?.size?.height-52??0) /2);

            console.log('프리셋 체크',boxHeight,Number(data?.size?.height))
            return(
            <div className="ScoreBoard boxScoreBoard" ref={self.widget} style={{...style.object}}>
                
                <div className="matchInfo"  style={style.info}> 
                    {data.value.matchInfo}
                </div>
                <div className="teamArea ">
                    <div className="leftTeam teamInfo" style={{height : boxHeight, background:style?.teamStyle[0]?.background}}>
                        <div className="teamData" style={{height : boxHeight}}>
                            <div className="teamLogo"  style={{height : boxHeight,width :boxHeight}}>
                                <div class="square">
                                {TeamData?.[0]?.teamLogo?
                                    <img src={TeamData?.[0]?.teamLogo} />
                                :null}
                                </div>
                            </div>
                            <div className="teamName" style={{height : boxHeight}}>
                            <div/><div style={{fontSize : boxHeight/2,...style.team,color:style?.teamStyle[1]?.color}}>{TeamData?.[0]?.teamName}</div>
                            </div>
                            </div>
                        <div className="teamScroe" style={{fontSize : boxHeight/2,...style.team,color:style?.teamStyle[1]?.color}}>
                            {TeamData?.[0]?.teamScore??0}
                        </div>

                    </div>
                    <div className="TeamLine"/>
                    <div className="rightTeam teamInfo" style={{height : boxHeight, background:style?.teamStyle[1]?.background}}>
                        <div className="teamData" style={{height : boxHeight}}>
                            <div className="teamLogo"  style={{height : boxHeight,width :boxHeight}}>
                                <div class="square">
                                {TeamData?.[1]?.teamLogo?
                                    <img src={TeamData?.[1]?.teamLogo} />
                                :null}
                                </div>
                            </div>
                            <div className="teamName"  style={{height : boxHeight}}>
                            <div/><div style={{fontSize : boxHeight/2,...style.team,color:style?.teamStyle[1]?.color}}>{TeamData?.[1]?.teamName}</div>
                            </div>
                        </div>
                     
                        <div className="teamScroe" style={{fontSize : boxHeight/2,...style.team,color:style?.teamStyle[1]?.color}}>
                            {TeamData?.[1]?.teamScore??0}
                        </div>
                        </div>
                    </div>
                </div>
        )
        case 'overwatch_normal':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
            <div className="ScoreBoard overwatch_normal" ref={self.widget} style={{...style.object}}>
           
                <div className="leftTeam teamInfo" style={left} >
                    <div className="teamName" > 
                        {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                    </div>
                    <div className="teamScroe">
                        {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                    </div>
                    <div className="designLine"/>
                </div>
                <pre className="matchInfo" style={style.info}> 
                    {data.value.matchInfo}
                </pre>
                <div className="rightTeam teamInfo" style={right}>
                    <div className="teamName">
                        {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                    </div>
      
                    <div className="teamScroe">
                        {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                    </div>
                    <div className="designLine"/>
                </div>
            </div>
        )
        case 'overwatch_new':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}}>
                <svg>
                    <polyline
                    points="0,0 0,50 50,50 50,0 0,0" 
                    fill="red" stroke="#b38fac" stroke-width="6" />
                    <polyline
                    points="0,0" 
                    fill="none" stroke="#b38fac" stroke-width="6" />
                </svg>
                {/* <div className="leftTeam teamInfo" style={left} >
                    <div className="teamName" > 
                        {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                    </div>
                    <div className="teamScroe">
                        {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                    </div>
                    <div className="designLine"/>
                </div>
                <pre className="matchInfo" style={style.info}> 
                    {data.value.matchInfo}
                </pre>
                <div className="rightTeam teamInfo" style={right}>
                    <div className="teamName">
                        {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                    </div>
                    <div className="teamScroe">
                        {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                    </div>
                    <div className="designLine"/>
                </div> */}

            </div>
        )

        case 'overwatch_underbar':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className="ScoreBoard overwatch_underbar" ref={self.widget} style={{...style.object}}>
                    <div className="playing">
                        <div className="matchHeader">
                            <div className="matchBox">
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                            </div>
                            <div className="matchInfo" style={{background:'linear-gradient(to right, '+left.background+' , '+right.background+ ')'}} >
                                <pre className="matchText" style={style.info}> 
                                    {/* {data.value.matchInfo} */}
                                </pre>
                            </div>
                   
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                           
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )

        case 'overwatch2_normal':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className="ScoreBoard overwatch2_normal" ref={self.widget} style={{...style.object}}>
                    <div className="opening">
                        <div className="matchBox">
                            <div className="matchHeader">
                                <pre className="matchInfo" style={style.info}> 
                                    {data.value.matchInfo}
                                </pre>
                            </div>
                            <div className="matchTeam">

                     
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                            </div>
                            </div>
                            <div className="matchGame">

                            </div>
                        </div>
                    </div>
                    <div className="playing">
                        <div className="matchHeader">
                            <div className="matchBox">
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                       
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                           
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
        case 'overwatch2_simple':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className="ScoreBoard overwatch2_simple" ref={self.widget} style={{...style.object}}>
                    <div className="playing">
                        <div className="matchHeader">
                            <div className="matchBox">
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                           
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
        case 'overwatch2_header':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className="ScoreBoard overwatch2_header" ref={self.widget} style={{...style.object}}>
                    <div className="playing">
                        <div className="matchHeader">
                            <div className="matchBox">
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                            </div>
                            </div>
                        
                            
                        </div>
                        <div className="matchInfoText">
                            <pre className="matchInfo" style={style.info}> 
                                    {data.value.matchInfo}
                            </pre>
                        </div>
                    </div>
                </div>
        )

        case 'leagueofLegends_normal':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            console.log(left)
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}}>
                        <svg viewBox="0 0 1920 108"  xmlns='http://www.w3.org/2000/svg' >

                            <defs>
                                <radialGradient id="myGradient">
                                <stop offset="10%" stop-color="gold" />
                                <stop offset="95%" stop-color="red" />
                                </radialGradient>
                            </defs>

                            <defs>
                                <radialGradient id="myGradient">
                                <stop offset="10%" stop-color="gold" />
                                <stop offset="95%" stop-color="red" />
                                </radialGradient>
                            </defs>

                            <svg x="185" y="0" width="400" height="43">
                                
                                <path d="M0 0 L0 43 L400 43 L400 0" fill={left.background}/>
                                <text x="350" y="30" class="teamName" fill="#FFFFFF" text-anchor="end" font-size="30" style={left} >
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </text>
                                <path d="M362 5 L362 38 L395 38 L395 5" fill="black"/>
                                <text x="379.5" y="31" class="teamScroe" fill="#FFFFFF" text-anchor="middle" font-size="20" style={left} >
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </text>
                            </svg>

                            <svg x="1347" y="0" width="400" height="43">
                                <path d="M0 0 L0 43 L400 43 L400 0" fill={right.background}/>
                                <text x="50" y="30" class="teamName" fill="#FFFFFF" text-anchor="start" font-size="30" style={right} >
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </text>
                                <path d="M5 5 5 38 L38 38 L38 5" fill="black"/>
                                <text x="21.5" y="31" class="teamScroe" fill="#FFFFFF" text-anchor="middle" font-size="20" style={left} >
                                    {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </text>
                            </svg>
                        </svg>

                </div>
        )
        case 'leagueofLegends_GameStyle':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            console.log(left)
            return(
                <div className={"object "+self.props.widgetName+"_preset_"+preset} ref={self.widget} style={{...style.object}}>
                        <svg className="ScoreBoard" viewBox="0 0 1920 108"  xmlns='http://www.w3.org/2000/svg' >
                            <defs>
                                <filter id="LeagueofLegends_GameStyle_shadow1">
                                <feDropShadow dx="0" dy="0" stdDeviation="1" flood-color="#000000"/>
                                </filter>
                                <filter id="LeagueofLegends_GameStyle_shadow2">
                                <feDropShadow dx="0" dy="0" stdDeviation="10"
                                    flood-color="#091414" flood-opacity="0.5"/>
                                </filter>
                                <filter id="LeagueofLegends_GameStyle_shadow">
                                <feDropShadow dx="0" dy="0" stdDeviation="3"
                                    flood-color="#000000" flood-opacity="1"/>
                                </filter>
                            </defs>
                        
                            <svg className="leftTeam" x="260" y="0" width="325" height="43"  xmlns='http://www.w3.org/2000/svg'>
                                
                                <path d="M0 0 L0 42 L325 42 L325 0" fill="#46402F"/>
                                <path d="M2 0 L2 40 L323 40 L323 0" fill="#1C3437"/>
                                <path d="M5 3 L5 37 L320 37 L320 3" filter="url(#LeagueofLegends_GameStyle_shadow)" fill="#091414"/>
                                <text x="275" y="30" class="teamName" fill="#417397" text-anchor="end" font-size="30" style={left} >
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </text>
  
                                <text x="304.5" y="31" class="teamScroe" fill="#417397" text-anchor="middle" font-size="20" style={left} >
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </text>
                            </svg>
                            
                            <svg className="rightTeam" x="1347" y="0" width="325" height="43"  xmlns='http://www.w3.org/2000/svg'>
                                <path d="M0 0 L0 42 L325 42 L325 0" fill="#46402F"/>
                                <path d="M2 0 L2 40 L323 40 L323 0" fill="#1C3437"/>
                                <path d="M5 3 L5 37 L320 37 L320 3" filter="url(#LeagueofLegends_GameStyle_shadow)" fill="#091414"/>
                                <text x="50" y="30" class="teamName" fill="#95403E" text-anchor="start" font-size="30" style={right} >
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </text>

                                <text x="21.5" y="31" class="teamScroe" fill="#95403E" text-anchor="middle" font-size="20" style={left} >
                                    {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </text>
                            </svg>
                        </svg>

                </div>
        )
        case 'valorant_normal':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className="ScoreBoard valorant_normal" ref={self.widget} style={{...style.object}}>
                    <div className="opening">
                        <div className="matchBox">
                            <div className="matchHeader">
                                <pre className="matchInfo" style={style.info}> 
                                    {data.value.matchInfo}
                                </pre>
                            </div>
                            <div className="matchTeam">

                     
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                            </div>
                            </div>
                            <div className="matchGame">

                            </div>
                        </div>
                    </div>
                    <div className="playing">
                        <div className="matchHeader">
                            <div className="matchBox">
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
        case 'valorant_double':
            if(data.value.reverse){
                left = Object.assign(style.right,style.team)
                right = Object.assign(style.left,style.team)
            }
            return(
                <div className="ScoreBoard valorant_double" ref={self.widget} style={{...style.object}}>
                    <div className="opening">
                        <div className="matchBox">
                            <div className="matchHeader">
                                <pre className="matchInfo" style={style.info}> 
                                    {data.value.matchInfo}
                                </pre>
                            </div>
                            <div className="matchTeam">

                     
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                            </div>
                            </div>
                            <div className="matchGame">

                            </div>
                        </div>
                    </div>
                    <div className="playing">
                        <div className="matchHeader">
                            <div className="matchBox">
                            <div className="leftTeam teamInfo" style={left} >
                                <div className="teamScroe">
                                    {(data.value.reverse?data.value.rightTeamScore:data.value.leftTeamScore)}
                                </div>
                                
                                <div className="teamName" > 
                                    {(data.value.reverse?data.value.rightTeamName:data.value.leftTeamName)}
                                </div>
                                <div className="teamSubScroe">
                                    {(data.value.reverse?data.value.rightTeamSubScore:data.value.leftTeamSubScore)}
                                </div>
                            </div>
                            <div className="rightTeam teamInfo" style={right} >
                                <div className="teamScroe">
                                {(data.value.reverse?data.value.leftTeamScore:data.value.rightTeamScore)}
                                </div>
                                <div className="teamName">
                                    {(data.value.reverse?data.value.leftTeamName:data.value.rightTeamName)}
                                </div>
                                <div className="teamSubScroe">
                                {(data.value.reverse?data.value.leftTeamSubScore:data.value.rightTeamSubScore)}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
        default:
            return(
            <div className={"object unknownPreset"} ref={self.widget} style={{...style.object}} >
                <p>알수없는 프리셋 {preset} </p>
            </div>
        )
    }
}
// export function data(data,style,self) {
//    return preset(data.preset,data,style,self)
// }

export function data(self,style) {
    return preset(self.props.data.preset,self,self.props.data,style)
}

