////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useContext, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
import {PositionGuide,ResizeGuide} from 'components/edit/editScreen/controlObject/WidgetControllerGuide'
import WidgetOption from 'components/edit/editScreen/controlObject/WidgetOption'
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as DeCoder from 'service/engine/DeCoder' ; 
import * as Widget from 'service/edit/WidgetService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [위젯선택] 
 * 선택된 위젯을 컨트롤 할 수 있는 컨트롤러 입니다.
 * @param {number} x 위젯의 가로 시작 좌표
 * @param {number} y 위젯의 세로 시작 좌표
 * @param {number} w 위젯의 가로크기
 * @param {number} h 위젯의 세로크기
 * @param {string} uuid 위젯의 고유키
 * @param {number} scale 위젯의 고유키
 * @param {object} getwidget 위젯의 정보를 가저옵니다.
 * @param {function} setWidget 위젯의 정보를 수정합니다.
 * @param {boolean} visible 해당 위젯의 영역이 보이도록 합니다.
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const WidgetController =  React.memo(function WidgetController({ x,y,w,h, scale, uuid,getWidget,panelSize,setWidget,assist,modifyWidget,visible,option={}}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);
    // state
    const [PointType, setPointType] = useState('resize');
   
    // const [Action, setAction] = useState(null); // 컨트롤러를 통한 위젯의 액션 (기본 : null / 이동 : 'move')

    const [Width, setWidth] = useState(w);
    const [Height, setHeight] = useState(h);
    const [Xpoint, setXpoint] = useState(x);
    const [Ypoint, setYpoint] = useState(y);

    // 위젯의 기본 위치 
    const [Default, setDefault] = useState({x : null, y : null}); 
    

    // 위젯의 이동 위치
    const [Dispiacement,setDispiacement] = useState({x : 0, y : 0, w : 0, h : 0});
    const [ResizeDirection, setResizeDirection] = useState(null); // 방향
    // ref

    const [Cache,setCache] = useState(null);
    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
        // getOverlapList() 
        // Cleanup function (optional)
        return () => {
        };
      },[]);


      useEffect(() => {
        setWidth(w)
        setHeight(h)
        setXpoint(x)
        setYpoint(y)
      },[x,y,w,h]);


      useEffect(() => {
        if(getWidget){
          const ResponseCache = context.getChannelCache(getWidget)
          setCache(ResponseCache); 
        }
      }, [context.channelCache]);

      
    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const WidgetPosition ={
      position: 'absolute',
      height: Height+'px',
      width : Width+'px',
      top : Ypoint+'px',
      left : Xpoint+'px'
    }

    const LeftPanel = panelSize?.PreferencesPanel?.width+20??0


  let guideColor = 'rgba(0,0,0,1)';
  let guide = '';
  switch (context.action) {
    case 'move':
      WidgetPosition['top'] = Ypoint+(Dispiacement.y*scale)+'px';
      WidgetPosition['left'] = Xpoint+LeftPanel+(Dispiacement.x*scale)+'px';
      guideColor = 'rgba(255,0,0,0.2)';
      break;
    case 'resize':

    const widgetW = Number(getWidget?.data?.size?.height)??null
    const widgetH = Number(getWidget?.data?.size?.width)??null
    if(widgetH==null&&widgetW==null){
      return
    }
      WidgetPosition['top'] = Ypoint+(Dispiacement.y*scale)+'px';
      WidgetPosition['left'] = Xpoint+LeftPanel+(Dispiacement.x*scale)+'px';
      WidgetPosition['height'] = Height - (Dispiacement.h*scale)+'px';
      WidgetPosition['width'] = Width - (Dispiacement.w*scale)+'px';
      guideColor = 'rgba(0,255,255,0.2)';
      break;
  
    default:
      break;
  }
  
    const ControlExpansion ={
      display: 'null',
      position: 'absolute',
      height: '100vh',
      width : '100vw',
      top :  0,
      left : -LeftPanel,
    }
    const ControlReduction={}
    if(visible){
      ControlExpansion['background'] = guideColor
    }



  
/** 핸들과 관련된 컴포넌트 내 state 를 초기화 합니다.
 */
    const clearHandle = () =>{
      context.setAction(null)
      setDefault({x : null, y : null});
      setDispiacement({x : 0, y : 0, w : 0, h : 0})
    }



/** [리스너] 위젯을 드레그 하기 시작할때 일어나는 일을 정의합니다.
 * 
 * @param {*} e 마우스 이벤트 (onMouseDown) 의 이벤트 객체
 */
const MoveActionStart = (e)=>{
  console.log('마우스 시작')
  context.hideContext()
  setDefault(prev =>({...prev,x : e.clientX, y : e.clientY,}) );
  context.setAction('move')
  e.stopPropagation();
}

/** [리스너] 위젯을 드레그 중 일때 시작할때 일어나는 일을 정의합니다.
 * @param {*} e 마우스 이벤트 (onMouseMove) 의 이벤트 객체
 */
const DragAction = (e)=>{
 
  // Default (초기값)이 null 인 경우 드레그가 아님으로 판단하고 리턴합니다.
  if(Default.x==null||Default.y==null ){return}
  const OriginalScale = 1/scale // 편집화면대 실제 오버랩의 비율을 구합니다.
  // 드레그를 시작한 위치에서 얼마나 변화했는지 확인합니다.
  const x = (e.clientX-Default.x)*OriginalScale;  // 마우스의 x 좌표
  const y = (e.clientY-Default.y)*OriginalScale;  // 마우스의 y 좌표
  
  switch (context.action) {
    case 'move':
      setDispiacement(prev => ({...prev,x,y}));
      break;
    case 'resize':
      resizeDrag(x,y);
      break;
    default:
      break;
  }
}


/** [리스너] 위젯을 드레그 중 일때 시작할때 일어나는 일을 정의합니다.
 * @param {*} e 마우스 이벤트 (onMouseUp) 의 이벤트 객체
 */
const MouseUpAction = (e)=>{

  switch (context.action) {
    case 'move':
      MoveActionEnd(e)
      break;
    case 'resize':
      ResizeActionEnd(e);
      break;
    default:
      break;
  }
}

const getInstantControl = () =>{

  const modifyCache = (RequestCache,target) => {
    context.setChannelCache(RequestCache,target);
  }
  const modifyWidget = (RequestData) => {
    console.log('modifyWidget',RequestData);
  }

  
  return DeCoder.getControllerComponent(getWidget,modifyWidget,Cache,modifyCache)
} 


const MoveActionEnd = (e)=>{
  if(context.action == 'move'){
    const OriginalScale = 1/scale
    const modifyX = Math.round(Number(getWidget.data.position.x) + Number((e.clientX-Default.x)*OriginalScale)).toString();  // 마우스의 x 좌표
    const modifyY = Math.round(Number(getWidget.data.position.y) + Number((e.clientY-Default.y)*OriginalScale)).toString();  // 마우스의 y 좌표
    const ModifyWidget = {position:{x:modifyX,y:modifyY}}
    Widget.modify(context,uuid,ModifyWidget);
    clearHandle()
    e.stopPropagation();
  }
}


/** 
 * 위젯의 사이즈를 변경하는 함수입니다. [ResizeActionStart,resizeDrag,ResizeActionEnd] 와 함께 사용됩니다.
 * @param {*} e 마우스 이벤트 (onMouseDown) 의 이벤트 객체
 * @param {string} position 위젯이 사이즈를 조정합니다. 
 */

const ResizeActionStart = (e,position) =>{
  // 이 행동이 위젯의 사이즈를 변경하는 행동임을 알립니다.
  context.hideContext()
  context.setAction('resize');
  // 마우스가 처음 드레그를 시작한 위치를 기록합니다.
  setDefault({x : e.clientX, y : e.clientY,});
  // 어떤 방향으로 위젯의 사이즈를 변경하는지 지정합니다. (ex : leftTop)
  setResizeDirection(position)
  // 이벤트 버블링을 방지합니다.
  e.stopPropagation();
}

const resizeDrag = (mouseX,mouseY) =>{
  // TODO 
  
  const widgetX = Number(getWidget.data?.position?.x)
  const widgetY = Number(getWidget.data?.position?.y)

  const widgetW = Number(getWidget.data?.size?.height)
  const widgetH = Number(getWidget.data?.size?.width)

  // 위젯에 이동 관련 속성이 없으면 해당 이벤트는 무시됩니다.
  if(widgetX==null||widgetY==null||widgetW==null||widgetH==null){ 

    return
  }

    let visibleX = 0
    let visibleY = 0
    let visibleW = 0
    let visibleH = 0

    switch (ResizeDirection) {
      case 'LeftTop':
        visibleX = widgetX + (mouseX - widgetX);
        visibleY = widgetY + (mouseY - widgetY);
        visibleW = mouseX;
        visibleH = mouseY;
        setDispiacement({x:Math.round(visibleX),y:Math.round(visibleY),w:Math.round(visibleW),h:Math.round(visibleH)});
        break;
      case 'CenterTop':
        visibleY = widgetY + (mouseY - widgetY);
        visibleH = mouseY;
        setDispiacement(prev=>({...prev,y:Math.round(visibleY),h:Math.round(visibleH)}));
        break;    
      case 'RightTop':
        visibleX = 0 
        visibleY = widgetY + (mouseY - widgetY);
        visibleW = - mouseX;
        visibleH = mouseY;
        setDispiacement({x:Math.round(visibleX),y:Math.round(visibleY),w:Math.round(visibleW),h:Math.round(visibleH)});      
        break;
      case 'LeftCenter':
        visibleX = widgetX + (mouseX - widgetX);
        visibleW = mouseX;
        setDispiacement(prev=>({...prev,x:Math.round(visibleX),w:Math.round(visibleW)}));
        break; 
      case 'RightCenter':
        visibleX = 0 
        visibleW = - mouseX;
        setDispiacement(prev=>({...prev,x:Math.round(visibleX),w:Math.round(visibleW)}));
        break;
      case 'LeftBottom':
        visibleX = widgetX + (mouseX - widgetX);
        visibleY = 0
        visibleW = mouseX;
        visibleH = - mouseY;
        setDispiacement({x:Math.round(visibleX),y:Math.round(visibleY),w:Math.round(visibleW),h:Math.round(visibleH)});
        break; 
      case 'CenterBottom':
        visibleY = 0
        visibleH = - mouseY;
        setDispiacement(prev=>({...prev,y:Math.round(visibleY),h:Math.round(visibleH)}));
        break;
      case 'RightBottom':
        visibleX = 0 
        visibleY = 0
        visibleW = - mouseX;
        visibleH = - mouseY;
        setDispiacement({x:Math.round(visibleX),y:Math.round(visibleY),w:Math.round(visibleW),h:Math.round(visibleH)});     
        break; 
      default:
        break;
    }
      // setDispiacement(prev => ({...prev,x,y}));
 




  // ResizeDirection
  
}

/**
 * ResizeActionEnd ()
 */
const ResizeActionEnd = (e) =>{
  if(context.action == 'resize'){

  const OriginalScale = 1/scale
  const modifyX = Math.round(Number(getWidget.data.position.x) + Number(Dispiacement.x)).toString();  // 마우스의 x 좌표
  const modifyY = Math.round(Number(getWidget.data.position.y) + Number(Dispiacement.y)).toString();  // 마우스의 y 좌표
  const modifyW = Math.round(Number(getWidget.data.size.width) - Number(Dispiacement.w)).toString();  // 위젯의 width
  const modifyH = Math.round(Number(getWidget.data.size.height) - Number(Dispiacement.h)).toString();  // 위젯의 height
  const ModifyWidget = {position:{x:modifyX,y:modifyY},size:{width:modifyW,height:modifyH}}
  Widget.modify(context,uuid,ModifyWidget);
  clearHandle()
  e.stopPropagation();
  }
}


const actionShow = () =>{
  let targetWidget = getWidget;

  const visible = targetWidget.data?.display?.show;

  if(visible == false){
    targetWidget['data']['display']['show'] = true;
    Widget.modify(context,uuid,targetWidget.data);
  }
  context.hideContext();
}

const actionHide = () =>{
  let targetWidget = getWidget;

  const visible = targetWidget.data?.display?.show;
  if(visible == true){
    targetWidget['data']['display']['show'] = false;
    Widget.modify(context,uuid,targetWidget.data);
  }
  context.hideContext();
}

const ShowContext = (e) =>{
  context.showContext(uuid,'widget', e.clientX,e.clientY);
  e.stopPropagation()
  e.preventDefault()
}

const ControlGuide = () => {
  switch (context.action) {
    case 'move':
      return <PositionGuide x={Math.floor(Number(getWidget.data.position.x)+Dispiacement.x)} y = {Math.floor(Number(getWidget.data.position.y)+Dispiacement.y)} />
    case 'moveKeyAction':
      return <PositionGuide x={Math.floor(Number(getWidget.data.position.x)+Dispiacement.x)} y = {Math.floor(Number(getWidget.data.position.y)+Dispiacement.y)} />  
    case 'resize':
      return <ResizeGuide width={Number(getWidget.data.size.width)-Dispiacement.w} height = {Number(getWidget.data.size.height)-Dispiacement.h} />
    default:
      return null 
  }
}

  const WidgetVisible = getWidget.data?.display?.show;

  // 해당 액션이 진행중이면 컨트롤 어시스트 모드가 활성화 됩니다. 
  const ControlAssist = ["move", "resize"];

  return(
    <div className='WidgetControllerPlace' style={ControlAssist.includes(context.action)?ControlExpansion:ControlReduction}
      onMouseMove={(e)=>DragAction(e)}
      onMouseUp={(e)=>MouseUpAction(e)}
    >

    <div className='WidgetController' style={WidgetPosition} 
    // onClick={(e)=>selectWidget(e)}
    onClick={(e)=> e.stopPropagation()}
    onContextMenu={(e)=>ShowContext(e)}
    onMouseDown={(e)=>MoveActionStart(e)}
    onMouseUp={(e)=>MoveActionEnd(e)}

    >
    <style jsx>{`${WidgetSelecterStyle}`}</style>

      <div className='InstantControllerArea'> 
      {getInstantControl()}
      </div>
      <span className='ResizePoint LeftTop' 
        onMouseDown={(e)=>ResizeActionStart(e,'LeftTop')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'LeftTop')}
      />
      <span className='ResizePoint CenterTop'
        onMouseDown={(e)=>ResizeActionStart(e,'CenterTop')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'CenterTop')}
      />
      <span className='ResizePoint RightTop'
        onMouseDown={(e)=>ResizeActionStart(e,'RightTop')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'RightTop')}
      />
      <span className='ResizePoint LeftCenter'
        onMouseDown={(e)=>ResizeActionStart(e,'LeftCenter')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'LeftCenter')}
      />
      <span className='ResizePoint RightCenter'
        onMouseDown={(e)=>ResizeActionStart(e,'RightCenter')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'RightCenter')}      
      />
      <span className='ResizePoint LeftBottom'
        onMouseDown={(e)=>ResizeActionStart(e,'LeftBottom')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'LeftBottom')}     
      />
      <span className='ResizePoint CenterBottom'
        onMouseDown={(e)=>ResizeActionStart(e,'CenterBottom')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'CenterBottom')}        
      />
      <span className='ResizePoint RightBottom'
        onMouseDown={(e)=>ResizeActionStart(e,'RightBottom')}
        // onMouseUp={(e)=>ResizeActionEnd(e,'RightBottom')}        
      />
        <div className='editTool'

      // onMouseMove={(e)=> console.log('editTool onMouseMove')}
      // onMouseUp={(e)=>  console.log('editTool onMouseUp')}
      // onDragStart={(e)=> console.log('editTool onDragStart')}


        onMouseMove={(e)=> e.stopPropagation()}
        onMouseUp={(e)=> e.stopPropagation()}
        onMouseDown={(e)=> e.stopPropagation()}
        >
        
          
          <div className={ context.action ?'ControllerGuide GuideActive':'ControllerGuide GuideDisable'}>
             {ControlGuide()}
          </div>

          <div className={ context.action ?'editOptions OptionsDisable':'editOptions OptionsActive'} >
            <WidgetOption action={()=>{Widget.copy(context,uuid); context.hideContext()}} icon={'content_copy'} info={'위젯을 복사합니다'} />
            {WidgetVisible ?<WidgetOption action={()=>{actionHide(); context.hideContext()}} icon={'visibility_off'} info={'위젯을 보이지 않게 합니다.'}/>:<WidgetOption action={()=>{actionShow(); context.hideContext()}} icon={'visibility'} info={'위젯을 보이게 합니다.'}/>}
            <WidgetOption action={()=>{Widget.indexEnd(context,uuid); context.hideContext()}} icon={'flip_to_front'} info={'위젯을 위로 올립니다'}/>
            <WidgetOption action={()=>{Widget.indexFront(context,uuid); context.hideContext()}} icon={'flip_to_back'} info={'위젯을 아래로 내립니다'}/>
            <WidgetOption action={()=>{Widget.del(context); context.hideContext()}} icon={'delete'} info={'위젯을 삭제합니다.'}/>
            <WidgetOption action={()=>context.showContext(uuid,'widget',Xpoint+360+Width+20,Ypoint)} icon={'arrow_drop_down'} info={'상세 메뉴를 표시합니다'}/>  
          </div>
        </div>



    </div>
    {context.action?
    <div className='actionGuide' style={{position:'absolute',zIndex:'10',top:panelSize?.EditArea?.height,left:panelSize?.UtilityPanel?.width+30,height:40,width:panelSize?.EditContainer?.width-panelSize?.UtilityPanel?.width-50}}>

      </div>
    :null}

    </div>
  )

  // height: Height+'px',
  // width : Width+'px',
  // top : Ypoint+'px',
  // left : Xpoint+'px'

  });

  export default WidgetController;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const WidgetSelecterStyle = `
    .WidgetController{
      cursor: move;
      box-sizing: border-box;
      border: 2px solid #a9a9a9;
      /*backdrop-filter:grayscale(30%);*/
      position: relative;
    }
    
    .WidgetController p{
      color : black;
    }

    .WidgetController .ResizePoint{
      display:flex;
      height: 10px;
      width: 10px;
      background: white;
      box-shadow: 1px 1px 3px #252525, -1px -1px 3px #636363;
      border-radius :  999px;
      z-index: 999;
      position: absolute;
    }
    .WidgetController .InstantControllerArea{
      position: absolute;
      background-color: transparent;
      display:flex;
      height: 100%;
      width: 100%;
      z-index: 599;
    }

    .WidgetController .editTool{
      top :-40px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #3F3F3F;
      box-shadow: 0 2px 5px rgba(0,0,0,.15);
      position: relative;
      height: 30px;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      width: 200px;


    }

    .WidgetController .editTool .ControllerGuide{
      background-color: orange;
      display:flex;
      transition: transform 0.3s;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      transform-origin: center top; 
      position: absolute;
      height: 30px;
      width: 200px;
    }

    .WidgetController .editTool .GuideDisable{
      transform: rotateX(90deg);
      pointer-events: none;
    }

    .WidgetController .editTool .GuideActive{
      transform: rotateX(0deg);

    }

    .WidgetController .editOptions{
      background-color: #3F3F3F;
      border-radius: 5px;
      height: 30px;
      display:flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      transition: transform 0.3s;
      transform-origin: center bottom; 
      width: 200px;
    }

    .WidgetController .OptionsDisable{
      transform: rotateX(90deg);

    }

    .WidgetController .OptionsActive{
      transform: rotateX(0deg);
    }

    .WidgetController .editOptions button{
      height: 30px;
      min-width : 30px;
    }
    .WidgetController .editOptions button:hover{
      height: 30px;
      min-width : 30px;
      border-radius: 5px;
      background-color: #2C2C2C;
    }


    .WidgetController .LeftTop{
       cursor: nwse-resize;
       top :-5px;
       left :-5px;
    }

    .WidgetController .CenterTop{
      cursor: ns-resize;
      top :-5px;
      left: 50%;
      transform: translateX(-50%);
    }

    .WidgetController .RightTop{
      cursor: nesw-resize;
      top :-5px;
      right :-5px;
    }

    .WidgetController .LeftCenter{
      cursor: ew-resize;
      top : 50%;
      left: -5px;
      transform: translateY(-50%);
    }

    .WidgetController .RightCenter{
      cursor: ew-resize;
      top : 50%;
      right: -5px;
      transform: translateY(-50%);
    }

    .WidgetController .LeftBottom{
      cursor: nesw-resize;
      bottom :-5px;
      left :-5px;
    }

    .WidgetController .CenterBottom{
      cursor: ns-resize;
      bottom :-5px;
      left: 50%;
      transform: translateX(-50%);
    }

    .WidgetController .RightBottom{
      cursor: nwse-resize;
      bottom :-5px;
      right :-5px;
    }
    .actionGuide{
      pointer-events: none;
      display:flex;
      align-items: center;
      justify-content: center;

    }

`;

