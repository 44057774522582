// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// Image | 이미지
// 이미지를 보여주는 위젯 입니다.
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import ImageV2 from 'components/widget/ImageV2'; // 컴포넌트

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기
// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 


////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = ImageV2; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
export const Reference = {
  position: {x: "20", y: "20", show : true},
  size: {width: "400", height: "300"},
  style : {
    shadowValue: "0", 
    shadowColor: "#000000",
    shadowDirection : "RightBottom",
    morphing: true, 
    fill : "#000000",
    borderRadius : 0,
    objectFit : 'fill',
    clipNode : [],
    ...common.filterDefault,
    ...common.transformDefault,
  },
  value: {
    mediaType : 'link',
    mediaSource : null,
    option: null
  },
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
}

////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let layout = {}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))
  }
  if(property.includes('size')){
    object = Object.assign(object,common.size({widget:data.size}))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,common.border({widget:data.style}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.filter({widget:data.style}))
    // 모핑
    layout = Object.assign(layout,common.morphing({widget:data.style}))
    object = Object.assign(object,common.morphing({widget:data.style}))
    // 변형
    object = Object.assign(object,common.transform({widget:data.style}))
    // 자르기
    object = Object.assign(object,common.clipPath({widget:data.style}))
    // 이미지정렬
    object = Object.assign(object,common.objectFit({widget:data.style}))
  }
  return {layout,object}
}
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
export const Preset = null
// 프리셋을 사용하는 위젯의 경우
/* export const Preset = [
    {name:'overwatch_normal', nameKo:'오버워치 기본형 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
] */

////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////
/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset=null) {

  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'presetName':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  let output = {}
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }
  return output
}
////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////


