////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, Fragment , useContext, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';

// [리덕스]스토어 연결

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 컴포넌트 연결

// [컴포넌트] 위젯 
import TemplateStoreContainer from "components/store/template/TemplateStoreContainer"

// 서비스 연결
import * as Editor from 'service/edit/EditService' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ExtendTemplatePanel({}) {



    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    //set
    const context = useContext(EditContext);

    // state

    // rel 


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        return () => {
          console.log('Component will unmount');
        };
      }, []);


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const backEditor = () =>{
      context.setExtendEditor(null)
    }

    return (
        <Fragment>
                <style jsx>{`${StoreStyle}`}</style>
                <div id = "TemplateStorePanel" >

    
                    <div id = "TemplateBody">
                    <TemplateStoreContainer parent={'editor'} backEditor={backEditor} />
    
                    </div>

                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(ExtendTemplatePanel);
  


  const StoreStyle = `

  #TemplateStorePanel {
    display: flex;

    width: 100%;
    height: 100%;
    animation: TemplateStorePanelStart ease 1s;
    background-color: #3F3F3F;
    border-radius: 5px;
    overflow: hidden;
  }


  #TemplateNav{
    width: 200px;
    display: flex;
    height: 100%;
  }

  #TemplateNav #TemplateList{
    display: flex;
    width: 200px;
    height: 100%;
    flex-direction: column;
    line-height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: start;
    box-shadow: 1px 1px 5px #1c1c1c;
    background-color: #2C2C2C;
    border-radius: 5px;
  }

  #TemplateNav .menu{
    width: 120px;
    padding : 0px 20px;
    border-radius: 5px;
    margin : 0px 20px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #TemplateNav .menu.upload{
    background-color: orange;
  }
  
  #TemplateBody{
    display: flex;
    flex : 1;
    height: 100%;
  }

  @keyframes TemplateStorePanelStart {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
  `;