////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment ,Profiler} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결
import TemplateList from 'components/edit/other/TemplateList'; // 가이드
import TemplatePage from 'components/edit/other/TemplatePage'; // 가이드

// 서비스 연결

import * as Event from 'service/event/Actions' ; 
import * as Template from 'service/event/template' ; 
import * as Modal from 'service/event/ModalControl' ;

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class AppendTemplate extends Component {
    
    state = {
        nav : 'new',
        selectTemplate : null,
        pageField : 'append',
    }

    changeTemplate = (templateName=null) =>{
        this.setState({selectTemplate:templateName,pageField : 'append',})
    }

    goEdit =() => {
        this.props.EditscreenDispatch('Edit')
    }

    templateList = (type = 'all') =>{
        let templateList = Template.list(type).map((template) => (Template.object(template)));
        if(!this.state.selectTemplate){
            templateList = templateList.map(
                (teamplate,index,source) => (<TemplateList teamplate={teamplate} index={index} key={index} changeTemplate={this.changeTemplate}/>) 
            );    
            return templateList
        }else{
            return <TemplateList teamplate={Template.object(this.state.selectTemplate)} index={this.state.selectTemplate} key={this.state.selectTemplate} changeTemplate={this.changeTemplate}/>
            
        }
    }

    pageList = () =>{
        let templatePageList = Template.object(this.state.selectTemplate).pages
            templatePageList = templatePageList.map(
                (page,index) => (<TemplatePage alias={page.alias} data={page.data} info={page.info} key={index} index={index} />) 
            );    
            return templatePageList
    }

    userGuide = () =>{
        return <div className="TemplateUserGuide">{Template.object(this.state.selectTemplate)?.userGuide}</div>
    }

    pageForm = () => {

        let nav = 
            (
            <div className='pageFieldDetails'>
                <ul>
                    <li className={(this.state.pageField=='append'?'select':'')} onClick={()=>this.setState({pageField:'append'})} > 페이지 </li>
                    <li className={(this.state.pageField=='userGuide'?'select':'')} onClick={()=>this.setState({pageField:'userGuide'})} > 사용안내 </li>
                </ul>
                <hr></hr>
            </div>
            )
            
        switch (this.state.pageField) {
            case 'append':
                return(
                    <Fragment>
                    {nav}
                    <div className='templatePageList'>
                        {this.pageList()}
                        <div></div>
                    </div>
                    </Fragment>
                )
            case 'userGuide':
                return(
                    <Fragment>
                    {nav}
                    <div className='templatePageUserGuide'>
                        {this.userGuide()}
                    </div>
                    </Fragment>
                ) 
            default:
                break;
        }
    }

    

    render() { 
        return (
            <div id="AppendTemplate" className='mianScreen'>
                <header>
                    <div className='headerTop'>
                        <div className='headerMargin'>
                            <button className='back' onClick={()=>this.goEdit()}>
                                화면으로
                            </button>
                            <button className='back' onClick={()=>Modal.OverlayPush('TemplateModal')}>
                                구버전
                            </button>
                        </div>
                        <h2 className='title'>
                            
                        </h2>
                        <div className='headerMargin'></div>
                    </div>
                    <div  className='headerBottom'>
                        <nav className='AppendTemplateNav'>
                            <div className={'TemplateBnt' + (this.state.nav=='use'?' select':' unselect')} onClick={()=>alert('사용중인 템플릿 에서 추가 가능은 준비중입니다.')}> <h3>사용중인 템플릿에서 가저오기</h3></div>
                            <div className={'TemplateBnt' + (this.state.nav=='new'?' select':' unselect')} onClick={()=>this.setState({nav:'new'})}> <h3>다른 템플릿에서 가저오기</h3></div>
                        </nav>
                    </div>
              
                </header>
                <div className='templateMarket'>
                    <div className='searchField'> 
                    {this.state.selectTemplate!=null?<button className='back' onClick={()=>this.changeTemplate()}> 뒤로 </button>:''}
                     
                    </div>
                    <div className='templateField'>
                        {this.templateList()}
                    </div>
                    <div className='pageField'> 
                        
                        {this.state.selectTemplate!=null?this.pageForm():''}
                    </div>
                </div>
        
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({

})

function mapDispatchToProps(dispatch){
    return {
    EditscreenDispatch : (screen) => dispatch({type:'mainScreen',mainScreen : screen}),
    //   exempleDispatch : (exemple) => dispatch({type:'exempleName',exempleProps : exemple}),
    }
}

  // export default Login;
  export default connect(mapStateToProps,mapDispatchToProps)(AppendTemplate);
  