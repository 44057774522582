////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";
// [프로바이더] 연결
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"



// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import PreviewDisplay from 'components/display/PreviewDisplay' // 오버랩 디스플레이
import GuideBalloon from 'components/common/form/GuideBalloon'; // 말풍선 가이드

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Action from 'service/edit/ActionService'; 
import * as page from 'service/api/page';
import * as overlap from 'service/api/overlap';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function ChannelPanel({panelSize}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    // state
    const [ChannelList, setChannelList] = useState([]);
    const [ChannelDropDown, setChannelDropDown] = useState(false);
    const [Preview, setPreview] = useState([]);

    /* EditContext 가 비동기 로딩으로 인해서 로딩중일때 오류를 막기 위한 State */
    const [ContextReady, setContextReady] = useState(false);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        getChannel()
        getPreview()
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        getPreview()
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context?.view?.view_pointer]);
      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        getPreview()
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [context.updateTime]);
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
  
    /**
     * 편집 프로바이더에서 위젯의 목록을 가저옵니다. 
     * 이후 위젯 리스트를 만들기 위해 데이터를 가공합니다.
     */


    const actionOpneChannel = () => {
      // console.log('Showprops.view?.uuView',props.view.uuid);
      const uuid = view.uuidConverter('url',context?.view?.uuid);
      window.open(`/view/${uuid}`, '_blank');

    }

    const actionCopyChannel = async (text) => {
      try {
          await navigator.clipboard.writeText(text);
          console.log("Text copied successfully");
      } catch (err) {
          console.error("Oops, unable to copy", err);
      }
      }   
    


    const getChannel = async ()=>{
      // setChannelDropDown(true)
      const List  = await context.getChannelList()
      console.log('getChannelList',List);
      setChannelList(List)
    }

    const getPreview = async (uuid) => {
      const PreviewObject = await page.getPageForUUID(context?.view?.view_pointer?.[0]?.uuid);      
      setPreview(PreviewObject);
    }

    const selectChannel = (uuid)=>{
      getPreview(uuid);
      context.selectView(uuid);
    }

   const setDefaultChannel = (e,channelID) => {

    // 오버랩 아이디를 구하거나, 아니면
    context.modifyOverlap({meta:{'default_channel':channelID}});


    // overlap.set()
    e.stopPropagation()
   }


    const DropDownOption = (channel)  => {

      switch (channel?.view_status?.status) {
        case 'play':
          
          break;
        case 'pause':
          
          break;      
        default:
          break;
      }

    }

    
    const DropDown = () => {
      console.log('DropDown',ChannelList);
      
      
      const DropDownList = ChannelList.map((channel,index)=>( <div className='ChannelList' key={channel.uuid} onClick={()=>{selectChannel(channel.uuid); setChannelDropDown(false)}}>
          <span className='channelOption' > 
      

            {channel?.view_status?.status == 'play'? 
             Editor.icon({icon:'play_arrow',size:20,lineHeight:30})
             :null}
            {channel?.view_status?.status == 'pause'? 
             Editor.icon({icon:'pause',size:20,lineHeight:30})
             :null}
            {context?.overlap?.meta?.default_channel == channel.uuid ?
              Editor.icon({icon:'check_box',size:20,lineHeight:30})
            :null}
           </span>
           <span className='channelName' > {channel.view_name} </span>
       {/* <span className='channelResolution'> {channel.resolution_width}x{channel.resolution_height} </span>  */}
       </div> ));
      // DropDownList.push( <div className='ChannelList' key={null} onClick={()=>{alert('준비중인 기능입니다.'); setChannelDropDown(false)}}> <span className='channelName' > 채널에 연결하지 않음 </span> </div>)
      return (<Fragment> {DropDownList}</Fragment>)
    }

    let ChannelPreview = Preview 

    // 컨텍스트 
    const index = context?.overlap?.content?.order?.indexOf(context?.view?.view_pointer?.[0]?.uuid);

    if(context.selectPageUUID == context?.view?.view_pointer?.[0]?.uuid){
    }

    console.log('ChannelPreview',ChannelPreview)
    return (
        <Fragment>
                <style jsx>{`${PanelStyle}`}</style>
    
                <div id = "ChannelPanel">
                <div id = "ChannelControlLayer"> 
                  {context?.view!=null?
                  <Fragment>
                    <div className = "PreviewArea">
                    <div id = "ChannelPreview"> 
                    {ChannelPreview.length!=0? 
                    <Fragment>
                      <PreviewDisplay pageList={ChannelPreview} referenceWidth={1920} referenceHeight={1080}/>      
                      {context?.view?.view_status.status == 'pause'?
                        <div className='channelPause' style={{aspectRatio:`${1920} / ${1080}`}} >
                          {Editor.icon({icon:'pause',size:50,lineHeight:108})}
                        </div>    
                      :null}
                    </Fragment>
                    
                    :
                      <div className='voidPreview' style={{aspectRatio:`${1920} / ${1080}`}}>
                 
                        <p>선택된 페이지가 없습니다.</p>
                        <small>송출할 페이지를 선택해주세요</small>
                      </div>
                    }    
                    </div>
                    </div> 
                    <div className = "ControlArea">
                      <div class="ControlButton-container">
                        {context?.view?.view_status.status == 'play'?
                          <div class="ChannelButton-item">
                          <GuideBalloon guideText='송출을 중단합니다.' direction='right'>
                            <button className='ChannelButton' onClick={()=> Action.broadcastPause(context)} >
                            {Editor.icon({icon:'pause',size:20,lineHeight:30})}
                            {/* <svg id="broadcastIcon" width="30px" height="30px"><circle cx="15" cy="15" r="7" fill="gray" stroke="#fff" stroke-width="2"></circle></svg> */}
                            </button>
                          </GuideBalloon>
                        </div>
                        :null}
                        {context?.view?.view_status.status == 'pause'?
                        <div class="ChannelButton-item">
                        <GuideBalloon guideText='송출을 시작합니다.' direction='right'>
                          <button className='ChannelButton' onClick={()=> Action.broadcastPlay(context)} >
                          {Editor.icon({icon:'play_arrow',size:20,lineHeight:30})}
                          {/* <svg id="broadcastIcon" width="30px" height="30px"><circle cx="15" cy="15" r="7" fill="gray" stroke="#fff" stroke-width="2"></circle></svg> */}
                          </button>
                        </GuideBalloon>
                        </div>
                        :null}
                        {context?.view?.view_status.status == 'stop'?
                        <div class="ChannelButton-item">
                        <GuideBalloon guideText='송출할 페이지가 없습니다.' direction='right'>
                          <button className='ChannelButton' style={{backgroundColor:'#888'}} onClick={()=> {}} >
                          {/* {Editor.icon({icon:'play_arrow',size:20,lineHeight:30,color:'#ccc'})} */}
                          {/* <svg id="broadcastIcon" width="30px" height="30px"><circle cx="15" cy="15" r="7" fill="gray" stroke="#fff" stroke-width="2"></circle></svg> */}
                          </button>
                        </GuideBalloon>
                        </div>
                        :null}
                        
                      
                        <div class="ChannelButton-item">
                          <GuideBalloon guideText='지금 보고 있는 페이지를 송출합니다.' direction='right'>
                            <button className='ChannelButton' onClick={()=> Action.broadcast(context,context.selectPageUUID,0)} >
                            <svg id="broadcastIcon" width="30px" height="30px"><circle cx="15" cy="15" r="7" fill={context.selectPageIndex == index?"red":"gray"} stroke="#fff" stroke-width="2"></circle></svg>
                            </button>
                          </GuideBalloon>
                        </div>      
                        <div class="ChannelButton-item">
                        <GuideBalloon guideText='송출중인 페이지 입니다.' direction='right'>
                            <button className='ChannelButton' style={{background:'red',color:'#fff'}} onClick={()=>console.log('체크')} >
                              {index!=-1?index+1:<img className = "buttonLogoImage" src={logoW}/>}
                            </button>
                          </GuideBalloon>
                        </div>
                        <div class="ChannelButton-item"/>
                        <div class="ChannelButton-item">
                          <GuideBalloon guideText='새 창에서 채널 미리봅니다.' direction='right'>
                            <button className='ChannelButton' onClick={()=>actionOpneChannel()} >
                              {Editor.icon({icon:'open_in_new',size:20,lineHeight:30})}
                            </button>
                          </GuideBalloon>
                        </div>
                        <div class="ChannelButton-item">
                          <GuideBalloon guideText='채널 주소를 복사합니다.' direction='right'>
                            <button className='ChannelButton' onClick={()=>actionCopyChannel(`${window.location.protocol}//${window.location.host}/view/`+view.uuidConverter('url',context?.view?.uuid))} >
                              {Editor.icon({icon:'link',size:20,lineHeight:30})}
                            </button>
                          </GuideBalloon>
                        </div>
                      </div>
                    </div> 
                  </Fragment>
                  :
                  <Fragment>
                    <div className = "ChannelControlGuide">
                    <svg width="60px" height="60px" xmlns="http://www.w3.org/2000/svg" version="1.1"><circle cx="30" cy="30" r="23" fill="#666" stroke="#aaa" stroke-width="7" filter="url(#shadow)"></circle></svg>
                    <div>
                        선택된 채널이 없습니다.<br/> 송출을 위해 아래에서 채널을 선택해주세요
                      </div>
                    </div>

                  </Fragment>

                  }
                  

                </div>

                <div id = "ChannelSelectLayer"> 
                {
                  ChannelDropDown?
                  <div className = "ChannelDropDownList"> 
                  {DropDown()}
                </div>
                :null
                }

                <div className="SelectedChannel" onClick={()=>setChannelDropDown(!ChannelDropDown)}> 

                <div className="defaultbox">
                  {!context?.view?.uuid?
                      null
                  :
                    <div className='defaultChannel' onClick={(e)=>setDefaultChannel(e,context?.view?.uuid)}>
                    <GuideBalloon guideText={<p>오버랩 시작 시 선택한 채널과 <br/>자동으로 연결합니다.</p>} direction='topleft' background="#3F3F3F">
                    {context?.overlap?.meta?.default_channel == context?.view?.uuid && context?.overlap?.meta?.default_channel?
                      Editor.icon({icon:'check_box',size:20,lineHeight:25})
                    :
                      Editor.icon({icon:'check_box_outline_blank',size:20,lineHeight:25})
                    }
                    </GuideBalloon>

                    </div>

                  }
                </div>


                {context?.view==null?
                <Fragment>
                  여기서 채널을 선택하세요
                </Fragment>
                :
                <Fragment>
                  {context?.view?.view_name}
                </Fragment>
                }
                 
                 <div className="moreBox">
           
                {Editor.icon({icon:'expand_more',size:20,lineHeight:25})}
                </div>
                </div>
     
   
                </div>


                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(ChannelPanel);
  


  const PanelStyle = `

  #ChannelPanel{
      width: 100%;
      height: 100%;
      position: relative;
  }

  #ChannelPanel #SelectChannel{

  }

  #ChannelControlLayer{
    display : flex ;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index : 1;
    top : 0;
  }
  #ChannelControlLayer .PreviewArea{
    width: 220px;
    height: 160px;
    box-sizing: border-box;
    padding :20px 0px 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #ChannelControlLayer .ControlArea{
    width: 100px;
    height: 160px;
    padding: 20px 20px 20px 0px;
    box-sizing: border-box;
  }

  #ChannelControlLayer .ControlButton-container {
    display: grid;
    grid-template-rows: auto auto; /* 2행 */
    grid-template-columns: 1fr 1fr; /* 3열 */


  }
  #ChannelControlLayer .ChannelButton-item{
    width: 40px;
    height: 40px;
  }

  #ChannelControlLayer .ChannelButton-item .ChannelButton{
    width: 30px;
    height: 30px;
    margin : 5px;
    background-color: #3F3F3F;
    border-radius : 5px;
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.2), /* 상대적으로 가까운 그림자 */
        0 1px 3px rgba(0, 0, 0, 0.1); /* 멀리 떨어진 더 퍼진 그림자 */
    transition: transform 0.3s ease, box-shadow 0.3s ease;    
    overflow : hidden; 
  }

  #ChannelControlLayer .ChannelButton-item .ChannelButton:active{
    transform: scale(0.8);
  }

  .PageButton:hover {
    transform: translateY(-2px); /* 버튼을 위로 약간 올림 */
    
    /* 호버시 그림자 변경 */
    box-shadow: 
        0 6px 8px rgba(0, 0, 0, 0.25),
        0 2px 4px rgba(0, 0, 0, 0.15);
}

  #ChannelControlLayer .ChannelControlGuide{
    width: 100%;
    height: calc(100% - 40px);  
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align : center;
    display : flex ;
    flex-direction : column;
    color :gray;
  }
  #ChannelPreview{
    width: 192px;
    height: 108px;
    position: relative;
  }

  #ChannelPreview .channelPause{
    height: 100%; 
    position:absolute;
    top : 0;
    display : flex;
    justify-content : center;
    align-items : center;
    background : #00000099;
  }

  #ChannelControlLayer .buttonLogoImage{
    margin : 5px;
    width: 20px;
    height: 20px;
  }

  #ChannelSelectLayer{
    display : flex ;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    z-index : 2;
    top : 0;
  }

  #ChannelSelectLayer .SelectedChannel  { 
    bottom : 10px;
    position: absolute;
    background-color: #343434;
    width: calc(100% - 40px);
    left : 20px;
    height: 30px;
    margin : auto;
    border-radius : 5px;
    display : flex ;
    justify-content: space-between;
    align-items : center;
    pointer-events: auto;
  }

   #ChannelSelectLayer .SelectedChannel .defaultbox {
    width: 20px;
    height: 20px;
    margin : 5px;

    display : flex ;
    justify-content: center;
    align-items : center;

  } 

  #ChannelSelectLayer .SelectedChannel .moreBox {
    width: 20px;
    height: 20px;
    margin : 5px;
    background-color: #1C1C1C;
    display : flex ;
    justify-content: center;
    align-items : center;
    border-radius : 5px;
  } 

  #ChannelSelectLayer .ChannelDropDownList{
    background-color: #343434;
    position: absolute;
    left : 20px;
    bottom : 45px;
    width: calc(100% - 40px);
    pointer-events: auto;
    border-radius : 5px;
  }

  #ChannelDropDown{
    width: calc( 100% - 20px ) ;
    top : 30px;
    padding : 0px  10px ;
    background-color: #343434;
    transition : height 0.3s;
    overflow: auto;
    border-radius : 0px 0px 10px 10px;
  }

  .ChannelList{
    display : flex;
    justify-content: space-between;
    text-align : right;
    height: 30px;
    padding : 2.5px 10px;
    line-height: 30px;
    margin : 5px 0px;
    box-sizing: border-box;

  }
    .ChannelList .channelOption{
    display : flex;
  }
  .ChannelList:hover{
    background-color: #2c2c2c;
    
  }

  .ChannelList .channelName{
    font-size : 17px;
  }

  .ChannelList .channelResolution{
    background-color: #1C1C1C;
    font-size : 10px;
    padding : 2px;
    border-radius : 2px;
  }

  #ChannelControlLayer .PreviewArea .voidPreview {
    background-color: #333;
    border-radius : 5px;
    color : #aaa;
    display : flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }
  `;