////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useRef, memo } from 'react';
import ContentEditable from 'react-contenteditable';
import "sass/widgetEdit.scss";
import { icon } from 'service/edit/EditService';
import * as TextEditor from 'service/edit/TextEditorService';
import * as ED from 'service/engine/decoder/EditorDecoder';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility'; 
import { style } from 'service/model/widget/UnknownModel';
import { Tune } from '@material-ui/icons';
import { coordinateSystem } from 'service/event/widget';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

const TextEditV2Form = memo(({ target,  modify, keyName, title, option }) => {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    const PrevValueRef = useRef(null);
    const ModifyRef = useRef(false); // 스타일을 최근에 변경했는지 체크합니다.
    const HTMLRef = useRef(null);
    const corserRef = useRef(null);
    const { text } = keyName;
    const [Toggle, setToggle] = useState(option?.openToggle == undefined ? true : option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm == undefined ? true : option?.openForm);

    const [DefaultTextStyle, setDefaultTextStyle] = useState({'color':'#000000','fontfamily':'프리텐다드SemiBold','fontSize':50,'fontWeight':'normal'});

    const [CorserState, setCorserState] = useState({start:null, end:null ,area:null});
    // const [DefaultParagraphStyle, setDefaultParagraphStyle] = useState({'color':'','fontfamily':'','fontSize':'','fontSize':'fontWeight' });
    const [html, setHtml] = useState('');
    const contentEditable = useRef(null);



    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
      const Value_HTML = TextEditor.decoder(ED.getValue(target, text));
      PrevValueRef.current = ED.getValue(target, text);
      if (contentEditable.current) {
        contentEditable.current.focus();
      }
      setHtml(Value_HTML);
    }, []);


    useEffect(() => {
      restoreSelection();
    }, [html]);

    useEffect(() => {
      restoreSelection();
    }, [CorserState]);

    useEffect(() => {
    }, [DefaultTextStyle]);

    ////////////////////////////////////////////////////////
    // Handlers
    ////////////////////////////////////////////////////////

    // 새로운 글자를 입력시 실행되는 구문
    const textOnChange = (evt) => {
       

        HTMLRef.current = evt.target.value;
        const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultTextStyle,{styleModify:ModifyRef.current&&evt.type=='input'});
        if(ModifyRef.current&&evt.type=='input'){
     
          const LastValue = PrevValueRef.current[PrevValueRef.current.length - 1];
          let targetIndex = Value_JSON.findIndex(obj => obj.styleKey === LastValue.styleKey);
          console.log('LastValue',LastValue,targetIndex,Value_JSON.length);
          if(Value_JSON.length - targetIndex > 0){
            ModifyRef.current= false
          }

          // ModifyRef.current= false
        }
  
        const Value_HTML = TextEditor.decoder(Value_JSON);
        console.log('마지막 Value_JSON',Value_JSON)
        PrevValueRef.current = Value_JSON
        HTMLRef.current = Value_HTML
        if(Value_JSON.length == 0){
          setHtml(Value_HTML)
        }
        // ModifyRef.current = false
        modify(text.category, text.key, Value_JSON, target.uuid,{log:'textOnChange'});
        // modify(text.category, text.key, Value_JSON, target.uuid);


    };

    const pushEnter = () => {
  
      const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultTextStyle,{enter : corserRef.current?.start});
      // 이전 스타일 가지고 오기
      const prevText = Value_JSON[corserRef.current?.start - 1]?? Value_JSON[corserRef.current?.start];
      // 공백추가
      Value_JSON.splice(corserRef.current?.start, 0, { char: '\n', style: prevText?.style });
      corserRef.current.start = corserRef.current.start + 1;
      corserRef.current.end = corserRef.current.end + 1;
      const Value_HTML = TextEditor.decoder(Value_JSON);
      PrevValueRef.current = Value_JSON
      HTMLRef.current = Value_HTML;

      setHtml(Value_HTML);

      modify(text.category, text.key, Value_JSON, target.uuid,{log:'pushEnter'});
    } 

    const endEdit = () => { 
      // 문제 요인 여기서 HTML을 가지고 최신 스타일 반영불가
      const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultTextStyle);
      const Value_HTML = TextEditor.decoder(Value_JSON);
      PrevValueRef.current = Value_JSON
      HTMLRef.current = Value_HTML;
      setHtml(Value_HTML);
   
      modify(text.category, text.key, Value_JSON, target.uuid,{log:'endEdit'});
      
    } 

    const handleCursorChange = (option) => {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(contentEditable.current);

        preCaretRange.setEnd(range.startContainer, range.startOffset);
        
        const getLineBreak = (range) => {
          const div = document.createElement('div');
          div.appendChild(range.cloneContents());
          return (div.innerHTML.match(/<br\s*\/?>/gi) || []).length;
        };

        const startLineBreak = getLineBreak(preCaretRange);
        const start = preCaretRange.toString().length + startLineBreak;
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        const endLineBreak = getLineBreak(preCaretRange);
        const end = preCaretRange.toString().length + endLineBreak;

        const isCollapsed = range.collapsed
        corserRef.current = { start, end ,area: !isCollapsed};
        if(option?.refresh) {
          contentEditable.current.blur();

        
          // 사용자가 방금 수정하지 않았다면
        if(end&&ModifyRef.current==false){
          const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultTextStyle);
          setDefaultTextStyle(Value_JSON[end-1]?.style??{'color':'#000000','fontfamily':'프리텐다드SemiBold','fontSize':50,'fontWeight':'normal'}) 
        }
    

          setCorserState(corserRef.current);
          setHtml(HTMLRef.current);
        }
      }
    };

    
    const restoreSelection = () => {
      // contentEditable 요소와 커서 참조가 존재하는지 확인합니다.
      if (contentEditable.current && corserRef.current?.start != null && corserRef.current?.end != null) {
        try {
          // 새 Range 객체를 생성합니다.
          const range = document.createRange();
    
          // Range의 시작 위치와 끝 위치를 설정합니다.
          range.setStart(contentEditable.current.childNodes[corserRef.current.start], 0);
          range.setEnd(contentEditable.current.childNodes[corserRef.current.end], 0);
    
          // 현재 선택된 범위를 초기화합니다.
          const selection = window.getSelection();
          selection.removeAllRanges();
    
          // 새로 만든 Range를 선택합니다.
          selection.addRange(range);
    
          // contentEditable 요소를 블러 처리한 후 포커스를 다시 맞춥니다.
          contentEditable.current.blur();
          contentEditable.current.focus();
          
        } catch (error) {
          // 에러가 발생하면 콘솔에 에러를 출력합니다.
          console.error(error);
        }
      }
    };

    const handleKeyDown = (e) => {

      switch (e.key) {
        case 'Enter':
          if(!e.nativeEvent.isComposing){
            e.preventDefault();
            pushEnter();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
        case 'ArrowRight':
        case 'ArrowDown':

          // pushEnter();
          break;      
        default:
          break;
      }
    };

    const handleKeyUp = (e) => {
      switch (e.key) {
        case 'Enter':
          if(!e.nativeEvent.isComposing){
  
          }else{
            handleCursorChange();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          handleCursorChange({refresh:true,dragDirection:'left'});
          break;      
        case 'ArrowRight':
        case 'ArrowDown':
          handleCursorChange({refresh:true,dragDirection:'right'});
          break;      
        default:
          handleCursorChange();
          break;
      }
  
      if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
        console.log('검사 패스', corserRef.current);
      } else {
  
      }
    };


    /**
     * CharStyleModify2
     */
    const CharStyleModify2 = (key,value) => {
      restoreSelection();

      if(corserRef.current?.area){

        const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultTextStyle);
        const Start = corserRef.current.start > corserRef.current.end ? corserRef.current.end : corserRef.current.start;
        const End = corserRef.current.start > corserRef.current.end ? corserRef.current.start : corserRef.current.end;
  
        for (let index = 0; index < Value_JSON.length; index++) {
          if (index >= Start && index < End){
            const char =  Utility.deepCopy(Value_JSON[index]);
            if (char.style) {
              char.style.color = value ?? '#000';
            } else {
              char.style = { color: value ?? '#000' };
            }
            Value_JSON[index]= char;
          }
        }
  
        const Value_HTML = TextEditor.decoder(Value_JSON);
        PrevValueRef.current = Value_JSON
        HTMLRef.current = Value_HTML;
        modify(text.category, text.key, Value_JSON, target.uuid,{log:'CharStyleModify - 영역 스타일 변경'});
        setHtml(Value_HTML);
        const NextDefaultTextStyle = Utility.deepCopy(DefaultTextStyle);

        NextDefaultTextStyle[key] = value ;
        setDefaultTextStyle(NextDefaultTextStyle) 
      }else{

        const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultTextStyle);
        const Value_HTML = TextEditor.decoder(Value_JSON);
        modify(text.category, text.key, Value_JSON, target.uuid,{log:'CharStyleModify - 기본 스타일 변경'});
        setHtml(Value_HTML);

        const NextDefaultTextStyle = Utility.deepCopy(DefaultTextStyle);
        ModifyRef.current = true;
        NextDefaultTextStyle[key] = value ;
        setDefaultTextStyle(NextDefaultTextStyle) 
      }
      
    }

 


    const TextHandleNav = (targetStyle) => {
      return (
        <nav className='TextEditNav'>
        {/* 폰트 만들기 */}
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
            {Editor.icon({icon:'format_bold',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_italic',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_underlined',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_bold',size:20,lineHeight:25})}
          </div>
        </button>

        <button className='TextEditNavButton SizeFrom'>
          <input className='SelectColorCode' type='number' style={{color:'ValidColor'?"#FFFFFF":"#FF0000"}}  maxLength="7"  value={'10'} onChange={(e)=>console.log(e.target.value.toUpperCase())}/>
        </button>

        <button className='TextEditNavButton ColorForm'>
          <input type="color" value={DefaultTextStyle?.color??'#000000'} onChange={(e) => CharStyleModify2('color',e.target.value.toUpperCase())} />
          {/* <input className='SelectColorCode' style={{color:'ValidColor'?"#FFFFFF":"#FF0000"}}  maxLength="7"  value={DefaultTextStyle?.color??'#000000'} onChange={(e)=> CharStyleModify('color',e.target.value.toUpperCase(),corserRef.current.start??null,corserRef.current.area?corserRef.current.end:null)}/> */}
        </button>
        {/* 
        <button className='TextEditNavButton FontForm'>
          <input type="color" value={'value'} onChange={(e) => console.log(e.target.value.toUpperCase())} />
          <input className='SelectColorCode' style={{color:'ValidColor'?"#FFFFFF":"#FF0000"}}  maxLength="7"  value={'#ffffff'} onChange={(e)=>console.log(e.target.value.toUpperCase())}/>
        </button> 
        */}
        </nav>
      )
      
    }

    const TargetStart = CorserState?.start??null
    const TargetEnd = CorserState?.end??null
    const TargetArea = CorserState?.area??false

    const TargetChar = TargetStart? PrevValueRef?.current?.[TargetStart]?.char : null;
    const TargetStyle = TargetStart? PrevValueRef?.current?.[TargetStart]?.style : null;
    return (
      <section className='TextEditV2Form'>
        <style jsx>{`${FormStyle}`}</style>
        <div className={OpenForm ? 'editContainer containerOpen' : 'editContainer containerClose'}>
          <article className="EditPart TextEditor">
            {TextHandleNav(TargetStyle)}
            <ContentEditable
              className='ContentEditable'
              innerRef={contentEditable}
              html={html + `<BR data-key="null"/>`}
              disabled={false}
              onChange={textOnChange}
              onMouseLeave={()=>handleCursorChange({refresh:false})}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onMouseUp={()=>handleCursorChange({refresh:true})}
              tagName="div"
              style={{padding: '10px', minHeight: '100px' ,...DefaultTextStyle,fontSize:15}}
            />
          </article>
        </div>
        {/* <div>
          컨트롤 박스
          {!TargetArea?
            <p>단어 : {TargetChar} 	&#91;{TargetStart}&#93;   </p>
            :
            <p>단어 : {TargetChar} 	&#91;{TargetStart}&#93; ~ {TargetChar} 	&#91;{TargetEnd}&#93;   </p>
          }

          <p>KEY : {JSON.stringify(TargetStyle)} </p>
          <p>KEY : {JSON.stringify(DefaultTextStyle)} </p>

          
        </div>
        <div>
          <p>선택 상태:{corserRef?.current?.area?'네':'아니요'}</p>
          <p>Start:  {CorserState.start}</p>
          <p>End: {corserRef?.current?.end??'null'}</p>
        </div>
          <textarea>{html}</textarea>
        <div dangerouslySetInnerHTML={{ __html: html }} /> */}
      </section>
    );

}, () => true);

export default TextEditV2Form;

const FormStyle = `
.TextEditV2Form #EditControl {
  width : 100%;
  height: 100%;
  position: absolute;
  z-index : 2;
  outline: none;
}

.TextEditV2Form .ContentEditable {
  /** background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, #ccc 25%, #ccc 75%, #fff 75%, #fff); 
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px; */ 
  background : #fff;
  border-radius : 5px;
  outline: none;
  border: none;
  margin : 10px 0px;
}

.TextEditV2Form .TextEditNav{
  margin : 10px 0px;
  display: flex;
  flex-wrap: wrap;
  background : #222;
  border-radius : 5px;
}
.TextEditV2Form .TextEditNavButton{
    height: 25px;
    margin: 5px;
    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TextEditV2Form .TextEditNavButton.OptipnButton:active{
    transform: scale(0.8);
  }

.TextEditV2Form .SizeFrom{
    height: 25px;
    width : 50px;
}
.TextEditV2Form .SizeFrom input{
    height: 25px;
    width : 50px;
    font-family: "프리텐다드SemiBold", sans-serif;

        text-align: center;
}


.TextEditV2Form .SingleButton{
    height: 25px;
    width : 25px;
}

.TextEditV2Form .TextEditNavButton input{
    background-color: transparent;
    border: none; 
    height: 25px;
    font-family: "프리텐다드SemiBold", sans-serif;

}

.TextEditV2Form .ColorFrom{
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TextEditV2Form .ColorForm .SelectColorCode{
  height: 20px;
  width: 70px;
  padding : 0px;
  margin : 0px; 
  font-family: "프리텐다드SemiBold", sans-serif;
  font-size : 14px;
  line-height : 20px;
  text-align : center;    
  outline: none;
}




.TextEditV2Form .ColorForm input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 20px;
  height: 20px;
  margin : 2.5px;
  border-radius: 2px;
  cursor: pointer;
  padding: 0;
}

/* Remove default internal padding and appearance for webkit browsers */
.TextEditV2Form .ColorForm input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 5px;
}
.TextEditV2Form .ColorForm input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

`;






const Counter = ({val}) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {val}
    </div>
  );
};
