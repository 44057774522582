////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결


// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
import Tutorial from "pages/Tutorial"
import MiniView from "components/viewer/MiniView"
import SpaceBox from "components/lobby/space/SpaceBox"


// 서비스 연결

import * as Space from 'service/api/space' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SpaceShare extends Component {
    
    state = {
        userId : '',
        screenData : [{"type":"Text","version":1,"uuid":"cd0155f64c81477dad4511694c54a203","alias":null,"data":{"position":{"show":true,"x":"555","y":"322"},"style":{"textAlign":"center","fontSize":"200"},"size":{},"value":{"text":"테스트중!!!!!!!!!!"},"preset":null,"display":{},"meta":{}},"animation":{"mountStart":null,"mount":null,"mountEnd":null}}],
        zoom : 0,
        infoData : null,
        spaceList : null,
    }

    async componentDidMount () {
        await this.getSpace()
    }

    constructor(props) {
        super(props);
        this.broadcast = React.createRef();
      }

    componentDidUpdate(prevProps){
        
    }

    copy = async (value) => {
        if (navigator.clipboard) {
           await navigator.clipboard.writeText(value).then(() => {
                alert('복사되었습니다. 이제 붙여넣으세요')
            });
          } else {
            alert('구형브라우저를 사용중이라 복사에 실패하였습니다.')
          }
    }

    addSpace = async (title)=>{
   

    }

    getSpace = async ()=>{
        const space = await Space.getSpace();
        const list = space.map(
            (space,index) => (
                <SpaceBox 
                    spaceId={space.id} 
                    name={space.space_name} 
                    description={space.space_description}
                    image={space.space_image} 
                    plan={space.plan} 
                    created={space.created_at}
                    updated={space.updated_at}
                    />
            ) 
        ); 

        this.setState({
            spaceList : list
        })
    }

    spaceList = ()=>{

    }

    render() { 
        return (
            <div className = "Lobby_innerPage" >
            <div id = "Lobby_Dashboard" >
            <div id = "Dashboard_SpaceList">
                    <h3 className="title">
                    나의 스페이스
                    </h3>
                    {this.state.spaceList}
                </div>
                <div onClick={()=>this.addSpace()}>
                    새로운 스페이스 생성하기
                </div>
                {/* <div id = "Dashboard_Broadcast">
                    <h3 className="title">
                     최근 사용
                    </h3>
                </div>

                <h3 className="title">
                    새로만들기
                </h3>
                 */}
                
            </div>
            </div>
        )
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  // export default Login;
  export default SpaceShare;
  