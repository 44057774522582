////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/widgetEdit.scss"

// 컴포넌트 연결
import WidgetDisplay from 'components/display/WidgetDisplay' // 위젯 전용 디스플레이
import PositionModule from '../editModule/PositionModule'; // 위젯의 사이즈를 조정 하는 모듈입니다.
import ErrorModule from '../editModule/ErrorModule'; // 잘못된 위젯의 값이 있을때 편집기를 대체합니다.
import GuideBalloon from 'components/common/form/GuideBalloon'// 가이드 벌룬

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as ED from 'service/engine/decoder/EditorDecoder'; 
import * as DeCoder from 'service/engine/DeCoder'; 
// 이미지 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


/** [에디터] 텍스트 편집 툴 입니다.
 * (대상위젯, 편집펑션, 편집할 객체 키, 타이틀 ,옵션)
* @param {widget} target : 편집할 대상 위젯
* @param {function} modify : 편집용 함수
* @param {{
* style: { x : number, y : number }
* }} keyName : 해당 에디터가 수정하는 값 입니다. value : {'textValue', 수정할 대상값,} 
* @param {{
* editTitle: string,textValue: string
* }} title : 편집기에 표시될 제목들 입니다.
* @param {{
* fixToggle : boolean 
* openToggle : boolean
* }} option : 추가 옵션입니다. fixToggle : 토글을 고정합니다. openToggle : 필드의 기본 상태를 열림으로 봅니다.
*/


function AssetEditForm({target,modify,keyName,title,option,func}) {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // set
    const uuid = target.uuid

    // state
    const [Toggle, setToggle] = useState(option?.openToggle==undefined?true:option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm==undefined?true:option?.openForm);

    const [ShowList, setShowList] = useState(false);
    // rel 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {

        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    // 해당 에디터에서 편집이 가능한 값 목록
    const {asset} = keyName ;

    const AssetList = DeCoder.getAsset({target});
    const TargetAsset = ED.getValue(target,asset)




    const preViewAsset = (assetData) => {
        const PreView =[];
        if(assetData){
          if(assetData?.[0]?.image){
            PreView.push(<div className='assetPreviewImage' ><img src={assetData?.[0]?.image}/></div>)
          }
          if(assetData?.[1]?.image){
            PreView.push(<div className='assetPreviewImage' ><img src={assetData?.[1]?.image}/></div>)
          }
          if(assetData?.[2]?.image){
            PreView.push(<div className='assetPreviewImage' ><img src={assetData?.[2]?.image}/></div>)
          }
        }
        return PreView;
      }
  
  

    const TempAsset = AssetList.filter((preset) => preset.name == TargetAsset);

    const NowAsset = TempAsset?.[0];

    const List = (
      <Fragment>
      <div class="tailArea">
          <div class="tail"/>
      </div>
      {NowAsset.length != 0 ?
      <div className="AssetSelectList"  onClick={(e)=> e.stopPropagation()} >
      {NowAsset?.data.map((data,index) => (

      <div className='AssetList' >
                {data?.image?
                <img src={data?.image}/>:
                <div className='noImage'> {Editor.icon({icon:'account_circle',size:25,lineHeight:30})} </div>
                }
                <p>{data?.nameKo}</p>
              </div>

      ))}
      </div>
      :
      <div className='AssetSelectMessage '>
          <p> 생성된 팀이 없습니다.</p>
          
      </div>
      }
      </Fragment>
  )



    return (
    <Fragment>
        <style jsx>{`${FormStyle}`}</style>
        <section className='AssetEditForm'   onClick={()=>setShowList(false)}>
            {Editor.editorDetail('에셋','style',OpenForm,setOpenForm,Toggle)}
            <div className={OpenForm?'editContainer containerOpen':'editContainer containerClose'}>

                <article className="EditPart" >
        
                      <div className='assetBox' onClick={(e)=>{setShowList(!ShowList); e.stopPropagation();}}>
                        <div className='assetPreview'>
                        {preViewAsset(NowAsset?.data)}
                        </div>
                        <div className='assetText'>
                        <div className='TitleField'>
                            <h3 className='assetTitle'>
                            {NowAsset?.nameKo}
                            </h3>
                        </div>
                        </div>
                    </div>
                    {ShowList?List:null}
                    <div className='changeField'>
                    <button className='changeButton' onClick={()=>func.moveTab('asset')}>
                        {Editor.icon({icon:'autorenew',size:25,lineHeight:30})}
                        <p>
                        변경하기
                        </p>
                        
                    </button>
                    </div>

                </article>
            </div>
        </section>
    </Fragment>
    );

}

export default AssetEditForm;

const FormStyle = `
.AssetEditForm{

}
.AssetEditForm .changeField{
    width: 100%;
    display :flex;
    justify-content : center;
}

.AssetEditForm .changeField .changeButton{
    width: 100%;
    margin : 10px 0px ;
    
    display :flex;
    justify-content : center;
    align-items : center;
    border-radius : 5px;
    background: #262626 ;
}




.AssetEditForm .assetBox{
    width: calc(100% - 20px);
    background-color:rgb(63, 63, 63);
    border-radius : 10px;
    display:flex;
    background-color: #333;
    margin : 10px 0px;
    padding : 10px;

  }

  .AssetEditForm .assetBox:hover{

    background: rgba(255, 153, 0, .5);

  }


  .AssetEditForm .assetPreview{
    width: calc(50%);
    height : 50px;
    align-items: center;
    display:flex;
    justify-content: space-between;
  }

  .AssetEditForm .assetPreview .assetPreviewImage{
    background-color: #444;
    border-radius : 5px;
    box-shadow: 3px 3px 3px #222;
    overflow: hidden;
  }


  .AssetEditForm .assetPreview .assetPreviewImage:nth-child(1) {
    width: 40px;
    height: 40px;
    transform: scale(1.3);
    order : 2;
  }


  .AssetEditForm .assetPreview .assetPreviewImage:nth-child(2) {
    width: 40px;
    height: 40px;
    order : 1;
  }


  .AssetEditForm .assetPreview .assetPreviewImage:nth-child(3) {
    width: 40px;
    height: 40px;
    order : 3;
  }


  .AssetEditForm .assetPreview .assetPreviewImage img{
    display:flex;
    width: 100%;
    height : 100%;
    object-fit : cover;
  }

  .AssetEditForm .assetText{
    width: 50%;
    border-radius : 10px;
    display:flex;
    justify-content: end;
  }


  .AssetEditForm .assetText .TitleField{
    width: 100%;

  }

  .AssetEditForm .assetText .assetTitle{
    width: 100%;
    height : 100%;
    display :flex;
    justify-content : center;
    align-items : center;
  }

  
.AssetEditForm  .tailArea{
    width: 100%;
    height : 20px;
    display: flex;
    justify-content: center;
}
.AssetEditForm  .tailArea .tail{
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 20px 20px 20px; /* 삼각형 모양 */
    border-color: transparent transparent #222 transparent; /* 아래쪽 삼각형 */
}

.AssetEditForm  .AssetSelectList{
  width: 100%;
  overflow: auto;
  height: 200px;
  background-color: #222;
  display: grid;
  grid-template-columns: repeat(auto-fill, 55px); /* 그리드 컬럼 크기를 60px로 설정 */
  box-sizing: border-box;
  justify-content: center; /* 그리드 컨테이너 내에서 아이템들을 중앙 정렬 */
  gap: 5px; /* 그리드 아이템 간 갭을 10px로 설정 */
  padding: 10px 5px; /* 컨테이너 패딩 설정 */
  border-radius : 10px;
}

.AssetEditForm .AssetSelectMessage{
  width: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;
  align-items: center;
  height : 60px;
  background-color: #222;
}

.AssetEditForm .AssetList{
  position: relative;
  width: 55px;
  height : 55px;
  background-color: #333;
  border-radius : 10px;
  overflow: hidden;
}

.AssetEditForm .AssetList img{
  width: 55px;
  height : 55px;
  object-fit: cover;
}

.AssetEditForm .AssetList .noImage{
  width: 55px;
  height : 30px;
  padding-top : 5px;
  display:flex;
  justify-content : center;
  align-items : center;

}
.AssetEditForm .AssetList p{
  width: 100%;
  height : 20px;
  position: absolute;
  bottom : 0px;
  font-size : 10px;
  display :flex;
  justify-content : center;
  align-items : center;
  transition: all 0.3s ;
  font-family: "프리텐다드Black", sans-serif;
  text-shadow: 1px 1px 1px black;
}
.AssetEditForm .AssetList:hover p{
  overflow: hidden;
  width: 100%;
  height : 100%;
  font-size : 15px;
  bottom : 0px;
  backdrop-filter: saturate(180%) blur(20px);
}

`;