// 리엑트 연결

import { widget } from "service/value/Model";

// 서비스 연결
import _ from 'lodash'; // lodash import
import * as Utility from 'service/other/Utility';
import * as widgetModel from 'service/model/widgetModel'
import * as EeCoder from 'service/engine/EnCoder'; 
import * as DeCoder from 'service/engine/DeCoder' ;  
////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////
import store from "store";

const templateClick = value => ({type:'templateClick',click : value})
const selectTemplate = id => ({type:'selectTemplate',select : id})
////////////////////////////////////////////////////////

// 테스트 구문
export function connectTest() {
    console.log('Eecoder : 연결테스트')
  }


function deepMerge(obj1, obj2) {
const output = { ...obj1 };

for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key]) && obj1.hasOwnProperty(key)) {
            output[key] = deepMerge(obj1[key], obj2[key]);
        } else {
            output[key] = obj2[key];
        }
    }
}
return output;
}


export function styleMerge(original,addition) {
    const result = {};
    // obj1의 키들을 result에 가
    for (let key in original) {
      if (original.hasOwnProperty(key)) {
        if (addition.hasOwnProperty(key)) {
          // 키가 obj2에도 존재하는 경우
          if (typeof original[key] === 'object' && typeof addition[key] === 'object') {
            // 두 값이 모두 객체인 경우 병합
            result[key] = { ...original[key], ...addition[key] };
          } else {
            // 그 외의 경우 obj2의 값을 사용
            result[key] = addition[key];
          }
        } else {
          // 키가 original 에만 존재하는 경우
          result[key] = original[key];
        }
      }
    }
  
    // obj2에만 존재하는 키들을 result에 추가
    for (let key in addition) {
      if (addition.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
        result[key] = addition[key];
      }
    }
  
    return result;

}


/**
 * 레퍼런스와 병합된 오버랩 위젯을 가지고 옵니다. 
 * @param {*} context 
 * @param {*} uuid 
 * @returns widgetOBJ || null
 */
export function get(context,uuid) {
    const widgetID = uuid;
    const Page = context.nowPage()?.page_widget??[];
    const target = Page.filter(widget => widget.uuid === widgetID);
    if(target){
        return DeCoder.getMergeWidget(target[0]);
    }else{
        return null;
    }
  }


/** modify | 위젯의 값을 수정합니다.
 * @param {Provider} context EditProvider
 * @param {Array<number>|number|null} uuid 선택된 위젯의 uuid | null 인경우 선택된 위젯이 없음
 * @param {object<OverlapWidget>} widget 선택된 위젯의 uuid | null 인경우 선택된 위젯이 없음
 */
export const modify = (context,uuid,widget)=>{
    let modifyWidgetList = context?.nowPage().page_widget;  
    if (Array.isArray(uuid)){
        console.error('EditControl>modifyWidget 에서의 오류, uuid 리스트(그룹인경우) 받아 수정하는 로직은 아직 만들기 전입니다.');
        return;
    }else{
        const widgetIndex = modifyWidgetList.findIndex(item => item.uuid === uuid);
        if(!uuid){
            console.error('선택된 위젯의 값이 잘못되었습니다. 선택된 위젯의 UUID 값 : ',modifyWidgetList);
            return;
        }
        const MergeWidget = deepMerge(modifyWidgetList[widgetIndex].data,widget);
        modifyWidgetList[widgetIndex].data = MergeWidget;
        context.modifyPage({'page_widget':modifyWidgetList},'widget.modify를 통한 수정')
    }
}

/** alias | 위젯의 값을 수정합니다.
 * @param {Provider} context EditProvider
 * @param {Array<number>|number|null} uuid 선택된 위젯의 uuid | null 인경우 선택된 위젯이 없음
 * @param {string} alias 선택된 위젯의 uuid | null 인경우 선택된 위젯이 없음
 */
export const alias = (context,uuid,alias)=>{

    let modifyWidgetList = context?.nowPage().page_widget;  

    if (Array.isArray(uuid)){
        console.error('EditControl>modifyWidget 에서의 오류, uuid 리스트(그룹인경우) 받아 수정하는 로직은 아직 만들기 전입니다.');
        return;
    }else{
        const widgetIndex = modifyWidgetList.findIndex(item => item.uuid === uuid);
        const WidgetType =modifyWidgetList?.[widgetIndex]?.type;
        const OldName =modifyWidgetList?.[widgetIndex]?.alias?`alias_${WidgetType}_${modifyWidgetList?.[widgetIndex]?.alias}`:`uuid_${WidgetType}_${uuid}`;
        const NewName =alias? `alias_${WidgetType}_${alias}`:`uuid_${WidgetType}_${uuid}`;
        console.log('이름변경:',OldName,NewName)
        context.reNameChannelCache(OldName,NewName)
        modifyWidgetList[widgetIndex].alias = alias;
        context.modifyPage({'page_widget':modifyWidgetList},'modifyWidget.alias')
        
    }
}


/** create | 위뎃의 데이터를 기반으로 위젯을 생성합니다.
 * @param {Provider} context EditProvider
 *  */ 

export function create(context,createData) {
    console.log('widgetcreate',context,createData)


    if(!createData){
        return;
    }


    const WidgetName = createData?.widget??null;
    let x = createData?.positionX??null;
    let y = createData?.positionY??null;
    let preset = createData?.preset??null;
    let version = createData?.version??null;
    let value = createData?.value??{};
    let option = createData?.option??null;

    

    const Resolution = context?.overlap?.meta?.resolution
    let reference =  widgetModel?.getReference()?.[WidgetName]

    if(!reference){
      reference =  widgetModel?.getReference().Unknown
    }

    let targetData ;


    if(!version){
      targetData = reference.version[reference.default]
    }else{
      targetData = reference?.version?.[version]?? reference.version[reference.default]
    }

    const TargetSize  = targetData?.reference?.size
    if(TargetSize){
    x = x!=null? x: TargetSize? Resolution?.width/2-TargetSize?.width/2 :20;
    y = y!=null? y: TargetSize? Resolution?.height/2-TargetSize?.height/2 :20;
    }


    const Page = context.nowPage();
    if (!Page?.page_widget){console.error('[에러] : 선택된 페이지가 없어 위젯을 추가 할 수 없습니다. '); return;}
    const NewWidget = EeCoder.add(WidgetName,x.toString(),y.toString(),{preset,option,value})

    console.log('NewWidget',NewWidget);

    context.modifyPage({'page_widget':Page.page_widget.concat(NewWidget)},'widgetAdd', {callBack:()=>context.modifySelectWidget(NewWidget.uuid,'uuid')})
   
}
    


/** del | 선택한 오버랩 위젯(위젯들)을 삭제합니다. 
 * @param {Provider} context EditProvider
 *  */ 
export function del(context) {
    const widgetList = context.nowPage()?.page_widget;
    const SelectWidget = context.selectWidget;
    if (Array.isArray(SelectWidget)) {
        console.error('keyMove : 다중 편집을 아직 지원하지 않습니다.')
        return;

    }else{

    }
    const ModifyList = widgetList.filter((item, index) => item.uuid !== SelectWidget);
    context.modifyPage({'page_widget':ModifyList},'modifyWidget')  
    context.modifySelectWidget(null)
}

/** copy | 선택한 오버랩 위젯(위젯들)을 복사합니다.
 * @param {Provider} context EditProvider
 *  */ 
export function copy (context,uuid) { 

    console.log('[버그 체커] 카피',uuid);

    if(uuid){
        // uuid 가 1개 인경우 (복사할 객체가 1개)
        if (typeof uuid === "string") {
            const traget = context.findwidget(uuid).shift();
            console.log('[버그 체커] 타겟',traget);
            context.appendClipBoard('widget',traget);
          }
    }
}


/** paste | 선택한 오버랩 위젯(위젯들)을 붙여넣습니다.
 * @param {Provider} context EditProvider
 * @param {Provider} type 클립보드 내 타입
 * @param {object} position {x:num,y:num} 
 *  */ 
export function paste(context,type,positionX,positionY) {
    
    if (!context.clipBoard.hasOwnProperty(type)) {
        console.error(`clipBoard : \n ⛔️ 클립보드에 해당 타입이 존재하지 않습니다 ${type} `);
        return
    }

    if(!context.clipBoard.length == 0){
        console.error(`clipBoard : \n ⛔️ ${type} 클립보드에 복사할 대상이 하나도 없습니다.`);
        return
    }
    const WidgetClipBoard = context.clipBoard[type];
    let targetWidget =  Utility.deepCopy(WidgetClipBoard[WidgetClipBoard.length- 1]);
    let targetPage = context.nowPage()

    ////////////////////////////////////////////////////////
    // 복사 세이프티, alias 또는 UUID를 통해 KEY가 중복되는것을 막음
    ////////////////////////////////////////////////////////
    console.log('붙여넣기 에러 :',Number(targetWidget.data.position.x),Number(targetWidget.data.position.x) )
    // 같은 페이지내 복사를 통한 UUID 중복 방지구문


    if(positionX){
        targetWidget.data.position.x = Number(positionX)
    }else{
        targetWidget.data.position.x = Number(targetWidget.data.position.x)
    }
    if(positionY){
        targetWidget.data.position.y = Number(positionY)
    }else{
        targetWidget.data.position.y = Number(targetWidget.data.position.y)
    }

    // 같은 페이지에 같은 UUID의 위젯이 있는경우 UUID 를 변경하고 이전 위젯과 겹치지 않도록 20 픽셀을 미뤄줍니다.
    for (let index = 0; index < targetPage.page_widget.length; index++) {
        if (targetPage.page_widget[index].uuid == targetWidget.uuid)
        {   
            console.error('uuid 중복')
            // r
            targetWidget.data.position.x = Number(targetWidget.data.position.x) + 20
            targetWidget.data.position.y = Number(targetWidget.data.position.y) + 20
            targetWidget.uuid = Utility.uuidMake() 
            break
        }
  
    }
     // 같은 페이지내 복사를 통한 UUID 중복 방지구문
     for (let index = 0; index < targetPage.page_widget.length; index++) {
     
        if (targetPage.page_widget[index].alias == targetWidget.alias)
        {   
            console.error('uuid 중복')
            targetWidget.alias = null
            break
        }

    }

    
    console.log('붙여넣기 에러 E :', Number(targetWidget.data.position.x),Number(targetWidget.data.position.y) )
   

    const widgetList = context.nowPage()?.page_widget;
    if (!widgetList){
        console.error(`clipBoard : \n ⛔️ ${type} 선택된 페이지가 없어 위젯을 추가 할 수 없습니다.`);
    }
    widgetList.push(targetWidget)
    context.modifyPage({'page_widget':widgetList},'widgetPaste',{callBack:()=>context.modifySelectWidget(targetWidget.uuid,'uuid')})
}


/** move | 선택한 오버랩 위젯(위젯들)을 삭제합니다. 
 * @param {Provider} context EditProvider
 *  */ 
export function move(context,x=0,y=0) {
    // const widgetList = context?.nowPage().page_widget;   

    const widgetList = Utility.deepCopy(context?.nowPage().page_widget);
    console.log('widgetList',widgetList);
    const widgetIndex = widgetList.findIndex(item => item.uuid === context.selectWidget);

    const SelectWidget = context.selectWidget;
    if (Array.isArray(SelectWidget)) {
        console.error('keyMove : 다중 편집을 아직 지원하지 않습니다.')
        return;

    }else{

    }

    const WidgetX = Number(widgetList[widgetIndex]?.data?.position?.x??NaN);
    const WidgetY = Number(widgetList[widgetIndex]?.data?.position?.y??NaN);

    if (WidgetX !== NaN) {
        widgetList[widgetIndex].data.position.x = WidgetX + x ;
    }
    if (WidgetY !== NaN) {
        widgetList[widgetIndex].data.position.y = WidgetY + y ;
    }

    console.log('TragetWidget',widgetList[widgetIndex].data.position);


    context.modifyPage({'page_widget':widgetList},'modifyWidget')  
}


/** indexSwap | 특정 위젯의 위치를 변경합니다.
 * @param {EditProvider} context 전역 데이터 접근을 위해 context 객체가 필요합니다.
 * @param {string} widgetUUID 페이지의 최상단으로 이동 시킬 위젯의 uuid
 * @param {number} value 순서를 변경 할 값 양수 위로 음수 아래로
 * @returns void 
 *  */ 

export function indexSwap(context,widgetUUID,value) {
    // 보고 있는 페이지의 위젯 리스트를 가저옵니다.

    const PageWidgets = context.nowPage().page_widget ;
    // 선택한 위젯을 가저옵니다. 
    const TargetWidget = context.findwidget(widgetUUID)[0];
    const TargetIndex = PageWidgets.findIndex(item => item.uuid === widgetUUID);
    // 위젯 리스트에서 선택한 위젯을 제거합니다.
    let newTargetWidgets = PageWidgets.filter(item => item.uuid !== widgetUUID);
    
    if(TargetIndex===-1){
        console.error('[error] ⛔️ widget > indexSwap : 현제 페이지에서 선택한 위젯을 찾을 수 없습니다. ');
        return
    }

    const moveIndex = TargetIndex + value;
    if(moveIndex > PageWidgets.length ){
        console.log('[error] ⛔️ widget > indexSwap : 위젯이 더 뒤로 갈 수 없음')
        return;
    }
    if(moveIndex < 0 ){
        console.log('[error] ⛔️ widget > indexSwap : 위젯이 더 앞으로 갈 수 없음')
        return;
    }

    newTargetWidgets.splice(moveIndex, 0, TargetWidget);
    context.modifyPage({'page_widget':newTargetWidgets},'modifyWidget')  
}

/** indexFront | 특정 위젯을 페이지의 최상단으로 이동시킵니다.
 * @param {EditProvider} context 전역 데이터 접근을 위해 context 객체가 필요합니다.
 * @param {string} widgetUUID 페이지의 최상단으로 이동 시킬 위젯의 uuid
 * @returns void 
 *  */ 
export function indexFront(context,widgetUUID) {
    // 보고 있는 페이지의 위젯 리스트를 가저옵니다.
    const PageWidgets = context.nowPage().page_widget ;
    // 선택한 위젯을 가저옵니다. 
    const TargetWidget = context.findwidget(widgetUUID)[0];
    // 위젯 리스트에서 선택한 위젯을 제거합니다.
    let NewTargetWidgets = PageWidgets.filter(item => item.uuid !== widgetUUID);
    // 위젯 리스트에서 맨 앞에 추가해줍니다.
    NewTargetWidgets.unshift(TargetWidget)
    // 수정 사항을 반영합니다.
    context.modifyPage({'page_widget':NewTargetWidgets},'modifyWidget')  
}

/** indexFront | 특정 위젯을 페이지의 최하단으로 이동시킵니다.
 * @param {EditProvider} context 전역 데이터 접근을 위해 context 객체가 필요합니다.
 * @param {string} widgetUUID 페이지의 최상단으로 이동 시킬 위젯의 uuid
 * @returns void 
 *  */ 
export function indexEnd(context,widgetUUID) {
    // 보고 있는 페이지의 위젯 리스트를 가저옵니다.
    const PageWidgets = context.nowPage().page_widget ;
    // 선택한 위젯을 가저옵니다. 
    const TargetWidget = context.findwidget(widgetUUID)[0];
    // 위젯 리스트에서 선택한 위젯을 제거합니다.
    let NewTargetWidgets = PageWidgets.filter(item => item.uuid !== widgetUUID);
    // 위젯 리스트에서 맨 뒤에 추가해줍니다.
    NewTargetWidgets.push(TargetWidget)
    // 수정 사항을 반영합니다.
    context.modifyPage({'page_widget':NewTargetWidgets},'modifyWidget') 
}



/** activate | 특정 위젯이 활성화 되어 있는지 체크합니다.
 * @param {string} widgetData 페이지의 최상단으로 이동 시킬 위젯의 uuid
 * @returns void 
 *  */ 
export function activate(widgetData) {

    if(widgetData?.display?.show){
        return ' widgetShow '
    }else{
        return ' widgetHidden '
    }
}


/** activate | 특정 위젯이 활성화 되어 있는지 체크합니다.
 * @param {string} widgetData 페이지의 최상단으로 이동 시킬 위젯의 uuid
 * @returns void 
 *  */ 
export function renderControl(widgetData) {

    Utility.elementEqual(widgetData,widgetData)
}