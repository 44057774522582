////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/lobby/space/module/OverlapInfo"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';

// 서비스 연결
import * as overlap from 'service/api/overlap';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function OverlapList(props) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [overlapList, setList] = useState(null);
    const [windowSize, setWindowSize] = useState(null);
    // rel 
    const SpaceRef = useRef(null);
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        console.log('Component did mount or update');
        getOverlapList() 
        // Cleanup function (optional)
        return () => {
          console.log('Component will unmount');
        };
      },[]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////


      async function getOverlapList() {
        let overLapComponent = []
        let overLapList = []
        overLapList = await overlap.list(props.spaceId);
        console.log('overLapList',overLapList);
        overLapComponent = overLapList.map((overlap,index) => (<OverlapInfo key={overlap.uuid} setSelect={props.setSelect} index={index} windowSize={windowSize} data={overlap} spaceId={props.spaceId}/>));    
        setList(overLapComponent);
      }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='overlapListWrap SpaceWrap' ref={SpaceRef}>
            <div className='overlapList'>
            {overlapList}
            </div>
        </div>
    );
  }

  export default OverlapList;

